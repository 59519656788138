import { styled } from '@liftfoils/styles'
import { forwardRef } from 'react'
import { SelectFieldPropsType } from '../SelectField/SelectFieldPropsType'
import { countryCodes } from './helpers/countryCodes'
import { getFlagEmoji } from './helpers/getFlagEmoji'
import { getCountryDataByCode } from './helpers/getCountryDataByCode'
import { SelectField } from '../SelectField/SelectField'

const FieldContainer = styled('div', {
  position: 'relative',
})

const StyledSelect = styled(SelectField, {
  select: {
    color: 'transparent',
  },
})

const SelectValue = styled('div', {
  position: 'absolute',
  color: '$gray500',
  transformOrigin: 'right top',
  transform: 'translate(25px, 22px) scale(1)',
  lift_font: 'body02',
  pointerEvents: 'none',
  zIndex: 1,
})

const FlagIcon = styled('span', {
  mr: '$4',
})

type CountryCodeSelectFieldPropsType = Omit<
  SelectFieldPropsType,
  'autoComplete'
>

export const CountryCodeSelectField = forwardRef<
  HTMLSelectElement,
  CountryCodeSelectFieldPropsType
>((props, ref) => {
  const { disabled, id, children, value, style, className, ...rest } = props

  const currentCountryData = getCountryDataByCode(value)

  return (
    <FieldContainer className={className}>
      {currentCountryData && (
        <SelectValue>
          <FlagIcon>{getFlagEmoji(currentCountryData.code)}</FlagIcon>
          <span>{currentCountryData.dialCode}</span>
        </SelectValue>
      )}
      <StyledSelect
        {...rest}
        disabled={disabled}
        id={id}
        ref={ref}
        autoComplete="tel-country-code"
        value={value}
      >
        {countryCodes.map((countryCode) => (
          <option
            key={countryCode.code}
            value={countryCode.code}
            data-dial-code={countryCode.dialCode}
          >
            {countryCode.name}
          </option>
        ))}
      </StyledSelect>
    </FieldContainer>
  )
})
