import { FC } from 'react'
import { Newsletter } from './Newsletter'
import { SiteConfiguration } from '@liftfoils/models'
import { styled } from '@liftfoils/styles'
import { PortableText } from '../PortableText/PortableText'

const Wrap = styled('div', {
  display: 'grid',
  gridGap: '$8',
  gridTemplateRows: '1fr 82px 1fr',
  '@md': {
    gridTemplateRows: 'min-content 82px 1fr',
    maxWidth: '360px',
  },
})

const Heading = styled('div', {
  lift_font: 'heading03',
  color: '$gray500',
})

const Description = styled('div', {
  lift_font: 'body06',
  color: '$gray300',
  maxWidth: '400px',
  a: {
    color: '$gray500',
  },
})

export const FooterNewsletter: FC<{
  newsletter: SiteConfiguration['newsletter']
}> = ({ newsletter }) => {
  return (
    <Wrap>
      <Heading>
        {newsletter.heading && <PortableText value={newsletter.heading} />}
      </Heading>
      <Newsletter />
      <Description>
        {newsletter.description && (
          <PortableText value={newsletter.description} />
        )}
      </Description>
    </Wrap>
  )
}
