import { FC, useEffect, useRef, useState } from 'react'
import { styled } from '@liftfoils/styles'
import { useTranslation } from 'react-i18next'
import { Button } from '@liftfoils/components'
import { TextField } from '@liftfoils/forms'

type AddToCartButtonProps = {
  value: string
  onChange: (val: string) => void
}

const Root = styled('div', {
  variants: {
    editing: {
      true: {
        gridColumn: '1 / 3',
      },
    },
  },
})

export const CartNote: FC<AddToCartButtonProps> = ({ value, onChange }) => {
  const { t } = useTranslation('cart')
  const valueIsEmpty = !value || value === ''
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [editing, setEditing] = useState(!valueIsEmpty)

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus()
    }
  }, [editing])

  return (
    <Root editing={editing}>
      {editing ? (
        <TextField
          id={'cart-note'}
          ref={inputRef}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          multiline
          label={t('note')}
        />
      ) : (
        <Button
          appearance={'grayUnderline'}
          onClick={() => {
            setEditing(true)
          }}
        >
          {t('addNote')}
        </Button>
      )}
    </Root>
  )
}
