import { FC, useRef, useState } from 'react'
import { styled } from '@liftfoils/styles'

import {
  TikTok,
  LinkedIn,
  Facebook,
  Instagram,
  YouTube,
} from '@liftfoils/icons'
import { Link } from '../Link/Link'
import { Container } from '../Container/Container'
import { AccodrionMethods, Accordion } from '../Accordion/Accordion'
// import { ShippingButton } from '../ShippingButton/ShippingButton'
import { SiteConfiguration } from '@liftfoils/models'
import { FooterNewsletter } from '../Newsletter/FooterNewsletter'
import { Credits } from './Credits'
import { AccountButton } from '../AccountButton'

const Root = styled('div', {
  display: 'block',
  py: '$8',
  '@md': {
    py: '$10',
  },
})

const WrapTop = styled('div', {
  display: 'grid',
  '@md': {
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    columnGap: '$10',
  },
  '@lg': {
    gridTemplateColumns: '1fr minmax(0, 2fr)',
    columnGap: '$10',
  },
})

const Nav = styled('div', {
  display: 'none',
  width: '100%',
  mt: '$8',
  alignItems: 'self-start',
  marginLeft: 'auto',
  maxWidth: '630px',
  '@md': {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    columnGap: '$5',
    rowGap: '$8',
    mt: '$0',
  },
  '@lg': {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    columnGap: '$6',
    rowGap: '$9',
  },
})

const Col = styled('div', {
  display: 'grid',
})

const ColHeading = styled('button', {
  lift_font: 'heading03',
  lineHeight: '1',
  color: '$gray500',
  borderTop: '1px solid',
  borderColor: '$gray100',
  px: '$0',
  height: '$11',
  background: 'none',
  textAlign: 'left',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  '@md': {
    border: 'none',
    py: '$0',
    px: '$0',
    height: '$8',
  },
  variants: {
    isOpen: {
      true: {
        '&::before': {
          content: ``,
          display: 'block',
          backgroundColor: '$gray500_06',
          position: 'absolute',
          top: '50%',
          right: '0',
          width: '21px',
          height: '1px',
          transform: 'translateY(-50%)',
        },
      },
      false: {
        '&::after': {
          content: ``,
          display: 'block',
          backgroundColor: '$gray500_06',
          position: 'absolute',
          top: '50%',
          right: '10px',
          width: '1px',
          height: '21px',
          transform: 'translateY(-50%)',
        },
        '&::before': {
          content: ``,
          display: 'block',
          backgroundColor: '$gray500_06',
          position: 'absolute',
          top: '50%',
          right: '0',
          width: '21px',
          height: '1px',
          transform: 'translateY(-50%)',
        },
      },
    },
  },
})

const ColItems = styled('div', {
  display: 'grid',
  justifyItems: 'start',
  pb: '$8',
  '@md': {
    border: 'none',
    pb: '$0',
  },
})

const NavLink = styled(Link, {
  lift_font: 'heading03',
  color: '$gray300',
  cursor: 'pointer',
  mt: '$6',
  transition: 'color 200ms',

  '@md': {
    mt: '$4',
  },
  '&:hover': {
    color: '$gray500',
  },
})

const AccordionsMobile = styled('div', {
  display: 'grid',
  width: '100%',
  mt: '$8',
  borderBottom: '1px solid',
  borderColor: '$gray100',
  '@md': {
    display: 'none',
  },
})

const AccordionMobile = styled('div', {
  display: 'grid',
  width: '100%',
})

const WrapBottom = styled('div', {
  display: 'grid',
  alignItems: 'self-end',
  gridGap: '$6',
  mt: '$9',
  '@md': {
    gridTemplateColumns: '1fr auto auto',
    columnGap: '$10',
    $mt: 'L',
  },
  '@lg': {
    $mt: 'XL',
    gridGap: '$9',
  },
})

const WrapBottomLeftSide = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gridColumnGap: '$3',
  gridRowGap: '$3',
  justifyContent: 'center',
  '@md': {
    justifyContent: 'start',
    flexDirection: 'column',
    gridColumn: 1,
    gridRow: 1,
  },
  '@lg': {
    flexDirection: 'row',
    gridGap: '$5',
  },
})

const BottomUtils = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '$6',
  '@md': {
    gridColumn: 2,
    gridRow: 1,
    gridTemplateColumns: 'auto auto',
  },
})
const SocialMedia = styled('div', {
  display: 'grid',
  justifyContent: 'center',
  gridColumnGap: '$6',
  '@sm': {
    gridColumnGap: '$8',
  },
  '@md': {
    gridColumn: 3,
    gridRow: 1,
    justifyContent: 'end',
    gridColumnGap: '$6',
  },
})

const Copyright = styled('p', {
  lift_font: 'body06',
  color: '$gray300',
  textAlign: 'center',
  '@md': {
    textAlign: 'left',
    mt: '$0',
  },
})

export const Footer: FC<{
  footer: SiteConfiguration['footer']
  newsletter: SiteConfiguration['newsletter']
}> = ({ footer, newsletter }) => {
  // const [isShippingModalOpen, setShippingModalOpen] = useState(false)
  const accordionsRef = useRef<Array<AccodrionMethods | null>>([])
  const [accordionsOpen, setAccordionsOpen] = useState<Array<boolean>>([
    false,
    false,
    false,
  ])

  const accordionClick = (i: number) => {
    if (accordionsRef.current) {
      const accordionsOpenNewState = [...accordionsOpen]

      if (accordionsOpen[i] != true) {
        accordionsRef.current[i]?.open()
        accordionsOpenNewState[i] = true
      } else {
        accordionsRef.current[i]?.close()
        accordionsOpenNewState[i] = false
      }

      setAccordionsOpen(accordionsOpenNewState)
    }
  }

  // const handleOpenShippingModal = () => {
  //   setShippingModalOpen(true)
  // }
  // const handleCloseShippingModal = () => {
  //   setShippingModalOpen(false)
  // }

  return (
    <Root>
      <Container>
        <WrapTop>
          <FooterNewsletter newsletter={newsletter} />
          <Nav>
            {footer.linkGroups.map((group) => (
              <Col key={group.title}>
                <ColHeading as={'h3'}>{group.title}</ColHeading>
                <ColItems>
                  {group.links?.map((groupLink) => (
                    <NavLink {...groupLink.link} key={groupLink.title}>
                      {groupLink.title}
                    </NavLink>
                  ))}
                </ColItems>
              </Col>
            ))}
          </Nav>
        </WrapTop>

        <AccordionsMobile>
          {footer.linkGroups.map((group, groupIndex) => (
            <AccordionMobile key={groupIndex}>
              <ColHeading
                isOpen={accordionsOpen[groupIndex]}
                onClick={() => accordionClick(groupIndex)}
              >
                <span>{group.title}</span>
              </ColHeading>
              <Accordion ref={(el) => (accordionsRef.current[groupIndex] = el)}>
                <ColItems>
                  {group.links?.map((groupLink, groupLinkIndex) => (
                    <NavLink {...groupLink.link} key={groupLinkIndex}>
                      {groupLink.title}
                    </NavLink>
                  ))}
                </ColItems>
              </Accordion>
            </AccordionMobile>
          ))}
        </AccordionsMobile>

        <WrapBottom>
          <BottomUtils>
            {/*<ShippingButton*/}
            {/*  appearance={'outlineGray300'}*/}
            {/*  shortLabel={false}*/}
            {/*  size={'medium'}*/}
            {/*  isShippingModalOpen={isShippingModalOpen}*/}
            {/*  handleCloseShippingModal={handleCloseShippingModal}*/}
            {/*  handleOpenShippingModal={handleOpenShippingModal}*/}
            {/*/>*/}
            <AccountButton appearance={'outlineGray300'} size={'medium'} />
          </BottomUtils>
          <SocialMedia
            css={{
              gridTemplateColumns: `repeat(${footer.socialMedia.length}, min-content)`,
              '@md': {
                gridTemplateColumns: `repeat(${
                  footer.socialMedia.length + 2
                }, min-content)`,
              },
            }}
          >
            {footer.socialMedia.map((item) => (
              <Link
                href={item.url}
                appearance={'socialMedia'}
                target="_blank"
                key={item.type}
              >
                {(() => {
                  switch (item.type) {
                    case 'youtube':
                      return <YouTube />
                    case 'instagram':
                      return <Instagram />
                    case 'facebook':
                      return <Facebook />
                    case 'tiktok':
                      return <TikTok />
                    case 'linkedin':
                      return <LinkedIn />
                    default:
                      return null
                  }
                })()}
              </Link>
            ))}
          </SocialMedia>
          <WrapBottomLeftSide>
            <Copyright>Lift Foils © {new Date().getUTCFullYear()}</Copyright>
            <Credits />
          </WrapBottomLeftSide>
        </WrapBottom>
      </Container>
    </Root>
  )
}
