export const GTMOriginalLocation = () => {
  const w = window as any

  w.dataLayer = w.dataLayer || []
  w.dataLayer.push({
    originalLocation:
      document.location.protocol +
      '//' +
      document.location.hostname +
      document.location.pathname +
      document.location.search,
  })
}
