import React, {
  useState,
  useEffect,
  createContext,
  FC,
  PropsWithChildren,
} from 'react'
import { useRouter } from 'next/router'
import {
  createCartClient,
  equalAttributes,
  useAccount,
} from '@liftfoils/services/shopify-service'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { captureException } from '@sentry/nextjs'
import {
  ShopifyCartBuyerIdentityInput,
  ShopifyMutationCartLinesAddArgs,
  ShopifyMutationCartLinesUpdateArgs,
  ShopifyResolvedCart,
  ShopifyResolvedCartLine,
} from '@liftfoils/shopify-sdk'

export type CartContextType = {
  cart: ShopifyResolvedCart | null
  quantity: number
  processing: boolean
  addCartLines: (
    lines: ShopifyMutationCartLinesAddArgs['lines'],
  ) => Promise<ShopifyResolvedCartLine[] | undefined> | undefined
  removeCartLines: (ids: string[]) => void
  updateCartLines: (lines: ShopifyMutationCartLinesUpdateArgs['lines']) => void
  updateCartNote: (note: string) => void
  updateDiscountCodes: (discountCodes: string[]) => Promise<void>
}

export const CartContext = createContext<CartContextType>({
  cart: null,
  quantity: 0,
  processing: false,
  addCartLines: () => undefined,
  removeCartLines: () => undefined,
  updateCartLines: () => undefined,
  updateCartNote: () => undefined,
  updateDiscountCodes: async () => undefined,
})

const createLsCartIdService = (regionId: string) => {
  const LOCAL_STORAGE_CART_ID_KEY = `cartId_${regionId}`
  return {
    get: () => localStorage.getItem(LOCAL_STORAGE_CART_ID_KEY),
    set: (id: string) => localStorage.setItem(LOCAL_STORAGE_CART_ID_KEY, id),
    remove: () => localStorage.removeItem(LOCAL_STORAGE_CART_ID_KEY),
  }
}

export const CartProvider: FC<PropsWithChildren<{ debug?: boolean }>> = ({
  children,
}) => {
  const { locale } = useRouter()
  const [cart, setCart] = useState<ShopifyResolvedCart>(null)
  const [linesQuantity, setLinesQuantity] = useState<number>(0)
  const [processing, setProcessing] = useState(false)
  const {
    account,
    processing: accountProcessing,
    refreshUserSession,
  } = useAccount()
  const buyerIdentity: ShopifyCartBuyerIdentityInput = account
    ? {
        email: account.email,
        customerAccessToken: account.customerAccessToken,
        companyLocationId: account.companyLocationId,
      }
    : {
        email: null,
        customerAccessToken: null,
        companyLocationId: null,
      }

  const regionId = getLocaleRegionIdFromPath(locale)[0]
  const lsCartId = createLsCartIdService(regionId)
  const cartClient = createCartClient({ locale, cartId: cart?.id || null })

  const debugCondition = process.env['CONTEXT'] !== 'production'

  const logDebugMessage = (...args: any[]) =>
    debugCondition && console.log(...args)

  const errorDebugMessage = (...args: any[]) =>
    debugCondition && console.error(...args)

  const __fetchOrCreateCart = async () => {
    setProcessing(true)
    const LSCartId = lsCartId.get()

    try {
      const cartRes = LSCartId
        ? await cartClient.fetch(LSCartId)
        : await cartClient.create()
      __resolveCartOperation(cartRes)
    } catch (e) {
      const err = new Error(
        `[fetchOrCreateCart] Reached retry limit. ${JSON.stringify(e)}`,
      )
      errorDebugMessage(err)
      captureException(err)
      return
    }
  }

  const __resolveCartOperation = (cartRes: ShopifyResolvedCart) => {
    if (cartRes) {
      // proper cart
      logDebugMessage('[refreshCart] FRESH CART RECEIVED. updating state...')
      setCart(cartRes)
      setLinesQuantity(cartRes.totalQuantity)
      setProcessing(false)
      lsCartId.set(cartRes.id)
    }
    if (!cartRes) {
      // empty cart
      logDebugMessage('[refreshCart] EMPTY CART RECEIVED. recreating...')
      setCart(null)
      setLinesQuantity(0)
      lsCartId.remove()
      __fetchOrCreateCart()
    }
  }

  const __revalidateCart = async (
    fn: () => Promise<ShopifyResolvedCartLine[] | void> | void,
  ) => {
    setProcessing(true)
    if (!lsCartId.get() || !cart) {
      await __fetchOrCreateCart()
      await fn()
    }
  }

  const __refreshBuyerIdentity = async () => {
    if (!cart) return
    if (
      (!cart.buyerIdentity.email && account?.email) ||
      (cart.buyerIdentity.email && !account) ||
      cart.buyerIdentity.email !== account?.email
    ) {
      const res = await cartClient.updateCartBuyerIdentity({
        cartId: cart.id,
        buyerIdentity,
      })
      __resolveCartOperation(res)
    }
  }

  const addCartLines: CartContextType['addCartLines'] = async (newLines) => {
    logDebugMessage('[addCartLines] ADD LINES')
    await __revalidateCart(() => addCartLines(newLines))
    const res = await cartClient.addLines(newLines)

    if (res) {
      const updatedCart = res
      const addedLines = newLines.reduce(
        (acc: ShopifyResolvedCartLine[], line) => {
          const matchedLine = updatedCart?.lines?.find((l) => {
            return (
              l.merchandise.id === line.merchandiseId &&
              equalAttributes(l.attributes, line.attributes)
            )
          })
          return matchedLine ? [...acc, matchedLine] : acc
        },
        [],
      )

      __resolveCartOperation(res)

      if (addedLines.length === newLines.length) {
        return addedLines
      } else {
        const err = new Error(
          `[addCartLines] Added lines not found in incoming cart, lines: ${JSON.stringify(
            newLines,
          )}, cart: ${JSON.stringify(updatedCart)}`,
        )
        captureException(err)
        return undefined
      }
    }
    __resolveCartOperation(res)
    return undefined
  }

  const updateDiscountCodes: CartContextType['updateDiscountCodes'] = async (
    codes: string[],
  ) => {
    logDebugMessage('[updateDiscountCodes] UPDATE DISCOUNT CODES')
    const res = await cartClient.updateDiscountCodes(codes)
    __resolveCartOperation(res)
  }

  const removeCartLines: CartContextType['removeCartLines'] = async (ids) => {
    logDebugMessage('[removeCartLines] REMOVE LINES')
    await __revalidateCart(() => removeCartLines(ids))
    const res = await cartClient.removeLines(ids)
    __resolveCartOperation(res)
  }

  const updateCartLines: CartContextType['updateCartLines'] = async (lines) => {
    logDebugMessage('[updateCartLines] UPDATE LINES')
    await __revalidateCart(() => updateCartLines(lines))
    const res = await cartClient.updateLines(lines)
    __resolveCartOperation(res)
  }

  const updateCartNote: CartContextType['updateCartNote'] = async function (
    note: string,
  ) {
    logDebugMessage('[updateCartNote] UPDATE NOTE')
    await __revalidateCart(() => updateCartNote(note))
    const res = await cartClient.updateNote(note)
    __resolveCartOperation(res)
  }

  const __reloadCart = async () => {
    await __fetchOrCreateCart()
  }
  //refresh cart on focus
  useEffect(() => {
    refreshUserSession()
    window.addEventListener('focus', refreshUserSession)
    return () => window.removeEventListener('focus', refreshUserSession)
  }, [locale])

  useEffect(() => {
    if (!accountProcessing) {
      __reloadCart()
    }
  }, [JSON.stringify(account), accountProcessing])

  //refresh buyer identity on account change
  useEffect(() => {
    if (accountProcessing) return
    __refreshBuyerIdentity()
  }, [
    JSON.stringify(account),
    JSON.stringify(cart?.buyerIdentity),
    accountProcessing,
  ])

  return (
    <CartContext.Provider
      value={{
        cart,
        processing,
        quantity: linesQuantity,
        addCartLines,
        removeCartLines,
        updateCartLines,
        updateCartNote,
        updateDiscountCodes,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
