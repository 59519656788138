import { styled } from '@liftfoils/styles'
import { useEffect, useRef, useState, forwardRef } from 'react'
import { Link } from '@liftfoils/components'
import { ArrowDown } from '@liftfoils/icons'
import { HeaderTab } from '@liftfoils/models'

import {
  NAV_ENTERING_TIME,
  NAV_EXITING_TIME,
  NAV_SWITCHING_TIME,
} from './header.config'
import { useHeaderContext } from './HeaderContext'
import { CommonTab } from './HeaderTab'
import { useTranslation } from 'react-i18next'

type DesktopNavItemProps = {
  tab: HeaderTab
  navButtonAppearance: 'bareWhite' | 'bareGray'
  onCloseRequest: () => void
}

const FLYOUT_SAFE_SPACE = 150
const DesktopNavigationItemWrap = styled('li', {
  listStyle: 'none',
  display: 'grid',
  justifyContent: 'center',
})

const OpenerWrap = styled('div', {
  display: 'grid',
  position: 'relative',
})
const StyledLink = styled(Link, {
  display: 'grid',
  padding: '$7 $6',
  lineHeight: 1,
  alignContent: 'center',
  color: '$white',
  transition: `color 200ms`,
  variants: {
    isActive: {
      true: {
        color: '$black',
      },
    },
  },
})
const ArrowButton = styled('button', {
  pointerEvents: 'none',
  position: 'absolute',
  right: 0,
  height: '100%',
  opacity: 0,
  background: 'none',
  border: 'none',
  transition: `opacity 200ms`,
  padding: '$2',
  display: 'grid',
  alignItems: 'center',
  borderRadius: '$rMax',
  '&:focus-visible': {
    opacity: 1,
    outline: '$teal500_05 solid 3px',
    outlineOffset: '-2px',
  },
  focusStyle: 'default',
})
const StyledArrowDown = styled('div', {
  transform: 'rotate(0deg)',
  transformOrigin: '50%',
  variants: {
    isActive: {
      true: {
        transform: 'rotate(-180deg)',
        transformOrigin: '50%',
      },
    },
  },
})
const FlyoutWrap = styled('div', {
  position: 'absolute',
  top: '$headerHeight',
  left: 0,
  right: 0,
})

const FlyoutContent = styled('div', {
  overflowY: 'scroll',
  position: 'absolute',
  width: '100%',
  maxHeight: `calc(100vh - ${FLYOUT_SAFE_SPACE}px)`,
  top: 0,
  left: 0,
  zIndex: '999',
  borderTop: '1px solid $gray200',
  boxShadow: '0 10px 18px -10px rgba(0, 0, 0, 0.05)',
  '&::-webkit-scrollbar': {
    display: 'none',
  },

  variants: {
    state: {
      EXITED: {
        opacity: 0,
        visibility: 'hidden',
      },
      ENTERING: {
        transform: 'none',
        transition: `opacity ${NAV_ENTERING_TIME}ms, visibility 0ms`,
      },
      SWITCHING_ENTERING: {
        transition: `opacity 200ms, visibility 0ms`,
      },
      ENTERED: {},
      EXITING: {
        opacity: 0,
        visibility: 'hidden',
        transition: `opacity ${NAV_EXITING_TIME}ms, visibility 0ms ${NAV_EXITING_TIME}ms`,
      },
      SWITCHING_EXITING: {
        opacity: 0,
        visibility: 'hidden',
        transition: `opacity 200ms, visibility 0ms 200ms`,
      },
    },
  },
})

const FlyoutBackground = styled('div', {
  position: 'absolute',
  width: '100%',
  left: 0,
  background: 'white',
  overflowY: 'hidden',
  variants: {
    state: {
      EXITED: { opacity: 0 },
      ENTERING: {
        opacity: 1,
        transition: `height 0ms cubic-bezier(.4,.0,.6,1), opacity ${NAV_EXITING_TIME}ms`,
      },
      SWITCHING_ENTERING: {
        transition: `height ${NAV_SWITCHING_TIME}ms cubic-bezier(.4,.0,.6,1)`,
      },
      ENTERED: { opacity: 1 },
      EXITING: {
        opacity: 0,
        transition: `height 0ms ${NAV_EXITING_TIME}ms, opacity ${NAV_EXITING_TIME}ms`,
      },
      SWITCHING_EXITING: {
        transition: `height ${NAV_SWITCHING_TIME}ms cubic-bezier(.4,.0,.6,1)`,
      },
    },
  },
})
type UserActionMethod = 'HOVER' | 'KEYBOARD'

export const DesktopNavItem = forwardRef<HTMLSpanElement, DesktopNavItemProps>(
  ({ tab, navButtonAppearance, onCloseRequest }, ref) => {
    const {
      handleOpen,
      handleSwitch,
      headerState: {
        flyoutHeight,
        navigationAnimationStatus,
        activeTabId,
        lastSwitchedTabId,
      },
    } = useHeaderContext()

    const { t } = useTranslation('modal')

    const isIn = activeTabId === tab.id
    const wasIn = lastSwitchedTabId === tab.id

    const flyoutAnimationStatus = (() => {
      if (isIn && navigationAnimationStatus === 'ENTERED') return 'ENTERED'
      if (isIn && navigationAnimationStatus === 'ENTERING') return 'ENTERING'
      if (isIn && navigationAnimationStatus === 'SWITCHING')
        return 'SWITCHING_ENTERING'
      if (isIn && navigationAnimationStatus === 'EXITING') return 'EXITING'
      if (wasIn && navigationAnimationStatus === 'SWITCHING')
        return 'SWITCHING_EXITING'
      return 'EXITED'
    })()

    const [flyoutTriggerMethod, setFlyoutTriggerMethod] = useState<
      undefined | UserActionMethod
    >(undefined)

    const arrowButtonRef = useRef<HTMLButtonElement | null>(null)
    const contentRef = useRef<HTMLDivElement | null>(null)

    const onOpenRequest = (method: UserActionMethod) => {
      setFlyoutTriggerMethod(method)
      const newHeight = contentRef.current?.getBoundingClientRect()?.height ?? 0
      if (
        navigationAnimationStatus === 'EXITED' ||
        navigationAnimationStatus === 'EXITING'
      ) {
        handleOpen(tab.id, newHeight)
      } else {
        handleSwitch(tab.id, newHeight)
      }
    }

    useEffect(() => {
      const handleEsc = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          onCloseRequest()
          flyoutTriggerMethod === 'KEYBOARD' && arrowButtonRef?.current?.focus()
        }
      }
      if (!isIn) window.removeEventListener('keydown', handleEsc)
      if (isIn) window.addEventListener('keydown', handleEsc)

      return () => {
        window.removeEventListener('keydown', handleEsc)
      }
    }, [isIn])

    if (!tab) return null

    const hasFlyout = tab.type !== 'withoutSubnavigation'

    if (!hasFlyout) {
      return (
        <DesktopNavigationItemWrap onMouseEnter={onCloseRequest}>
          <OpenerWrap>
            <span id={`navigation-menu-item-${tab.id}`} ref={ref}>
              {tab.title && (
                <StyledLink
                  appearance={navButtonAppearance}
                  size={'navHeaderLarge'}
                  {...tab.link}
                >
                  {tab.title}
                </StyledLink>
              )}
            </span>
          </OpenerWrap>
        </DesktopNavigationItemWrap>
      )
    }

    return (
      <>
        <DesktopNavigationItemWrap
          onMouseEnter={() => {
            onOpenRequest('HOVER')
          }}
        >
          <OpenerWrap>
            <span id={`navigation-menu-item-${tab.id}`} ref={ref}>
              {tab.title && (
                <StyledLink
                  appearance={navButtonAppearance}
                  size={'navHeaderLarge'}
                  {...tab.link}
                  isActive={isIn && navigationAnimationStatus !== 'EXITING'}
                >
                  {tab.title}
                </StyledLink>
              )}
            </span>
            <ArrowButton
              ref={arrowButtonRef}
              aria-label={`${isIn ? t('close') : t('open')} ${tab.title} tab`}
              aria-expanded={isIn}
              id={`navigation-menu-opener-${tab.id}`}
              aria-controls={`navigation-menu-${tab.id}`}
              onClick={isIn ? onCloseRequest : () => onOpenRequest('KEYBOARD')}
            >
              <StyledArrowDown isActive={isIn}>
                <ArrowDown
                  pathStyle={{
                    fill:
                      navButtonAppearance === 'bareWhite' ? 'white' : 'black',
                  }}
                />
              </StyledArrowDown>
            </ArrowButton>
          </OpenerWrap>
        </DesktopNavigationItemWrap>

        <FlyoutWrap
          id={`navigation-menu-${tab.id}`}
          aria-labelledby={`navigation-menu-opener-${tab.id}`}
        >
          <FlyoutBackground
            state={flyoutAnimationStatus}
            css={{ height: `${flyoutHeight}px` }}
          >
            <FlyoutContent ref={contentRef} state={flyoutAnimationStatus}>
              <CommonTab tab={tab} />
            </FlyoutContent>
          </FlyoutBackground>
        </FlyoutWrap>
      </>
    )
  },
)
