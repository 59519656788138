export const ratios = {
  landscape1: 0.42,
  landscape2: 0.64,
  landscape3: 0.7,
  landscape4: 0.85,
  square: 1,
  portrait1: 1.1,
  portrait2: 1.32,
}

export type ImageSizes = '856' | '1440' | '1920' | '2880'

export type MediaRatiosType = keyof typeof ratios

export type MediaRatiosTypeExtended =
  | MediaRatiosType
  | Record<ImageSizes, keyof typeof ratios>
