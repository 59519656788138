import React from 'react'

export const Calendar = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6385 4.5986C13.4121 4.5986 13.2284 4.41491 13.2284 4.19022V3.28971H7.15606V4.19042C7.15443 4.41513 6.97071 4.59883 6.74602 4.59883C6.51967 4.59883 6.33757 4.41513 6.33757 4.19042V3.28971H4.00577C3.35296 3.28971 2.82127 3.82115 2.82127 4.47395V6.27782H17.6276V4.47395C17.6276 3.82115 17.0963 3.28971 16.4419 3.28971H14.0469V4.19022C14.0486 4.41491 13.8648 4.5986 13.6385 4.5986ZM18.4445 6.66754V4.47395C18.4445 3.37011 17.5474 2.47125 16.4419 2.47125H14.0469V1.4084C14.0469 1.18369 13.8648 1 13.6385 1C13.4121 1 13.2284 1.18205 13.2284 1.4084V2.47125H7.15606V1.40862C7.15606 1.18391 6.97071 1.00022 6.74602 1.00022C6.52131 1.00022 6.33757 1.18227 6.33757 1.40862V2.47125H4.00577C2.90192 2.47125 2.00317 3.37011 2.00317 4.47395V6.63542C2.00108 6.65209 2 6.66906 2 6.68628C2 6.70349 2.00108 6.72046 2.00317 6.73713V16.91C2.00317 18.0138 2.90192 18.9127 4.00577 18.9127H16.4419C17.5474 18.9127 18.4461 18.0155 18.4445 16.91V6.70502C18.4448 6.6988 18.4449 6.69256 18.4449 6.68628C18.4449 6.68 18.4448 6.67375 18.4445 6.66754ZM17.6276 7.09469H2.82127V16.91C2.82127 17.5628 3.35296 18.0942 4.00577 18.0942H16.4419C17.0947 18.0942 17.6276 17.5628 17.6276 16.91V7.09469Z"
      />
    </svg>
  )
}
