'use client'

import React, { FC, PropsWithChildren, ReactNode } from 'react'
import { Button } from '../Button/Button'
import { styled } from '@liftfoils/styles'

export type DisclosureBodyProps = {
  id?: string
  isOpen?: boolean
}
const DisclosureBodyInnerStyled = styled('div', {
  overflow: 'hidden',
})
const DisclosureBodyStyled = styled('div', {
  display: 'grid',
  gridTemplateRows: '0fr',
  variants: {
    isOpen: {
      true: {
        gridTemplateRows: '1fr',
        transition: 'grid-template-rows 300ms ease-in-out, opacity 100ms 200ms',
      },
      false: {
        opacity: 0,
        transition: 'grid-template-rows 300ms ease-in-out, opacity 200ms',
      },
    },
  },
})
export const DisclosureBody: FC<PropsWithChildren<DisclosureBodyProps>> = ({
  isOpen,
  id,
  children,
}) => {
  return (
    <DisclosureBodyStyled
      isOpen={isOpen}
      role={'region'}
      id={`sect${id}`}
      aria-labelledby={`accordion-${id}`}
    >
      <DisclosureBodyInnerStyled>{children}</DisclosureBodyInnerStyled>
    </DisclosureBodyStyled>
  )
}

export type DisclosureItemProps = {
  id?: string
  isOpen?: boolean
  onRequestOpen?: (index: string) => void
  onRequestClose?: (index: string) => void
  headerSlot: ReactNode
}

export const Disclosure: FC<PropsWithChildren<DisclosureItemProps>> = ({
  id,
  isOpen = false,
  onRequestClose,
  onRequestOpen,
  children,
  headerSlot,
}) => {
  const handleClick = () => {
    if (onRequestClose && onRequestOpen && id !== undefined) {
      isOpen ? onRequestClose(id) : onRequestOpen(id)
    }
  }
  return (
    <>
      <Button
        onClick={handleClick}
        aria-expanded={isOpen}
        aria-controls={`sect${id}`}
        id={`accordion-${id}`}
        type={'button'}
      >
        {headerSlot}
      </Button>
      <DisclosureBody isOpen={isOpen} id={id}>
        {children}
      </DisclosureBody>
    </>
  )
}
