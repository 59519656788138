import { regions } from './regions'

export const SHOPIFY_INSTANCE_IDS = ['US', 'AU'] as const
export const SHOPIFY_VENDORS = ['Lift Foils', 'Lift Foils Australia'] as const

export type ShopifyInstanceId = (typeof SHOPIFY_INSTANCE_IDS)[number]
export type ShopifyVendor = (typeof SHOPIFY_VENDORS)[number]

export type ShopifyInstance = {
  vendor: ShopifyVendor
  id: ShopifyInstanceId
  path: string
  domain?: string
  token?: string
  icon: () => string
  stampedApiKey?: string
  stampedSid?: string
}

export const SHOPIFY_INSTANCE_BY_ID: Record<
  ShopifyInstanceId,
  ShopifyInstance
> = {
  US: {
    id: 'US',
    path: 'us',
    vendor: 'Lift Foils',
    icon: () => '🇺🇸',
    domain: process.env.NEXT_PUBLIC_SHOPIFY_US_DOMAIN,
    token: process.env.NEXT_PUBLIC_SHOPIFY_US_ACCESS_TOKEN,
    stampedApiKey: process.env.NEXT_PUBLIC_STAMPED_US_API_KEY,
    stampedSid: process.env.NEXT_PUBLIC_STAMPED_US_SID,
  },
  AU: {
    id: 'AU',
    path: 'au',
    vendor: 'Lift Foils Australia',
    icon: () => `🦘`,
    domain: process.env.NEXT_PUBLIC_SHOPIFY_AU_DOMAIN,
    token: process.env.NEXT_PUBLIC_SHOPIFY_AU_ACCESS_TOKEN,
  },
} as const

export const SHOPIFY_INSTANCE_BY_VENDOR: Record<
  ShopifyVendor,
  ShopifyInstance
> = {
  'Lift Foils Australia': SHOPIFY_INSTANCE_BY_ID['AU'],
  'Lift Foils': SHOPIFY_INSTANCE_BY_ID['US'],
}

export const SHOPIFY_INSTANCES: ShopifyInstance[] = SHOPIFY_INSTANCE_IDS.map(
  (id) => SHOPIFY_INSTANCE_BY_ID[id],
)

export const DEFAULT_SHOPIFY_INSTANCE_ID = 'US' as ShopifyInstanceId

export const EXCLUDED_LOCALES = regions.reduce(
  (acc: string[], r) => (r.enabled ? [...acc, r.id.toLowerCase()] : acc),
  [],
)
