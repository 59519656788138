const getDate = (date: string): Date => new Date(date)

const getDateString = (date: string, withYear?: boolean) => {
  return getDate(date).toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: withYear ? 'numeric' : undefined,
    timeZone: 'UTC',
  })
}

export const getDurationString = (
  startDate?: string,
  endDate?: string,
  withYear?: boolean,
): string => {
  if (!startDate) {
    return ''
  }
  const startDateString = getDateString(startDate, withYear)

  if (!endDate) return startDateString

  const sameDay = startDate === endDate

  if (sameDay) return startDateString

  const endDateString = getDateString(endDate, withYear)
  return `${startDateString} - ${endDateString}`
}
