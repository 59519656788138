import { globalCss } from './stitches'

const reset = {
  /*RESET ALL DEFAULT STYLES*/
  [`html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, menu, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video`]:
    {
      margin: 0,
      padding: 0,
      border: 0,
      fontSize: '100%',
      font: 'inherit',
      verticalAlign: 'baseline',
    },
  /* HTML5 display-role reset for older browsers */
  [`article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section`]:
    {
      display: 'block',
    },
  /* HTML5 hidden-attribute fix for newer browsers */
  [`*[hidden]`]: {
    display: 'none',
  },
  body: {
    lineHeight: 1,
  },

  [`blockquote, q`]: {
    quotes: 'none',
  },

  [`blockquote:before, blockquote:after, q:before, q:after`]: {
    content: 'none',
  },
  table: {
    borderCollapse: 'collapse',
    borderSpacing: 0,
  },
  [`*`]: {
    boxSizing: 'border-box',
  },
  [`a:focus, button:focus, input:focus, select:focus, div:focus`]: {
    outline: 'none',
  },
}

const projectDefaults = {
  html: {
    backgroundColor: 'white',
  },
  body: {
    backgroundColor: 'white',
    /*textRendering: 'geometricPrecision'*/
    '-webkit-font-smoothing': 'antialiased',
  },
  'body.ReactModal__Body--open': {
    overflow: 'hidden',
  },
  [`input[type='search']::-webkit-search-decoration, input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-results-button, input[type='search']::-webkit-search-results-decoration`]:
    {
      appearance: 'none',
    },
  a: {
    color: 'inherit',
    textDecoration: 'none',
  },
  button: {
    cursor: 'pointer',
    border: 'none',
    appearance: 'none',
    padding: 0,
    margin: 0,
  },
  [`input:focus-visible + label `]: {
    outline: 'rgba(0, 113, 227, 0.5) solid 3px',
    outlineOffset: '-2px',
  },
  [`_::-webkit-full-page-media, _:future, :root button:focus, :root a:focus`]: {
    outline: 'rgba(0, 113, 227, 0.5) solid 3px',
    outlineOffset: '-2px',
  },
}
const utils = {
  '.sr-only': {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: 0,
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    border: 0,
  },
}
const fonts = {
  '@font-face': [
    {
      src: `url('/fonts/soehne-web-buch.woff2') format('woff2')`,
      fontFamily: 'soehne-web-buch',
    },
    {
      src: `url('/fonts/soehne-web-buch-kursiv.woff2') format('woff2')`,
      fontFamily: 'soehne-web-buch-kursiv',
    },
    {
      src: `url('/fonts/soehne-web-dreiviertelfett.woff2') format('woff2')`,
      fontFamily: 'soehne-web-dreiviertelfett',
    },
    {
      src: `url('/fonts/soehne-web-leicht.woff2') format('woff2')`,
      fontFamily: 'soehne-web-leicht',
    },
    {
      src: `url('/fonts/soehne-web-leicht-kursiv.woff2') format('woff2')`,
      fontFamily: 'soehne-web-leicht-kursiv',
    },
  ],
}
const cookiebot = {
  '#cookiebot-declaration': {
    width: '980px',
    maxWidth: '90vw',
    margin: '0 auto',
    minHeight: '100vh',
  },
  '#cookiebot-declaration *': {
    fontFamily: 'soehne-web-buch, sans-serif',
    color: '$gray300',
    fontWeight: 300,
    lineHeight: 1.5,
    fontSize: 19,
  },
  '#cookiebot-declaration p': {
    fontWeight: 400,
  },
  '#cookiebot-declaration a': {
    fontSize: 16,
    textDecoration: 'underline',
  },
  '#cookiebot-declaration div': {
    fontWeight: 400,
  },
  '#cookiebot-declaration .CookieDeclarationType': {
    border: 'none',
    padding: 0,
  },
  '#cookiebot-declaration .CookieDeclarationType .CookieDeclarationTypeHeader':
    {
      fontFamily: 'soehne-web-leicht, sans-serif',
      color: 'black',
      mt: '$9',
      mb: '$6',
    },
  '#cookiebot-declaration .CookieDeclarationTable td, .CookieDeclarationTable th':
    {
      border: 'none',
      color: '$gray300',
      fontSize: 16,
      lineHeight: 1.4,
      fontWeight: 400,
    },
  '#cookiebot-declaration .CookieDeclarationTable th': {
    fontSize: 16,
    lineHeight: 1.4,
    color: 'black',
  },
}

export const globalStyles = globalCss({
  ...reset,
  ...projectDefaults,
  ...fonts,
  ...utils,
  ...cookiebot,
})
