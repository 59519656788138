import { FC } from 'react'

import { HeaderTab, MediaFieldType, PageLink } from '@liftfoils/models'
import { styled } from '@liftfoils/styles'

import { PortableText, Link, Media } from '@liftfoils/components'

const TabContent = styled('div', {
  display: 'grid',
  '@lg': {
    gridTemplateColumns: '1fr 1fr',
  },
})
const LinkInner = styled('div', {
  lift_font: 'heading02',
  display: 'flex',
  alignItems: 'center',
  height: '$headerHeight',
  borderBottom: '1px solid',
  borderColor: '$gray200',
  background: 'white',
  transition: 'color 200ms',
  color: '$gray500',
  variants: {
    pxSmall: {
      true: {
        px: '$4',
      },
      false: {
        px: '$8',
      },
    },
  },
  '@lg': {
    height: '$11',
  },
  '&:hover': {
    color: '$gray300',
  },
})
const LinkIcon = styled('div', {
  position: 'relative',
  overflow: 'hidden',
  variants: {
    isSmall: {
      true: {
        width: '$7',
        height: '$7',
        mx: '$7',
      },
      false: {
        borderRadius: '$r2',
        width: '3em',
        height: '2em',
        mr: '$8',
      },
    },
  },
})
const SecondCol = styled('div', {
  borderLeft: '1px solid',
  borderTop: '1px solid',
  borderColor: ' $gray200',
  display: 'grid',
  gridTemplateRows: `1fr $headerHeight`,
  gridRow: -1,
  '@lg': {
    gridRow: 'unset',
    borderTop: 'none',
    gridTemplateRows: `1fr $11`,
    rowGap: '$11',
  },
})
const SecondColBottom = styled('div', {
  display: 'grid',
  '@lg': {
    gridTemplateColumns: '1fr 1fr',
    '.LinkInner': { borderBottom: 'none' },
  },
})
const FirstCol = styled('div', {
  $p: 'M',
  display: 'grid',
  gridGap: '$8',
})

const ImageWrap = styled('div', {
  maxWidth: '340px',
  width: '100%',
  borderRadius: '$r1',
  overflow: 'hidden',
})
const ImageWithCaption = styled('div', {
  display: 'grid',
  gridGap: '$6',
  justifyItems: 'end',
  alignContent: 'start',
  textAlign: 'right',
  lift_font: 'body02',
  color: '$gray300',
  $p: 'M',
  '.highlighted': {
    color: '$gray500',
  },
})
const TextWrap = styled('div', {
  lift_font: 'heading01',
  maxWidth: '90%',
  color: '$gray300',
  '.highlighted': { color: '$gray500' },
  lineHeight: 1.3,
})
const CTAsWrap = styled('div', {
  justifySelf: 'start',
  alignSelf: 'end',
  display: 'inline-grid',
  gridAutoFlow: 'column',
  gridGap: '$6',
})

const StyledLink = styled(Link, {
  display: 'block',
  '&:focus-visible': {
    outlineOffset: '-2px',
  },
})

const DrawerLinkWithIcon: FC<{
  link: PageLink
  title: string
  image?: MediaFieldType
  isImageSmall?: boolean
}> = ({ link, title, image, isImageSmall }) => {
  return (
    <StyledLink {...link}>
      <LinkInner className={'LinkInner'} pxSmall={!!image}>
        {image && (
          <LinkIcon isSmall={!!isImageSmall}>
            <Media
              {...image}
              layout={'fill'}
              objectFit={'contain'}
              sizes={'100px'}
            />
          </LinkIcon>
        )}
        {title}
      </LinkInner>
    </StyledLink>
  )
}

export const CommonTab: FC<{ tab: HeaderTab }> = ({ tab }) => {
  return (
    <TabContent>
      <FirstCol>
        <TextWrap>
          {tab.description && <PortableText value={tab.description} />}
        </TextWrap>
        {tab.type !== 'imageWithCaption' && tab.image && (
          <ImageWrap>
            <Media {...tab.image} sizes={'400px'} />
          </ImageWrap>
        )}
        {tab.ctas && (
          <CTAsWrap>
            {tab.ctas.map((cta) => (
              <Link
                {...cta.link}
                appearance={'solidGray500'}
                size={'medium'}
                key={cta.title}
              >
                {cta.title}
              </Link>
            ))}
          </CTAsWrap>
        )}
      </FirstCol>

      <SecondCol>
        {tab.type === 'imageWithCaption' && tab.image && (
          <ImageWithCaption>
            <ImageWrap
              as={tab.imageLink?.href ? Link : 'div'}
              {...(tab.imageLink?.href && tab.imageLink)}
            >
              <Media {...tab.image} sizes={'400px'} />
            </ImageWrap>
            {tab.imageCaption && <PortableText value={tab.imageCaption} />}
          </ImageWithCaption>
        )}
        <div>
          {tab.links?.map((link) => (
            <DrawerLinkWithIcon key={link.title} {...link} />
          ))}
        </div>
        {tab.bottomLinks && (
          <SecondColBottom>
            {tab.bottomLinks.map((link) => {
              return (
                <DrawerLinkWithIcon key={link.title} {...link} isImageSmall />
              )
            })}
          </SecondColBottom>
        )}
      </SecondCol>
    </TabContent>
  )
}
