import {
  ShopifyCartFieldsFragment,
  ShopifyCartLineFieldsFragment,
  ShopifyComponentizableCartLineFieldsFragment,
} from '../generated/schemaSdk'
import { formatShopifyCartLine } from './formatShopifyCartLine'

export const BUNDLE_ID_KEY = '_bundleId'
export const BUNDLE_ID_PREFIX_ADDITIONAL = 'additional_'

const calculateLinesQuantity = (
  lines: ShopifyCartFieldsFragment['lines']['nodes'],
): number => {
  return (
    lines?.reduce((acc, line) => {
      if (
        !(
          line.__typename === 'CartLine' ||
          line.__typename === 'ComponentizableCartLine'
        )
      )
        return acc
      if (
        line.attributes
          ?.find((a) => a.key === BUNDLE_ID_KEY)
          ?.value?.startsWith(BUNDLE_ID_PREFIX_ADDITIONAL)
      )
        return acc
      return acc + line.quantity
    }, 0) ?? 0
  )
}

export const formatShopifyCart = (
  cart: ShopifyCartFieldsFragment | undefined | null,
) => {
  const cartLines = cart
    ? cart.lines.nodes.reduce<
        Array<
          | ShopifyCartLineFieldsFragment
          | ShopifyComponentizableCartLineFieldsFragment
        >
      >((acc, line) => {
        if (
          line.__typename === 'CartLine' ||
          line.__typename === 'ComponentizableCartLine'
        ) {
          return [...acc, line]
        }
        return acc
      }, [])
    : []

  return cart
    ? {
        ...cart,
        lines: cartLines.map((item) => formatShopifyCartLine(item)),
        totalQuantity: calculateLinesQuantity(cart.lines.nodes),
      }
    : null
}

export type ShopifyResolvedCart = ReturnType<typeof formatShopifyCart>
