import { styled } from '@liftfoils/styles'
import { RequestCallbackForm } from '@liftfoils/forms'
import { Close } from '@liftfoils/icons'
import { SiteConfiguration } from '@liftfoils/models'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Button, Modal, PortableText } from '@liftfoils/components'

const Content = styled('div', {
  maxWidth: '100%',
  p: '$9',
  width: '400px',
  display: 'grid',
  gridGap: '$7',
  textAlign: 'center',
  lift_font: 'body02',
})

const Line = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$5',
  '@sm': {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})

const Title = styled('p', {
  mb: '$3',
})
const GrayText = styled('p', {
  color: '$gray300',
})
const Border = styled('div', {
  height: '1px',
  content: ' ',
  backgroundColor: '$gray500_01',
})
const CloseButtonWrapper = styled('div', {
  position: 'absolute',
  right: 0,
  top: 0,
  width: '$11',
  height: '$11',
  display: 'grid',
  placeItems: 'center',
})

type ModalAssistanceProps = {
  assistance?: SiteConfiguration['assistance']
  isOpen: boolean
  handleClose: () => void
}

const VIEWS = {
  DEFAULT: 'DEFAULT',
  REQUEST_CALLBACK: 'REQUEST_CALLBACK',
} as const

export const ModalAssistance = ({
  assistance,
  isOpen,
  handleClose,
}: ModalAssistanceProps) => {
  const { t } = useTranslation('callUs')
  const [view, setView] = useState<keyof typeof VIEWS>(VIEWS.DEFAULT)

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      onAfterClose={() => setView(VIEWS.DEFAULT)}
      variant={'white-rounded'}
    >
      {view === VIEWS.DEFAULT && (
        <>
          <CloseButtonWrapper>
            <Button onClick={handleClose} appearance={'flex'}>
              <Close />
            </Button>
          </CloseButtonWrapper>
          <Content>
            <div>
              <Title>{assistance?.heading}</Title>
              <GrayText>
                <PortableText value={assistance?.description} />
              </GrayText>
            </div>
            {assistance?.phone && (
              <>
                <Border />
                <Line>
                  <GrayText>{t('callUs')}</GrayText>
                  <div>{assistance.phone}</div>
                </Line>
              </>
            )}
            <Border />
            <Line>
              <GrayText>{t('callback')}</GrayText>
              <Button
                appearance="solidGray500"
                size="small"
                onClick={() => setView(VIEWS.REQUEST_CALLBACK)}
              >
                {t('sendRequest')}
              </Button>
            </Line>
            {assistance?.email && (
              <>
                <Border />
                <Line>
                  <GrayText>{t('emailUs')}</GrayText>
                  <div>{assistance.email}</div>
                </Line>
              </>
            )}
            <Border />
          </Content>
        </>
      )}

      {view === VIEWS.REQUEST_CALLBACK && (
        <>
          <CloseButtonWrapper>
            <Button onClick={handleClose} appearance={'flex'}>
              <Close />
            </Button>
          </CloseButtonWrapper>
          <Content>
            <div>
              <Title>{assistance?.heading}</Title>
              <GrayText>
                <PortableText value={assistance?.description} />
              </GrayText>
            </div>
            <RequestCallbackForm />
          </Content>
        </>
      )}
    </Modal>
  )
}
