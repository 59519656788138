import { formatShopifyImage, formatShopifyImages } from './formatShopifyImage'
import { ShopifyProductExtendedFieldsFragment } from '../generated/schemaSdk'
import { formatShopifyVariantBasic } from './formatShopifyVariantBasic'
import { formatProductMetafields } from './formatProductMetafields'

export const formatShopifyProductExtended = (
  product: ShopifyProductExtendedFieldsFragment,
) => ({
  ...product,
  featuredImage: product.featuredImage
    ? formatShopifyImage(product.featuredImage)
    : null,
  images: product.images?.nodes
    ? formatShopifyImages(product.images.nodes)
    : null,
  variants: { nodes: product.variants.nodes.map(formatShopifyVariantBasic) },
  metafields: formatProductMetafields(product.metafields),
})

export type ShopifyResolvedProductExtended = ReturnType<
  typeof formatShopifyProductExtended
>
