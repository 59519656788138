import { useEffect, useState } from 'react'
import { ShopifyResolvedVariantBasic } from '@liftfoils/shopify-sdk'

import { useRouter } from 'next/router'
import { useAccount } from '../account/CustomerAccountProvider'
import { getVariantsSumPrice } from '../helpers/getVariantsSumPrice'
import { getNodesWithBuyerContext } from '../getNodesWithBuyerContext'

export const useClientVariants = (productVariantIds: Array<string>) => {
  const [clientVariants, setClientVariants] = useState<
    ShopifyResolvedVariantBasic[] | undefined
  >(undefined)
  const [isLoading, setLoading] = useState(false)
  const { locale } = useRouter()
  const { account } = useAccount()

  const { companyLocationId, customerAccessToken } = account ?? {}

  const buyer =
    customerAccessToken && companyLocationId
      ? { customerAccessToken, companyLocationId }
      : undefined

  useEffect(() => {
    const refreshVariants = async () => {
      if (!locale) return
      setClientVariants(undefined)

      setLoading(true)
      const nodes = await getNodesWithBuyerContext(
        productVariantIds,
        locale,
        buyer,
      )

      const incomingVariants = nodes.filter(
        (node) => node.__typename === 'ResolvedVariantBasic',
      ) as ShopifyResolvedVariantBasic[]

      setClientVariants(incomingVariants)
      setLoading(false)
    }
    refreshVariants()
  }, [locale, JSON.stringify(account), JSON.stringify(productVariantIds)])

  return {
    processing: isLoading,
    variants: clientVariants,
    sumPrice: clientVariants ? getVariantsSumPrice(clientVariants) : undefined,
  }
}
