import React, {
  ChangeEventHandler,
  FormEventHandler,
  KeyboardEventHandler,
} from 'react'
import { Button } from '@liftfoils/components'
import { styled } from '@liftfoils/styles'
import { SearchIconBig } from '@liftfoils/icons'
import { useTranslation } from 'react-i18next'

const InputForm = styled('form', {
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  height: 36,
})

const Input = styled('input', {
  backgroundColor: 'transparent',
  height: 36,
  borderRadius: 0,
  border: 0,
  margin: 0,
  width: '100%',
  lift_font: 'heading02',
  color: '$gray500',
  '&::placeholder': {
    color: '$gray500_06',
  },
})
const InputWrap = styled('div', {
  position: 'relative',
  display: 'grid',
})

const SearchInputSubmit = styled(Button, {
  background: 'transparent',
  marginRight: '$7',
  display: 'grid',
  placeContent: 'center',
  '&:focus-visible': {
    outlineOffset: '3px',
  },
})

interface SearchInputProps {
  value: string
  onResetRequest?: () => void
  onSubmit?: (val: string) => void
  onChange?: (value: string) => void
  className?: string
  isLoading?: boolean
  isDisabled?: boolean
}

export const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  (
    { value, onResetRequest, onChange, onSubmit, className, isDisabled },
    ref,
  ) => {
    const { t } = useTranslation('search')
    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      const { value } = e.currentTarget
      if (onChange) {
        onChange(value)
      }
    }

    const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
      e.preventDefault()
      if (onSubmit) {
        onSubmit(value)
      }
    }

    const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (e) => {
      switch (e.key) {
        case 'Escape': {
          if (onResetRequest) {
            onResetRequest()
          }
          break
        }
      }
    }

    return (
      <InputForm className={className} onSubmit={handleSubmit}>
        <SearchInputSubmit disabled={isDisabled}>
          <SearchIconBig />
        </SearchInputSubmit>
        <InputWrap>
          <Input
            type="search"
            placeholder={t('searchLiftfoils') ?? ''}
            value={value}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            ref={ref}
            autoFocus
            autoComplete="off"
            disabled={isDisabled}
          />
        </InputWrap>
      </InputForm>
    )
  },
)
