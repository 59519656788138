import { screenSizes, ScreenSize } from '@liftfoils/styles'

// For the slot width, rather than providing an absolute width (for example, 480px),
// you can alternatively provide a width relative to the viewport (for example, 50vw) — but not a percentage.
type MediaSizeValueType = `${number}px` | `${number}vw`

type MqType = Partial<Record<ScreenSize, MediaSizeValueType>>

export type MediaSizesProps =
  | (MqType & {
      default?: MediaSizeValueType
    })
  | string
  | undefined

const isScreenSize = (screenSize: unknown): screenSize is ScreenSize => {
  return (
    typeof screenSize === 'string' &&
    Object.keys(screenSizes).includes(screenSize)
  )
}

const DEFAULT_SIZE = '100vw'

/**
 * convert MediaSizeProps to proper sizes string
 * see docs for reference: https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images
 */
export const buildSizes = (props: MediaSizesProps): string => {
  if (!props) {
    return DEFAULT_SIZE
  }

  if (typeof props === 'string') {
    return props
  }

  const { default: defaultSize = DEFAULT_SIZE, ...mq } = props

  const mqEntries = Object.entries(mq)
  if (mqEntries.length > 0) {
    return (
      Object.entries(mqEntries)
        // convert breakpoins names to px values
        .map(([, [minWidthSize, value]]) => {
          if (isScreenSize(minWidthSize)) {
            return [screenSizes[minWidthSize], value]
          }
          return []
        })
        .filter((v) => v.length)
        // The browser ignores everything after the first matching condition, so be careful how you order the media conditions.
        .sort((a, b) => {
          if (typeof a[0] === 'number' && typeof b[0] === 'number') {
            return b[0] - a[0]
          }
          return 0
        })
        // vreate A media condition ((min-width: <BREAKPOINT>) <VALUE>) - "when the viewport width is <BREAKPOINT> pixels or more".
        // The last slot width has no media condition (this is the default that is chosen when none of the media conditions are true).
        .map(
          ([minWidthSize, value]) => `(min-width: ${minWidthSize}px) ${value}`,
        )
        .join(', ') + `, ${defaultSize}`
    )
  }

  if (typeof defaultSize === 'string') {
    return defaultSize
  }

  return DEFAULT_SIZE
}
