import { styled } from '@liftfoils/styles'
import { Button } from '../Button/Button'
import { Modal } from '../Modal/Modal'
import { Close } from '@liftfoils/icons'
import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'
import { Link } from '../Link/Link'

const Content = styled('div', {
  maxWidth: '100%',
  p: '$9',
  width: '400px',
  display: 'grid',
  gridGap: '$7',
  textAlign: 'center',
  lift_font: 'body02',
})

const Line = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$5',
  '@sm': {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})

const GrayText = styled('p', {
  color: '$gray300',
})

const Title = styled('p', {
  mb: '$7',
})

const Border = styled('div', {
  height: '1px',
  content: ' ',
  backgroundColor: '$gray500_01',
})
const CloseButtonWrapper = styled('div', {
  position: 'absolute',
  right: 0,
  top: 0,
  width: '$11',
  height: '$11',
  display: 'grid',
  placeItems: 'center',
})

const LabelBtn = styled('button', {
  lift_font: 'body06',
  color: '$gray300',
  textAlign: 'center',
  margin: 0,
  padding: 0,
  border: 0,
  background: 'none',
  '@md': {
    textAlign: 'left',
    width: 'auto',
    mt: '$0',
  },
})

export const Credits: FC = () => {
  const { t } = useTranslation('credits')
  const [isOpen, setIsOpen] = useState(false)

  const handleRequestOpen = () => {
    setIsOpen(true)
  }

  const handleRequestClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <LabelBtn onClick={handleRequestOpen}>Credits</LabelBtn>
      <Modal
        isOpen={isOpen}
        onRequestClose={handleRequestClose}
        variant={'white-rounded'}
      >
        <CloseButtonWrapper>
          <Button onClick={handleRequestClose} appearance={'flex'}>
            <Close />
          </Button>
        </CloseButtonWrapper>
        <Content>
          <Title>{t('siteCredits')}</Title>
          <Border />
          <Line>
            <GrayText>{t('development')}</GrayText>
            <Link
              href="https://commerce-ui.com/?utm_source=liftfoils.com&utm_campaign=Credits"
              target="_blank"
              appearance="solidGray500"
              size="small"
            >
              Commerce UI
            </Link>
          </Line>

          <Border />
          <Line>
            <GrayText>{t('design')}</GrayText>
            <Link
              href="https://aniaetlucie.com/"
              target="_blank"
              appearance="solidGray500"
              size="small"
            >
              Ania et Lucie
            </Link>
          </Line>
          <Border />
        </Content>
      </Modal>
    </>
  )
}
