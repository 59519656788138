import {
  AnswersType,
  FormattedAnswerStep,
  FormattedClassicFoilGearSelectorSteps,
  FormattedConfigurationStep,
  MediaFieldType,
  PortableTextBlocks,
} from '@liftfoils/models'

export type Answer = {
  title: string
  description: string
  image: MediaFieldType
}

export type Step = {
  title: string
  description: string
  stepType: keyof AnswersType
  answers: Answer[]
}

export type SanityClassicFoilGearSelectorType = {
  configurationTitle: string
  buyButtonDescription?: string
  sendToEmailDescription?: string
  disclaimer: PortableTextBlocks
  outOfStockDisclaimer: PortableTextBlocks
  steps: Array<Step>
}

/*
 * This function turns Sanity data into an array to be used for each step
 * in Gear Selector.
 */
export const mapGearSelector = (
  data?: SanityClassicFoilGearSelectorType,
): FormattedClassicFoilGearSelectorSteps | undefined => {
  if (!data) {
    return undefined
  }

  const quizSteps: FormattedAnswerStep[] = data.steps.map((step) => ({
    title: step.title,
    answerId: step.stepType,
    additionalDescription: step.description,
    answers: step.answers.map((answer) => ({
      title: answer.title,
      value: answer.title,
      description: answer.description,
      media: answer.image,
    })),
  }))
  const configurationStep: FormattedConfigurationStep = {
    title: data.configurationTitle,
    buyButtonDescription: data.buyButtonDescription || '',
    sendToEmailDescription: data.sendToEmailDescription || '',
    disclaimer: data.disclaimer,
    outOfStockDisclaimer: data.outOfStockDisclaimer,
  }

  return [...quizSteps, configurationStep]
}
