import { LocaleId } from '@liftfoils/configs'
import { GraphQLClient } from 'graphql-request'
import {
  SHOPIFY_DOMAIN,
  STOREFRONT_API_VERSION,
  STOREFRONT_API_ACCESS_TOKEN,
} from './shopify.config'

export function createStorefrontApiClient(localeId: LocaleId) {
  if (!(STOREFRONT_API_ACCESS_TOKEN && SHOPIFY_DOMAIN))
    throw new Error('Please setup env variables for the project')

  return new GraphQLClient(
    `${SHOPIFY_DOMAIN}/api/${STOREFRONT_API_VERSION}/graphql.json`,
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': STOREFRONT_API_ACCESS_TOKEN,
        'Accept-Language': localeId,
      },
    },
  )
}
