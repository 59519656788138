import { styled } from '@liftfoils/styles'

const centeredContainer = { maxWidth: '980px', mx: 'auto' }

export const EditorialBlockWrap = styled('div', {
  lift_font: 'heading03',
  alignSelf: 'center',
  mx: 'auto',
  variants: {
    theme: {
      dark: {
        color: '$white_06',
        '.highlighted, h1, h3': {
          color: 'white',
        },
      },
      light: {
        color: '$gray300',
        '.highlighted, h1, h3': {
          color: '$gray500',
        },
        a: {
          color: '$teal500',
        },
      },
    },
  },

  '.youtubePlayer, .instagramEmbed': {
    ...centeredContainer,
    '+ *': {
      $mt: 'L',
    },
  },
  '.shopTheStory': {
    ...centeredContainer,
    a: { textDecoration: 'none', color: '$white' },
  },
  a: { textDecoration: 'underline' },
  blockquote: {
    ...centeredContainer,
    lift_font: 'heading01',
    textAlign: 'center',
    mx: 'auto',
    '+ *': {
      $mt: 'L',
    },
  },
  h1: {
    ...centeredContainer,
    lift_font: 'heading01',
    $pb: 'M',
    textAlign: 'center',
  },
  h2: {
    ...centeredContainer,
    lift_font: 'heading01',
    '+ *': {
      mt: '$9',
    },
  },
  h3: {
    ...centeredContainer,
    lift_font: 'heading02',
    '+ *': {
      mt: '$7',
    },
  },
  '> p': { ...centeredContainer },
  'div > p': {
    ...centeredContainer,
  },
  'p + *': {
    mt: '$9',
  },
  'p + div': {
    pt: '$9',
    mt: '$0',
  },
  'p + h2': {
    $mt: 'L',
  },
  'p + h3': {
    $mt: 'L',
  },
  'p + blockquote': {
    $mt: 'L',
  },
  'ol, ul': {
    ...centeredContainer,
    listStyle: 'outside',
    pl: '32px',
    '+ *': {
      mt: '$9',
    },
    '+ h2': {
      $mt: 'L',
    },
    '+ h3': {
      $mt: 'L',
    },
    li: {
      mt: '$3',
    },
  },
  'ol > li': {
    listStyleType: 'decimal',
  },
  'ul > li': {
    listStyleType: 'disc',
  },
})
