import { styled } from '@liftfoils/styles'
import { Link } from '../Link/Link'
import { ReactNode } from 'react'

const RootLink = styled(Link, {
  position: 'relative',
  display: 'grid',
})

const Overlay = styled('div', {
  pointerEvents: 'none',
  border: '2px solid $teal500',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 2,
  borderRadius: '$r1',
  opacity: 0,
  transition: 'opacity 100ms',
})

const CardWrap = styled('div', {
  gridArea: '1/1',
  display: 'grid',
  borderRadius: '$r1',
  gridTemplateRows: 'auto $9 1fr',
  border: '1px solid $gray200',
  overflow: 'hidden',
  '&:hover': {
    [`${Overlay}`]: {
      opacity: 1,
    },
  },
})

type CardProps = {
  children: ReactNode
  href: string
  shallow?: boolean
  scroll?: boolean
}

function Card({ children, href, shallow, scroll }: CardProps) {
  return (
    <RootLink href={href} shallow={shallow} scroll={scroll}>
      <CardWrap>
        {children}
        <Overlay />
      </CardWrap>
    </RootLink>
  )
}

export { Card }
