import { FC } from 'react'
import { PhoneField, PhoneFieldsProps } from './PhoneField/PhoneField'
import { useController, UseControllerProps } from 'react-hook-form'
import { validatePhone } from '../helpers/validatePhone'
import { useTranslation } from 'react-i18next'

type FormTextFieldProps = UseControllerProps<any> & PhoneFieldsProps

export const FormPhoneField: FC<FormTextFieldProps> = ({
  rules = {},
  control,
  name,
  shouldUnregister,
  defaultValue,
  helpText,
  ...restOfProps
}) => {
  const { t } = useTranslation('forms')
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: { ...validatePhone(t), ...rules },
    shouldUnregister,
    defaultValue,
  })

  return (
    <PhoneField
      id={name}
      helpText={helpText || error?.message}
      invalid={invalid}
      {...field}
      {...restOfProps}
    />
  )
}
