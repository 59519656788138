import { LocaleId } from '@liftfoils/configs'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { groq } from 'next-sanity'
import { createPageBuilderProjection } from './projections/createPageBuilderProjection'
import { getClient } from './getClient'
import { PageBuilderSection, PageResponse } from '@liftfoils/models'
import { createEventPageProjection } from './getEventPageByPath'
import { createStoryPageProjection } from './projections/createStoryPageProjection'

export const createPageByDocumentIdQuery = (
  documentId: string,
  localeId: LocaleId,
) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const STORY_PAGE_PROJECTION = createStoryPageProjection(localeId)
  const EVENT_PAGE_PROJECTION = createEventPageProjection(localeId)

  return groq`*[_id == "${documentId}"][0]{
    _type == 'page' => {
      initialHeaderStyle,
      showFooter,
      title,
      pageBuilder[]${PAGE_BUILDER_PROJECTION}
    },
    _type == "efoil" => {
      "type": _type,
    },
    _type == "product" => {
      "type": _type,
    },
    _type == 'story' => ${STORY_PAGE_PROJECTION},
    _type == 'event' => ${EVENT_PAGE_PROJECTION}
  }`
}

export const getPageByDocumentId = async (
  documentId: string,
  localeRegionString: string,
  preview = false,
): Promise<PageResponse<PageBuilderSection[]>> => {
  const [, localeId] = getLocaleRegionIdFromPath(localeRegionString)

  const query = createPageByDocumentIdQuery(documentId, localeId)

  return await getClient(preview).fetch(query)
}
