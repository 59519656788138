export const SHOPIFY_DOMAIN = process.env.NEXT_PUBLIC_SHOPIFY_US_DOMAIN
export const STOREFRONT_API_ACCESS_TOKEN =
  process.env.NEXT_PUBLIC_SHOPIFY_US_ACCESS_TOKEN
export const STOREFRONT_API_VERSION = '2024-04'

export const SHOP_ID = process.env.NEXT_PUBLIC_SHOPIFY_SHOP_ID
export const SHOPIFY_OAUTH_BASE_PATH = `https://shopify.com/${SHOP_ID}/auth/oauth`
export const CLIENT_ID = process.env.NEXT_PUBLIC_SHOPIFY_CLIENT_ID
export const SHOPIFY_SESSION_COOKIE_KEY_NAME = 'shopify_customer_session'
export const CLIENT_SECRET = process.env.SHOPIFY_CLIENT_SECRET
export const CUSTOMER_API_URL = `https://shopify.com/${SHOP_ID}/account/customer/api/unstable/graphql`

export const CUSTOMER_ACCOUNT_DEVELOPMENT_MODE = false

export const AUTHORIZATION_REDIRECT_URI = CUSTOMER_ACCOUNT_DEVELOPMENT_MODE
  ? 'https://liftfoils.com'
  : process.env.BASE_URL
