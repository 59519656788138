import Cookies from 'js-cookie'
import {
  CLIENT_ID,
  AUTHORIZATION_REDIRECT_URI,
  SHOPIFY_SESSION_COOKIE_KEY_NAME,
  SHOPIFY_OAUTH_BASE_PATH,
} from '../../shopify.config'
import { gql } from 'graphql-request'
import { createCustomerAccountApiClient } from '../../createCustomerAccountApiClient'

const getExpiresInDate = (expiresIn: number) =>
  new Date(Date.now() + expiresIn * 1000)

export const exchangeToken = async () => {
  const { access_token, expires_in } = await fetch(
    `/api/access-token/exchange`,
    {
      method: 'POST',
    },
  ).then((res) => res.json())
  const session = Cookies.get(SHOPIFY_SESSION_COOKIE_KEY_NAME)
  if (!session) return
  Cookies.set(
    SHOPIFY_SESSION_COOKIE_KEY_NAME,
    JSON.stringify({ ...JSON.parse(session), access_token }),
    {
      expires: getExpiresInDate(expires_in),
    },
  )
  return access_token
}

export const obtainToken = async (authorizationCode: string) => {
  const { access_token, expires_in, refresh_token } = await fetch(
    `/api/access-token/obtain?code=${authorizationCode}`,
    {
      method: 'POST',
    },
  ).then((res) => res.json())
  Cookies.set(
    SHOPIFY_SESSION_COOKIE_KEY_NAME,
    JSON.stringify({ access_token, refresh_token }),
    {
      expires: getExpiresInDate(expires_in),
    },
  )
}

export const refreshToken = async () => {
  const sessionCookie = Cookies.get(SHOPIFY_SESSION_COOKIE_KEY_NAME)
  if (!sessionCookie) return { error: 'NO_SESSION_COOKIE' }
  const refreshResponse = await fetch(`/api/access-token/refresh`, {
    method: 'POST',
  }).then((res) => res.json()) // { error, access_token, refresh_token, expires_in }

  Cookies.set(
    SHOPIFY_SESSION_COOKIE_KEY_NAME,
    JSON.stringify({
      ...JSON.parse(sessionCookie),
      access_token: refreshResponse.access_token,
      refresh_token: refreshResponse.refresh_token,
    }),
    {
      expires: getExpiresInDate(refreshResponse.expires_in),
    },
  )

  return refreshResponse
}

export const authorize = async () => {
  if (!CLIENT_ID) throw new Error('Please setup env variables for the project')

  const authorization = {
    url: `${SHOPIFY_OAUTH_BASE_PATH}/authorize`,
    params: {
      scope: 'openid email https://api.customers.com/auth/customer.graphql',
      client_id: CLIENT_ID,
      response_type: 'code',
      redirect_uri: AUTHORIZATION_REDIRECT_URI,
    },
  }

  window.location.href = `${authorization.url}?${new URLSearchParams(
    authorization.params,
  )}`
}

export const getUserData = async (localeId: string, accessToken: string) => {
  // TODO use gql codegen (similar to shopify-sdk)
  const client = createCustomerAccountApiClient(localeId, accessToken)
  const MUTATION = gql`
    mutation storefrontCustomerAccessTokenCreate {
      storefrontCustomerAccessTokenCreate {
        customerAccessToken
      }
    }
  `
  const QUERY = gql`
    query customer {
      customer {
        emailAddress {
          emailAddress
        }
        id
        firstName
        companyContacts(first: 1) {
          nodes {
            company {
              id
              name
              locations(first: 5) {
                nodes {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `
  const mutationRes = await client.request(MUTATION)
  const queryRes = await client.request(QUERY)
  return {
    email: queryRes.customer.emailAddress.emailAddress,
    id: queryRes.customer.id,
    firstName: queryRes.customer.firstName,
    customerAccessToken:
      mutationRes.storefrontCustomerAccessTokenCreate.customerAccessToken,
    companyLocationId:
      queryRes.customer.companyContacts.nodes[0]?.company.locations.nodes[0]
        ?.id,
  }
}
