import { DEFAULT_LOCALE_ID, LocaleId } from '@liftfoils/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createBaseImageProjection } from './createBaseImageProjection'
import { createPortableTextProjection } from './createPortableTextProjection'
import { createEditorialPortableTextProjection } from './createEditorialPortableTextProjection'
import { createLinkProjection } from './createLinkProjection'
import { createMediaProjection } from './createMediaProjection'

export const createPageBuilderProjection = (localeId: LocaleId) => {
  const LINK_PROJECTION = createLinkProjection(localeId)
  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(localeId)
  const EDITORIAL_PORTABLE_TEXT_PROJECTION =
    createEditorialPortableTextProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const BASE_IMAGE_PROJECTION = createBaseImageProjection(localeId)
  return groq`{
    _type == 'sectionHero' => {
      _key,
      _type,
      background${MEDIA_PROJECTION},
      ${coalesceLocaleField('ctaLabel', localeId)},
      ctaLink${LINK_PROJECTION},
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('content', localeId)}[]${PORTABLE_TEXT_PROJECTION},
    },
    _type == 'sectionTextWithFeatures' => {
      _key,
      _type,
      ${coalesceLocaleField('buttonLabel', localeId)},
      buttonLink${LINK_PROJECTION},
      ${coalesceLocaleField('text', localeId)}[]${PORTABLE_TEXT_PROJECTION},
      ${coalesceLocaleField('title', localeId)},
      features[]{
        _key,
        ${coalesceLocaleField('name', localeId)},
        ${coalesceLocaleField('value', localeId)},
      }
    },
    _type == "sectionVideoStories" => {
      _key,
      _type,
      ${coalesceLocaleField('buttonLabel', localeId)},
      buttonLink${LINK_PROJECTION},
      ${coalesceLocaleField('text', localeId)}[]${PORTABLE_TEXT_PROJECTION},
      ${coalesceLocaleField('title', localeId)},
      stories[]{
        _key,
        ${coalesceLocaleField('title', localeId)},
        video${MEDIA_PROJECTION}
      }
    },
    _type == "sectionVideoPlaylist" => {
      _key,
      _type,
      background${MEDIA_PROJECTION},
      ${coalesceLocaleField('buttonLabel', localeId)},
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('videoUrl', localeId)},
      ${coalesceLocaleField('videosHeading', localeId)},
      videos[]{
        _key,
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField('videoUrl', localeId)},
        thumbnail${createMediaProjection(localeId, 'MEDIUM')}
      }
    },
    _type == "sectionPress" => {
      _key,
      _type,
      ${coalesceLocaleField('title', localeId)},
      articles[]{
        _key,
        ${coalesceLocaleField('title', localeId)},
        logo${BASE_IMAGE_PROJECTION},
        link${LINK_PROJECTION}
      }
    },
    _type == "sectionCardsWithDescription" => {
      _key,
      _type,
      ${coalesceLocaleField('title', localeId)},
      cards[]{
        _key,
        ${coalesceLocaleField(
          'description',
          localeId,
        )}[]${PORTABLE_TEXT_PROJECTION},
        background${MEDIA_PROJECTION}
      }
    },
    _type == "sectionFullscreenInfo" => {
      _key,
      _type,
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('heading', localeId)},
      ${coalesceLocaleField(
        'description',
        localeId,
      )}[]${PORTABLE_TEXT_PROJECTION},
      background${MEDIA_PROJECTION},
      buttonLink${LINK_PROJECTION},
      ${coalesceLocaleField('buttonLabel', localeId)},
    },
    _type == "sectionHugeImage" => {
      _key,
      _type,
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('caption', localeId)},
      ${coalesceLocaleField(
        'description',
        localeId,
      )}[]${PORTABLE_TEXT_PROJECTION},
      image${BASE_IMAGE_PROJECTION}
    },
    _type == "sectionUnequalCards" => {
      _key,
      _type,
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('caption', localeId)}[]${PORTABLE_TEXT_PROJECTION},
      cards[]{
        _key,
        ${coalesceLocaleField('title', localeId)},
        image${MEDIA_PROJECTION},
        ${coalesceLocaleField('buttonLabel', localeId)},
        buttonLink${LINK_PROJECTION},
      },
    },
    _type == "sectionProductHero" => {
      _key,
      _type,
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('description', localeId)},
      background${MEDIA_PROJECTION},
      ${coalesceLocaleField('ctaLabel', localeId)},
      ctaLink${LINK_PROJECTION},
      parameters[]{
        ${coalesceLocaleField('label', localeId)},
        ${coalesceLocaleField('value', localeId)},
      }
    },
    _type == "sectionProductFeatures" => {
      _key,
      _type,
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('text', localeId)},
      ${coalesceLocaleField('buttonLabel', localeId)},
      buttonLink${LINK_PROJECTION},
      features[]{
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField('description', localeId)},
        image${MEDIA_PROJECTION}
      }
    },
    _type == "sectionProductVideo" => {
      _key,
      _type,
      ${coalesceLocaleField('title', localeId)},
      background${MEDIA_PROJECTION},
      ${coalesceLocaleField('buttonLabel', localeId)},
      ${coalesceLocaleField('leftLabel', localeId)},
      ${coalesceLocaleField('rightLabel', localeId)},
      ${coalesceLocaleField('modalVideo', localeId)},

    },
    _type == "sectionFeaturesSlider" => {
      _key,
      _type,
      ${coalesceLocaleField('title', localeId)},
      features[]{
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField('shortTitle', localeId)},
        ${coalesceLocaleField('description', localeId)},
        background${MEDIA_PROJECTION},
      }
    },
    _type == "sectionHalfImage" => {
      _key,
      _type,
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField(
        'description',
        localeId,
      )}[]${PORTABLE_TEXT_PROJECTION},
      media${MEDIA_PROJECTION},
    },
    _type == "sectionMap" => {
      _key,
      _type,
      initialLocation,
      initialZoomLevel,
      "pins": *[!(_id in path('drafts.**')) && _type == "location"]{
        _id,
        "type": locationType,
        name,
        coordinates,
        country,
        state,
        city,
        zip,
        addressLine1,
        addressLine2,
        email,
        phone,
        website
      }
    },
    _type == "sectionRiderIntroduction" => {
      _key,
      _type,
      "description": select(
        type=='story'=> story-> {
          ${coalesceLocaleField(
            'shortDescription',
            localeId,
          )}[]${PORTABLE_TEXT_PROJECTION}
        }.shortDescription,
        riderDescription{
          "value":coalesce(${localeId}, ${DEFAULT_LOCALE_ID})[]${PORTABLE_TEXT_PROJECTION}
        }.value
      ),
      "background": backgroundImage${MEDIA_PROJECTION},
      "rider": rider -> {
        name,
        "location": baseLocation,
        "social": socialMedia
      },
      "story": story -> {
        ${coalesceLocaleField('title', localeId)},
        "category": categoryNew->{title}.title,
        "path": path.current
      },
      sidePanel{
        "_type": type,
        cards[]{
          ${coalesceLocaleField('title', localeId)},
          ${coalesceLocaleField('subtitle', localeId)},
          ${coalesceLocaleField('video', localeId)},
          thumbnail${MEDIA_PROJECTION}
        },
        ${coalesceLocaleField('quote', localeId)}[]${PORTABLE_TEXT_PROJECTION}
      }
    },
    _type == "sectionStoriesLanding" => {
      _key,
      _type,
      featuredStory->{
        ${coalesceLocaleField('title', localeId)},
        "category": categoryNew->{title}.title,
        "path": path.current,
        background${MEDIA_PROJECTION},
        date
      },
    "filters": *[_type == "storiesSettings"] {
      filtersOrder[]->{
        title,
        "slug":slug.current
      },
    }[0].filtersOrder,
      "stories": *[!(_id in path('drafts.**')) && _type=="story"]{
        ${coalesceLocaleField('title', localeId)},
        "category": categoryNew->{title}.title,
        "path": path.current,
        background${MEDIA_PROJECTION},
        date
      } | order(date desc)
    },
    _type == "sectionEventsLanding" => {
      _key,
      _type,
      ${coalesceLocaleField('heading', localeId)},
      featuredEvent->{
        ${coalesceLocaleField('title', localeId)},
        category,
        "path": path.current,
        background${MEDIA_PROJECTION},
        startDate,
        endDate,
        address{addressLine1,addressLine2},
        buttonLink${LINK_PROJECTION},
      },
      "ongoingEvents": *[!(_id in path('drafts.**') ) && _type=="event" && dateTime(now()) > dateTime(startDate+'T00:00:00Z') && dateTime(now()) < dateTime(endDate+'T23:59:59Z')] | order(startDate asc) {
        ${coalesceLocaleField('title', localeId)},
          startDate,
          endDate,
          category,
          address{addressLine1,addressLine2},
          "path": path.current,
          ${coalesceLocaleField(
            'introduction',
            localeId,
          )}[]${PORTABLE_TEXT_PROJECTION}
      },
      "upcomingEvents": *[!(_id in path('drafts.**') ) && _type=="event" && dateTime(now()) < dateTime(startDate+'T00:00:00Z')] | order(startDate asc){
        ${coalesceLocaleField('title', localeId)},
        startDate,
        endDate,
        category,
        address{addressLine1,addressLine2},
        "path": path.current,
        ${coalesceLocaleField(
          'introduction',
          localeId,
        )}[]${PORTABLE_TEXT_PROJECTION}
      },
      "pastEvents": *[!(_id in path('drafts.**')) && _type=="event" && dateTime(now()) > dateTime(endDate+'T23:59:59Z')] | order(startDate desc){
        ${coalesceLocaleField('title', localeId)},
        startDate,
        endDate,
        category,
        address{addressLine1,addressLine2},
      }
    },
    _type == 'sectionEditorialContent' => {
      _key,
      _type,
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField(
        'content',
        localeId,
      )}[]${EDITORIAL_PORTABLE_TEXT_PROJECTION},
      "shopifyIds": {
        "productBasic": [
          ...coalesce(content.${localeId}, content.${DEFAULT_LOCALE_ID})[]{
            _type == "sectionShopTheStory" => {
              "id": product->{
                "id":
                  select(
                    _type == "efoil"   =>  mainProduct -> store.gid,
                    _type == "product" => store.gid,
                  )
                }.id,
              },
            }.id,
          ],
        },
      "theme": 'light'
    },
    _type == 'sectionCollection' => {
      _key,
      _type,
      "_legacy_shopifyIds": [
        ...subcollections[].items[_type == 'reference']->{
          _type == "product" => {
            "id": _id
          },
          _type == "configurator" => {
            "id": shopifyProduct -> {_id}._id
          },
          _type == "efoil" => {
            "id": mainProduct -> {_id}._id
          }
        }.id,
        ...subcollections[].items[_type == 'reference']->{
          _type == "product" => {
            "ids": store.variants[]->{_id}._id
          },
          _type == "configurator" => {
            "ids": shopifyProduct->store.variants[]->{_id}._id
          },
          _type == "efoil" => {
            "ids": mainProduct->store.variants[]->{_id}._id
          }
        }.ids[]
      ],
      "shopifyIds": {
        "productBasic": [
          ...subcollections[].items[]-> {
            "id":  select(
              _type == "efoil"   => mainProduct -> store.gid,
              _type == "product" => store.gid,
            )
          }.id
        ]
      },
      ${coalesceLocaleField('title', localeId)},
      subcollections[]{
        _key,
        ${coalesceLocaleField('title', localeId)},
        items[]{
          _type == 'promoTile' => {
            _type,
            background${MEDIA_PROJECTION},
            link${LINK_PROJECTION},
            ${coalesceLocaleField('label', localeId)}
          },
          _type == 'reference' => @-> {
            _type,
            _type == "product" => {
              "id": store.gid,
              "link": store.slug.current
            },
             _type == "efoil" => {
              "id": mainProduct -> store.gid,
              "link": mainProduct -> store.slug.current,
            },
          }
        }
      }
    },
    _type == 'sectionOrderSummary' => {
      _key,
      _type,
      summaries[]{
        ${coalesceLocaleField('name', localeId)},
        details[]{
          ${coalesceLocaleField('text', localeId)}[]${PORTABLE_TEXT_PROJECTION}
        },
      },
    },
    _type == "sectionProductSizes" => {
      _key,
      _type,
      products[]{
        ${coalesceLocaleField('title', localeId)},
        link${LINK_PROJECTION},
        image${MEDIA_PROJECTION},
      },
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('lineLabelLeft', localeId)},
      ${coalesceLocaleField('lineLabelRight', localeId)},
      ${coalesceLocaleField('buttonLabel', localeId)},
      buttonLink${LINK_PROJECTION},
    },
    _type == "sectionForms" => {
      _key,
      _type,
      formType,
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField(
        'description',
        localeId,
      )}[]${PORTABLE_TEXT_PROJECTION},
    },
    _type == "sectionLeadershipMap" => {
      _key,
      _type,
      ${coalesceLocaleField('title', localeId)}[]${PORTABLE_TEXT_PROJECTION},
      ${coalesceLocaleField('text', localeId)}[]${PORTABLE_TEXT_PROJECTION},
      image${BASE_IMAGE_PROJECTION},
      ${coalesceLocaleField(
        'listTitle',
        localeId,
      )}[]${PORTABLE_TEXT_PROJECTION},
      regions[]{
        ${coalesceLocaleField('name', localeId)},
        "areas":coalesce(areas[].${localeId}, areas[].${DEFAULT_LOCALE_ID})
      }
    },
    _type == "sectionCustomerSupport" => {
      _key,
      _type,
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('description', localeId)},
      cards[]{
        _key,
        ${coalesceLocaleField('cardTitle', localeId)},
        ${coalesceLocaleField('contactData', localeId)},
        ${coalesceLocaleField('contactDetails', localeId)},
        ${coalesceLocaleField('ctaLabel', localeId)},
        ctaLink${LINK_PROJECTION},
        background${MEDIA_PROJECTION},
      },
    },
    _type == "sectionWithFaq" => {
        _key,
        _type,
        questions[]{
          _key,
           ${coalesceLocaleField('questioner', localeId)},
           ${coalesceLocaleField('location', localeId)},
           date,
           ${coalesceLocaleField('question', localeId)},
           ${coalesceLocaleField(
             'answer',
             localeId,
           )}[]${PORTABLE_TEXT_PROJECTION},
        }
    },
    _type == "sectionRecommendedProducts" => {
      _key,
      _type,
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('subtitle', localeId)},
      type,
    },
  }`
}
