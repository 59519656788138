import { createMediaProjection } from './projections/createMediaProjection'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { SiteConfiguration } from '@liftfoils/models'
import { coalesceLocaleField } from './helpers/coalesceLocaleField'
import { createPortableTextProjection } from './projections/createPortableTextProjection'
import { createLinkProjection } from './projections/createLinkProjection'
import { selectRegionalizedContent } from './helpers/selectRegionalizedContent'
import { createBaseImageProjection } from './projections/createBaseImageProjection'
import { DEFAULT_SHOPIFY_INSTANCE_ID } from '@liftfoils/configs'

export const getSiteConfiguration = async (
  localeRegionString: string,
  preview = false,
): Promise<SiteConfiguration> => {
  const [, localeId] = getLocaleRegionIdFromPath(localeRegionString)

  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)
  const BASE_IMAGE_PROJECTION = createBaseImageProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  // TODO cleanup instanceId
  const instanceId = DEFAULT_SHOPIFY_INSTANCE_ID
  const headerQuery = groq`
    *[_type == "header"] {
      "navigation": ${selectRegionalizedContent(instanceId)} {
        "data":
        *[_type=="navigation" && _id == ${
          preview ? '"drafts."' : '""'
        }+^._ref] {
        promoBar {
          ${coalesceLocaleField(
            'description',
            localeId,
          )}[]${PORTABLE_TEXT_PROJECTION},
           ${coalesceLocaleField(
             'b2bDescription',
             localeId,
           )}[]${PORTABLE_TEXT_PROJECTION},
          isVisible,
        },
        tabs[] {
          "id": _key,
          type,
          ${coalesceLocaleField('title', localeId)},
          link${LINK_PROJECTION},
          image${BASE_IMAGE_PROJECTION},
          ${coalesceLocaleField(
            'imageCaption',
            localeId,
          )}[]${PORTABLE_TEXT_PROJECTION},
          imageLink${LINK_PROJECTION},
          b2bOnly,
          ${coalesceLocaleField(
            'description',
            localeId,
          )}[]${PORTABLE_TEXT_PROJECTION},
          ctas[] {
            link${LINK_PROJECTION},
            ${coalesceLocaleField('title', localeId)}
          },
          links[] {
            link${LINK_PROJECTION},
            ${coalesceLocaleField('title', localeId)},
            image${BASE_IMAGE_PROJECTION}
          },
          bottomLinks[] {
            link${LINK_PROJECTION},
            ${coalesceLocaleField('title', localeId)},
            image${BASE_IMAGE_PROJECTION},
          },
        },
      }
      }.data[0],
    }[0].navigation
  `

  const footerQuery = groq`
    *[_type == "footer"] {
      "linkGroups": ${selectRegionalizedContent(instanceId)} -> {
        linkGroups[] {
          ${coalesceLocaleField('title', localeId)},
          links[] {
            link${LINK_PROJECTION},
            ${coalesceLocaleField('title', localeId)}
          }
        }
      }.linkGroups,
      socialMedia[] {
        type,
        url
      }
    }[0]
  `
  const cartQuery = groq`
    *[_type == "cartRegionalized"] {
      "cart":${selectRegionalizedContent(instanceId)}->{
        ${coalesceLocaleField(
          'cartNotes',
          localeId,
        )}[]${PORTABLE_TEXT_PROJECTION},
        ${coalesceLocaleField('seoTitle', localeId)},
        ${coalesceLocaleField('seoDescription', localeId)}
      }
    }[0].cart
  `
  const newsletterQuery = groq`
    *[_type == "newsletter"] {
      ${coalesceLocaleField('heading', localeId)}[]${PORTABLE_TEXT_PROJECTION},
      ${coalesceLocaleField(
        'description',
        localeId,
      )}[]${PORTABLE_TEXT_PROJECTION},
      media${MEDIA_PROJECTION}
    }[0]
  `

  const assistanceQuery = groq`
    *[_type == "header"] {
      "navigation": ${selectRegionalizedContent(instanceId)} -> {
        contact{
          ${coalesceLocaleField('heading', localeId)},
          ${coalesceLocaleField(
            'description',
            localeId,
          )}[]${PORTABLE_TEXT_PROJECTION},
          phone,
          email,
        },
      },
    }[0].navigation.contact
  `
  const seoQuery = groq`
    *[_type == "seo"] {
      ${coalesceLocaleField('metaTitle', localeId)},
      ${coalesceLocaleField('metaDescription', localeId)},
      ${coalesceLocaleField('ogTitle', localeId)},
      ${coalesceLocaleField('ogDescription', localeId)},
      ogImage${BASE_IMAGE_PROJECTION},
    }[0]
  `
  const searchQuery = groq`
    *[_type == "search"] {
      ${coalesceLocaleField('title', localeId)},
      links[]{
        ${coalesceLocaleField('title', localeId)},
        link${LINK_PROJECTION},
      },
      ${coalesceLocaleField('seoTitle', localeId)},
      ${coalesceLocaleField('seoDescription', localeId)}
    }[0]
  `
  const siteConfigurationQuery = groq`{
  "header": ${headerQuery},
  "footer": ${footerQuery},
  "cart": ${cartQuery},
  "newsletter": ${newsletterQuery},
  "assistance": ${assistanceQuery},
  "seo": ${seoQuery},
  "newsletter": ${newsletterQuery},
  "search": ${searchQuery}
  }
  `

  return await getClient(preview).fetch(siteConfigurationQuery)
}
