import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { submitForm } from '@liftfoils/services/api-service'
import { validateRequired } from '../helpers/validateRequired'
import { validateEmail } from '../helpers/validateEmail'
import { useFormSubmitState } from '../helpers/useFormSubmitState'
import { FormTextField, SubmitButton, FormPhoneField } from '../inputs'
import { useRouter } from 'next/router'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'

interface ContactFormProps {
  className?: string
}

type FormValues = {
  firstName: string
  lastName: string
  desiredOperatingArea?: string
  businessName?: string
  businessDescription?: string
  phoneNumber: string
  email: string
  website?: string
  country: string
  programType: string
}

const defaultValues: FormValues = {
  firstName: '',
  lastName: '',
  desiredOperatingArea: '',
  businessName: '',
  businessDescription: '',
  phoneNumber: '',
  email: '',
  website: '',
  country: '',
  programType: 'Affiliate Or Retailer',
}

const Form = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$4',
})

export const RequestCallbackForm: FC<ContactFormProps> = ({ className }) => {
  const { t } = useTranslation('forms')
  const form = useForm<FormValues>({
    defaultValues,
  })

  const { locale } = useRouter()
  const [, regionId] = getLocaleRegionIdFromPath(locale)

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    clearErrors,
  } = form

  const { invalid, success, setSuccess, setError, submitMessage } =
    useFormSubmitState<FormValues>({ form, defaultValues })

  const onSubmit = async (data: FormValues) => {
    try {
      clearErrors()
      const response = await submitForm('requestCallbackForm', {
        ...data,
        region: regionId,
      })
      if (!response.ok) {
        return setError()
      }
      return setSuccess()
    } catch {
      return setError()
    }
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className={className}
      name="request-callback-form"
    >
      <FormPhoneField
        control={control}
        rules={validateRequired(t)}
        name="phoneNumber"
        label={t('requestCallbackForm.phoneFieldLabel')}
        form={form}
      />

      <FormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="name"
        name="name"
        label={t('requestCallbackForm.nameFieldLabel')}
      />

      <FormTextField
        control={control}
        rules={{
          ...validateEmail(t),
        }}
        autoComplete="email"
        type="email"
        name="email"
        label={t('requestCallbackForm.emailFieldLabel')}
      />

      <FormTextField
        control={control}
        rules={validateRequired(t)}
        name="message"
        label={t('requestCallbackForm.messageFieldLabel')}
        multiline
        rows={8}
      />

      <SubmitButton
        text={t('requestCallbackForm.submitButtonLabel')}
        helpText={submitMessage}
        invalid={invalid}
        success={success}
        loading={isSubmitting}
      />
    </Form>
  )
}
