import { Button } from './Button/Button'
import { FC } from 'react'
import { useAccount } from '@liftfoils/services/shopify-service'
import { ROUTE_SHOPIFY_ACCOUNT } from '@liftfoils/configs'
import { useTranslation } from 'react-i18next'
import { Link } from './Link/Link'

export const AccountButton: FC<{
  appearance: 'outlineWhite' | 'outlineGray300'
  size?: 'small' | 'medium'
}> = ({ appearance, size = 'small' }) => {
  const { authorize, account } = useAccount()
  const { t } = useTranslation('header')
  return (
    <>
      <div>
        {account ? (
          <Link
            href={ROUTE_SHOPIFY_ACCOUNT}
            appearance={appearance}
            size={size}
          >
            {t('myAccount')}
          </Link>
        ) : (
          <Button appearance={appearance} size={size} onClick={authorize}>
            <span>{t('signIn')}</span>
          </Button>
        )}
      </div>
    </>
  )
}
