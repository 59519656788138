import { ComponentPropsWithRef, forwardRef } from 'react'

import { styled } from '@liftfoils/styles'
import {
  ModalContentPosition,
  ModalContentSize,
  ModalContentVariant,
} from './Modal'

const ContentStyled = styled('div', {
  position: 'relative',
  border: 'none',
  maxWidth: '100%',
  variants: {
    position: {
      center: {
        margin: 'auto',
      },
      bottom: {
        margin: 'auto auto 0',
      },
      right: {
        margin: 'auto 0 auto auto',
      },
      left: {
        margin: 'auto auto auto 0',
      },
      top: {
        margin: '0 auto auto auto',
      },
      'top-right': {
        margin: '$headerHeight $8 auto auto',
      },
    },
    size: {
      intrinsic: {},
      stretch: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
      },
      'stretch-x': {
        width: '100%',
      },
      'stretch-y': {
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
      },
    },
    variant: {
      default: {
        background: 'white',
      },
      'white-rounded': {
        borderRadius: '$r1',
        background: 'white',
      },
      rounded: {
        borderRadius: '$r1',
      },
    },
  },
})

type ContentProps = ComponentPropsWithRef<'div'> & {
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void
  position?: ModalContentPosition
  variant?: ModalContentVariant
  size?: ModalContentSize
}

export const Content = forwardRef<HTMLDivElement, ContentProps>(
  (
    { children, variant, position, size, onRequestClose, ...restProps },
    ref,
  ) => {
    const stylingProps = { variant, position, size }
    return (
      <ContentStyled
        {...restProps}
        ref={ref}
        style={{}}
        css={{}}
        {...stylingProps}
      >
        {children}
      </ContentStyled>
    )
  },
)

Content.displayName = 'Content'
