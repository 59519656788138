import { DEFAULT_LOCALE_ID, LocaleId } from '@liftfoils/configs'

export const coalesceLocaleField = (
  fieldName: string,
  localeId: LocaleId,
  targetFieldName?: string,
  defaultLocaleId = DEFAULT_LOCALE_ID,
) =>
  `"${
    targetFieldName ?? fieldName
  }": coalesce(${fieldName}.${localeId}, ${fieldName}.${defaultLocaleId})`
