import { YoutubeModalAction } from '@liftfoils/models'
import { getYouTubeIdFromUrl } from '@liftfoils/utils'
import { FC, PropsWithChildren, useState } from 'react'
import { Modal } from '../../../Modal/Modal'
import { YouTubePlayer } from '../../../YouTubePlayer/YouTubePlayer'
import { VideoModalContentWrap } from '../../../VideoModalContentWrap/VideoModalContentWrap'

type ActionProps = {
  onClick?: () => void
}

export const YoutubeModal: FC<
  PropsWithChildren<{
    action?: YoutubeModalAction
    renderButton: (actionProps: ActionProps) => JSX.Element
  }>
> = ({ action, renderButton }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleModal = () => setIsOpen(!isOpen)

  const actionProps = {
    onClick: () => toggleModal(),
  }

  return (
    <>
      {renderButton(actionProps)}
      <Modal
        overlayVariant={'blur'}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        overlayCloseButton
        variant={'rounded'}
      >
        <VideoModalContentWrap>
          <YouTubePlayer
            videoId={getYouTubeIdFromUrl(action?.payload?.youtubeUrl)}
          />
        </VideoModalContentWrap>
      </Modal>
    </>
  )
}
