import {
  ShopifyGetProductExtendedQueryVariables,
  createShopifySdk,
  formatShopifyProductExtended,
  ShopifyCountryCode,
} from '@liftfoils/shopify-sdk'
import { createStorefrontApiClient } from './createStorefrontApiClient'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'

export const getShopifyProductExtended = async (
  variables: ShopifyGetProductExtendedQueryVariables,
  locale: string,
) => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
  const shopifyClient = createStorefrontApiClient(localeId)

  return await createShopifySdk(shopifyClient)
    .getProductExtended({
      ...variables,
      regionId: regionId as ShopifyCountryCode,
    })
    .then((res) => {
      const product = res.data.product
      return product && formatShopifyProductExtended(product)
    })
}
