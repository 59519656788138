import React from 'react'

export const ArrowDownSlim = () => {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.14645 6.26689L1.357 0.646484L0.646454 1.33628L7.14645 7.64648L13.6465 1.33628L12.9359 0.646484L7.14645 6.26689Z" />
    </svg>
  )
}
