import { FC } from 'react'
import {
  Button,
  Modal,
  Link,
  Media,
  ProductVariantPrice,
  MediaPlaceholder,
} from '@liftfoils/components'
import { breakpoints, styled, useMediaQuery } from '@liftfoils/styles'
import { useCart } from '../useCart'
import { Close } from '@liftfoils/icons'
import {
  getVariantsByBundleId,
  getVariantsSumPrice,
} from '@liftfoils/services/shopify-service'
import { useTranslation } from 'react-i18next'
import { BUNDLE_ID_BASE_PREFIX, BUNDLE_TITLE_KEY } from '@liftfoils/configs'
import { ShopifyResolvedCartLine } from '@liftfoils/shopify-sdk'

const Content = styled('div', {
  m: '$9',
  display: 'grid',
  gridGap: '$8',
  '@md': {
    minWidth: '420px',
  },
})
const Border = styled('div', {
  height: '1px',
  content: ' ',
  backgroundColor: '$gray200',
})
const ModalHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  lift_font: 'heading04',
})

const Product = styled('div', {
  display: 'grid',
  gridTemplateColumns: '100px 1fr',
  alignItems: 'start',
  gridGap: '$8',
  lift_font: 'heading04',
})
const Attribute = styled('div', {
  lift_font: 'body03',
  color: '$gray400',
})
const ProductPrice = styled('p', {
  color: '$gray500',
})
const ProductImage = styled('div', {
  border: '1px solid',
  borderColor: '$gray200',
  borderRadius: '$r2',
  overflow: 'hidden',
  height: '100%',
})

const ButtonsWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '$6',
})

type CartModalProps = {
  isOpen: boolean
  message: string
  handleClose: () => void
  newCartLines?: ShopifyResolvedCartLine[]
  checkoutUrl?: string
  bundleId?: string
}

export const CartNotificationModal: FC<CartModalProps> = ({
  isOpen,
  handleClose,
  newCartLines,
  checkoutUrl,
  bundleId,
  message,
}) => {
  const { quantity } = useCart()
  const matchesMd = useMediaQuery(breakpoints['md'])
  const { t } = useTranslation('cart')

  if (!newCartLines) return null

  const bundleBase = newCartLines?.find((line) =>
    line?.attributes?.find((a) => a.value?.startsWith(BUNDLE_ID_BASE_PREFIX)),
  )

  const cartLine = bundleBase ?? newCartLines[0] ?? undefined
  const price = bundleId
    ? getVariantsSumPrice(getVariantsByBundleId(newCartLines, bundleId))
    : newCartLines[0].merchandise.price

  if (!cartLine) return null

  const title =
    cartLine.attributes?.find((a) => a.key === BUNDLE_TITLE_KEY)?.value ??
    cartLine.merchandise.product.title

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      position={matchesMd ? 'top-right' : 'top'}
      size={matchesMd ? 'intrinsic' : 'stretch-x'}
      variant={matchesMd ? 'white-rounded' : 'default'}
    >
      <Content>
        <ModalHeader>
          {message}
          <Button onClick={handleClose} appearance={'flex'}>
            <Close />
          </Button>
        </ModalHeader>
        <Border />
        <Product>
          <MediaPlaceholder ratio={'portrait2'}>
            <ProductImage>
              {cartLine.merchandise.image && (
                <Media
                  mediaPayload={cartLine.merchandise.image}
                  sizes={'220px'}
                  layout={'fill'}
                  objectFit={'contain'}
                  alt=""
                />
              )}
            </ProductImage>
          </MediaPlaceholder>
          <div>
            <h1>{title}</h1>
            {price && (
              <ProductPrice>
                <ProductVariantPrice price={price} />
              </ProductPrice>
            )}
            {cartLine.attributes
              ?.filter((a) => a.key[0] !== '_')
              .map((a) => (
                <Attribute key={a.key}>
                  {a.key}: {a.value}
                </Attribute>
              ))}
          </div>
        </Product>

        <Border />
        <ButtonsWrap>
          <Link appearance="solidGray500" size="large" href="/cart">
            {t('viewCart')} ({quantity})
          </Link>
          {checkoutUrl && (
            <Link appearance="solidTeal500" size="large" href={checkoutUrl}>
              {t('checkout')}
            </Link>
          )}
        </ButtonsWrap>
      </Content>
    </Modal>
  )
}
