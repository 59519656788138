import { FC, ReactElement } from 'react'
import { MuxVideoMediaPayload, MuxVideoType } from '@liftfoils/models'
import { MediaProps } from '../models/MediaProps'
import { Video } from './Video'
import { useVideoOrchestrator } from '../helpers/useVideoOrchestrator'
import { VideoControlButtons } from './VideoControlButtons'

type MuxVideoProps = Omit<MediaProps, 'mediaPayload'> & {
  mediaPayload: MuxVideoMediaPayload
  videoOverride?: (video: MuxVideoType) => ReactElement
}

type VideoAdapterProps = Omit<MediaProps, 'mediaPayload'> & {
  video: MuxVideoType
}

const VideoAdapter: FC<VideoAdapterProps> = ({
  layout,
  priority,
  video,
  alt,
  loop,
  muted = true,
  isVideoPaused,
  controlsPosition = 'none',
  onPlaybackFinish,
  autoplay = true,
  sizes,
  hardcropRatio,
  withSoundControl = false,
}) => {
  const { firstFrame, mp4, width, height, hasAudioTrack } = video

  const { containerRef, videoControlProps, controlsProps } =
    useVideoOrchestrator({
      autoplay,
      isMuted: muted,
      isVideoPaused: isVideoPaused,
      onPlaybackFinish,
    })

  return (
    <div ref={containerRef}>
      <Video
        src={mp4}
        width={width}
        height={height}
        layout={layout}
        sizes={sizes}
        firstFrame={firstFrame}
        alt={alt}
        loop={loop}
        priority={priority}
        hardcropRatio={hardcropRatio}
        {...videoControlProps}
      />
      <VideoControlButtons
        {...controlsProps}
        controlsPosition={controlsPosition}
        withSoundControl={(withSoundControl && hasAudioTrack) ?? false}
      />
    </div>
  )
}

export const MuxVideo = ({
  mediaPayload,
  videoOverride,
  ...restOfProps
}: MuxVideoProps) => {
  const { video } = mediaPayload

  if (!video) return null

  if (videoOverride) {
    return videoOverride(video)
  }

  return <VideoAdapter video={video} {...restOfProps} />
}
