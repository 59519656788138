import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { ShopifyMoneyFieldsFragment } from '../generated/schemaSdk'

export const getI18nPrice = (
  price: ShopifyMoneyFieldsFragment,
  locale?: string,
): string => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)

  return new Intl.NumberFormat(`${localeId}-${regionId}`, {
    style: 'currency',
    currency: price.currencyCode,
  }).format(Number(price.amount))
}
