import {
  ShopifyCartLineFieldsFragment,
  ShopifyComponentizableCartLineFieldsFragment,
} from '../generated/schemaSdk'
import { formatShopifyVariantBasic } from './formatShopifyVariantBasic'

export const formatShopifyCartLine = (
  cartLine:
    | ShopifyCartLineFieldsFragment
    | ShopifyComponentizableCartLineFieldsFragment,
) => ({
  ...cartLine,
  merchandise: formatShopifyVariantBasic(cartLine.merchandise),
})

export type ShopifyResolvedCartLine = ReturnType<typeof formatShopifyCartLine>
