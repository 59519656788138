export const ARIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M26.28 16.592v9.69h-9.777v1.086h10.864V16.592h-1.086ZM16.504 0v1.087h9.778v9.645h1.086V0H16.504ZM0 0v10.865h1.087V1.087h9.777V0H0ZM0 16.503v10.864h10.864v-1.086H1.087v-9.778H0ZM20.235 10.247v7.242l-6.048 3.518v-7.242l6.048-3.518Zm-.433-.815-6.118 3.56-6.12-3.56 6.093-3.372 6.145 3.372ZM7.134 17.49v-7.242l6.048 3.518v7.242l-6.048-3.518Zm14.106.24V9.422l-.01-.074a.362.362 0 0 0-.04-.111l-.03-.053a.478.478 0 0 0-.158-.14L13.92 5.154a.562.562 0 0 0-.53.002L6.363 9.043a.464.464 0 0 0-.154.138l-.026.044a.407.407 0 0 0-.045.125l-.011 8.38c0 .15.085.29.227.373l7.035 4.092.082.043a.563.563 0 0 0 .426-.001l7.115-4.134a.434.434 0 0 0 .227-.373Z" />
  </svg>
)
