import { styled } from '@liftfoils/styles'
import { FC, Ref, useEffect, useRef } from 'react'
import { MediaLayoutType } from '../models/MediaProps'
import { MuxImage } from './MuxImage'
import { MuxImageMediaPayload } from '@liftfoils/models'
import { MediaRatiosTypeExtended, ratios } from '../ratios'
import { captureException } from '@sentry/nextjs'
import { MediaSizesProps } from '../helpers/buildSizes'

const VideoWrap = styled('div', {
  position: 'relative',
  variants: {
    layout: {
      fill: {
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        position: 'absolute',
      },
      responsive: {},
      intrinsic: {},
    },
  },
})

const VideoElement = styled('video', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  zIndex: '$headerMobile',
  position: 'absolute',
  top: 0,
  left: 0,
})

type Props = {
  layout?: MediaLayoutType
  width: number
  height: number
  firstFrame: string
  priority?: boolean
  alt?: string
  preload?: string
  autoplay?: boolean
  muted?: boolean
  loop?: boolean
  src?: string
  play?: boolean
  load?: boolean
  sizes?: MediaSizesProps
  onEnded?: () => void
  onPlaying?: () => void
  onPause?: () => void
  onCanPlay?: () => void
  onLoadedData?: () => void
  onWaiting?: () => void
  onError?: () => void
  onLoadStart?: () => void
  onNotAllowed?: () => void
  hardcropRatio?: MediaRatiosTypeExtended
}

/**
 *  This component is state-less.
 *  It has to be controlled externally - in most cases by useVideoOrchestrator() hook
 **/
export const Video: FC<Props & { ref?: Ref<HTMLDivElement> }> = ({
  layout,
  width,
  height,
  sizes,
  firstFrame,
  priority,
  alt,
  loop = true,
  muted = true,
  preload = 'none',
  autoplay = false,
  src,
  play,
  load,
  onPlaying,
  onPause,
  onCanPlay,
  onLoadedData,
  onLoadStart,
  onWaiting,
  onEnded,
  onNotAllowed,
  onError,
  hardcropRatio,
}) => {
  const containerStyles = (() => {
    if (layout !== 'responsive') return undefined

    if (hardcropRatio && typeof hardcropRatio === 'string') {
      const ratioValue = ratios[hardcropRatio]
      const paddingBottom = `${ratioValue * 100}%`
      return { paddingBottom }
    }

    const originalRatio = `${(height / width) * 100}%`
    return { paddingBottom: originalRatio }
  })()

  const playRequestInProgress = useRef(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  const firstFramePayload: MuxImageMediaPayload = {
    type: 'mux-image',
    image: {
      src: firstFrame,
      alt,
    },
  }

  /**
   *  when state of -play- changes trigger play() or pause() method to <video>
   **/
  useEffect(() => {
    if (play === undefined) return

    const videoEl = videoRef.current
    const handler = async () => {
      if (videoEl && play) {
        playRequestInProgress.current = true
        await videoEl.play().catch((err: Error) => {
          if (err.name === 'NotAllowedError') {
            /**
             *   Not allowed error occurs when browser is blocking play() - Low Power Mode case
             *   on not allowed is function that should reset state of video to initial
             **/
            onNotAllowed && onNotAllowed()
          } else {
            captureException(err)
            onError && onError()
          }
        })
        playRequestInProgress.current = false
        return
      }
      /**
       *   Pause only when play() method is done
       **/
      if (videoEl && !play && !playRequestInProgress.current) {
        videoEl?.pause()
      }
    }
    handler()
  }, [play, src])

  /**
   *  when state of load=true changes trigger load()
   **/
  useEffect(() => {
    if (load === undefined) return

    const videoEl = videoRef.current
    if (videoEl && load) {
      videoEl?.load()

      return
    }
  }, [load])

  return (
    <VideoWrap style={containerStyles} layout={layout}>
      <MuxImage
        mediaPayload={firstFramePayload}
        priority={priority}
        layout={'fill'}
        objectFit={'cover'}
        sizes={sizes}
        originalWidth={width}
      />
      <VideoElement
        ref={videoRef}
        preload={preload}
        autoPlay={autoplay}
        muted={muted}
        loop={loop}
        playsInline
        title={alt}
        src={src}
        onEnded={onEnded}
        onPlaying={onPlaying}
        onPause={onPause}
        onCanPlayThrough={onCanPlay}
        onWaiting={onWaiting}
        onLoadedData={onLoadedData}
        onLoadStart={onLoadStart}
      />
    </VideoWrap>
  )
}
