import { FC } from 'react'

import { styled } from '@liftfoils/styles'
import { MediaFieldType, PortableTextBlocks } from '@liftfoils/models'
import { ImageWithCaption } from '../ImageWithCaption/ImageWithCaption'

const Wrap = styled('div', {
  position: 'relative',
  $hasContainerMX: true,
  display: 'grid',
  gridGap: '$9',
  $mb: 'M',
  '@md': {
    gridTemplateColumns: '1fr 1fr',
    $mb: 'L',
  },
  variants: {
    smallMargin: {
      true: {
        $mb: 'M',
      },
    },
  },
})

export type TwoImagesProps = {
  _type: string
  _key: string
  firstMedia: {
    media: MediaFieldType
    caption: PortableTextBlocks
  }
  secondMedia: {
    media: MediaFieldType
    caption: PortableTextBlocks
  }
}

export const TwoImages: FC<TwoImagesProps> = ({ firstMedia, secondMedia }) => {
  return (
    <Wrap smallMargin={!!firstMedia.caption || !!secondMedia.caption}>
      <ImageWithCaption
        media={firstMedia.media}
        caption={secondMedia.caption}
        sizes="(min-width: 740px) 50vw, 100vw"
      />
      <ImageWithCaption
        media={secondMedia.media}
        caption={secondMedia.caption}
        sizes="(min-width: 740px) 50vw, 100vw"
      />
    </Wrap>
  )
}
