import { FC } from 'react'
import { useRouter } from 'next/router'

import { styled } from '@liftfoils/styles'
import { Facebook, Twitter, LinkIcon } from '@liftfoils/icons'
import { Button } from '../Button/Button'
import { Link } from '../Link/Link'

const Wrap = styled('div', {
  width: 'auto',
  color: 'white',
  display: 'inline-flex',
  alignItems: 'center',
  gap: '$5',
})

const Label = styled('span', {
  lift_font: 'body03',
  mr: '$6',
})

export type ShareProps = {
  description: string
}

export const Share: FC<ShareProps> = ({ description }) => {
  const { asPath } = useRouter()
  const fullUrl = `${process.env.BASE_URL}${asPath}`

  const SOCIALMEDIAITEMS = [
    {
      type: 'facebook',
      url: 'https://www.facebook.com/sharer/sharer.php?u=' + fullUrl,
    },
    {
      type: 'twitter',
      url: 'https://twitter.com/intent/tweet?url=' + fullUrl,
    },
  ]

  const copyURL = () => {
    navigator.clipboard.writeText(fullUrl)
  }

  return (
    <Wrap>
      <Label>{description}</Label>
      <Button appearance={'shareIcon'} onClick={copyURL}>
        <LinkIcon />
      </Button>
      {SOCIALMEDIAITEMS.map((item) => (
        <Link
          href={item.url}
          target="_blank"
          appearance={'shareIcon'}
          key={item.type}
        >
          {(() => {
            switch (item.type) {
              case 'facebook':
                return <Facebook />
              case 'twitter':
                return <Twitter />
              default:
                return null
            }
          })()}
        </Link>
      ))}
    </Wrap>
  )
}
