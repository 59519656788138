import { FC } from 'react'
import {
  ShopifyMoneyFieldsFragment,
  ShopifyMutationCartLinesAddArgs,
} from '@liftfoils/shopify-sdk'
import { Dot } from '@liftfoils/icons'
import { styled } from '@stitches/react'
import { Loader, Button, ProductVariantPrice } from '@liftfoils/components'
import { useCart } from '../useCart'
import { useTranslation } from 'react-i18next'
import {
  BUNDLE_GA_ITEM_NAME_KEY,
  BUNDLE_ID_ADDITIONAL_PREFIX,
  BUNDLE_ID_BASE_PREFIX,
  BUNDLE_ID_KEY,
  BUNDLE_HANDLE_KEY,
} from '@liftfoils/configs'
import { v4 as uuidv4 } from 'uuid'

type BuyButtonWithTotalProps = {
  totalPrice: ShopifyMoneyFieldsFragment | null
  selectedVariants: string[]
  bundle?: boolean
  attributes?: { key: string; value: string }[]
  mainProductData?: {
    path?: string
    title?: string
    kitsLength?: number
  }
  availability?: boolean
}

const BuyNowContent = styled('div', {
  display: 'grid',
  placeItems: 'center',
  gridTemplateColumns: '1fr 45px auto',
})

const AddToCartText = styled('div', {
  display: 'inline',
  alignSelf: 'start',
  textAlign: 'left',
})

const LoaderWrap = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
})

const BuyButton = styled(Button, {
  paddingInline: '$8',
  paddingBlock: '$7',
  width: '100%',
  height: 55,
})

const ButtonText = styled('div', {
  display: 'inline',
  marginRight: '$4',
})

export const BuyButtonWithTotal: FC<BuyButtonWithTotalProps> = ({
  totalPrice,
  selectedVariants,
  bundle = false,
  attributes = [],
  mainProductData,
  availability,
}) => {
  const { t } = useTranslation('cart')
  const { addCartLines, processing } = useCart()
  const getAnalyticsAttributes = () => {
    if (mainProductData?.path && mainProductData?.title && selectedVariants) {
      return [
        {
          key: BUNDLE_HANDLE_KEY,
          value: mainProductData.path,
        },
        {
          key: BUNDLE_GA_ITEM_NAME_KEY,
          value: mainProductData.title,
        },
      ]
    }

    return []
  }

  const handleAddToCart = async () => {
    const uuid = uuidv4()
    let lines: ShopifyMutationCartLinesAddArgs['lines'] = []

    if (bundle) {
      lines = [
        {
          merchandiseId: selectedVariants[0],
          quantity: 1,
          attributes: [
            {
              key: BUNDLE_ID_KEY,
              value: `${BUNDLE_ID_BASE_PREFIX}${uuid}`,
            },
            ...getAnalyticsAttributes(),
            ...attributes,
          ],
        },
        ...selectedVariants.slice(1).map((merchandiseId) => ({
          merchandiseId,
          quantity: 1,
          attributes: [
            {
              key: BUNDLE_ID_KEY,
              value: `${BUNDLE_ID_ADDITIONAL_PREFIX}${uuid}`,
            },
          ],
        })),
      ]
    } else {
      lines = selectedVariants.map((variant) => ({
        merchandiseId: variant,
        quantity: 1,
      }))
    }

    await addCartLines(lines)
  }

  const isBundleReadyToAdd = (() => {
    if (bundle && mainProductData?.kitsLength) {
      return selectedVariants.length === mainProductData.kitsLength
    }
    return selectedVariants.length > 0
  })()

  const getAddToCartText = (
    availability?: boolean,
    isBundleReadyToAdd?: boolean,
  ) => {
    if (!availability) {
      return t('outOfStock')
    }
    return isBundleReadyToAdd ? t('addToCart') : 'See configuration'
  }

  return (
    <BuyButton
      appearance="solidTeal500"
      onClick={handleAddToCart}
      disabled={!isBundleReadyToAdd || !availability}
    >
      {processing ? (
        <LoaderWrap>
          <Loader />
        </LoaderWrap>
      ) : (
        <BuyNowContent>
          <div>
            <ButtonText>{t('total')}</ButtonText>
            <ProductVariantPrice
              price={
                (isNaN(totalPrice?.amount)
                  ? { amount: 0, currencyCode: 'USD' }
                  : totalPrice) as ShopifyMoneyFieldsFragment
              }
            />
          </div>
          <Dot style={{ fill: 'white' }} />
          <AddToCartText>
            {getAddToCartText(availability, isBundleReadyToAdd)}
          </AddToCartText>
        </BuyNowContent>
      )}
    </BuyButton>
  )
}
