import { createBaseImageProjection } from './projections/createBaseImageProjection'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { PageBuilderSection, PageResponse } from '@liftfoils/models'
import { DEFAULT_LOCALE_ID } from '@liftfoils/configs'
import { coalesceLocaleField } from '..'
import { createPortableTextProjection } from './projections/createPortableTextProjection'
import { createPageBuilderProjection } from './projections/createPageBuilderProjection'
import { createProductPageConfigProjection } from './projections/createProductPageConfigProjection'

export const getEfoilPageByHandle = async (
  handle: string,
  localeRegionString: string,
  preview = false,
): Promise<PageResponse<PageBuilderSection[]>> => {
  const [, localeId] = getLocaleRegionIdFromPath(localeRegionString)

  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(localeId)
  const BASE_IMAGE_PROJECTION = createBaseImageProjection(localeId)
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const PRODUCT_PAGE_CONFIG_PROJECTION =
    createProductPageConfigProjection(localeId)

  const draftFilter = preview ? '' : `!(_id in path('drafts.**')) && `
  const query = groq`*[${draftFilter} _type == "efoil" && mainProduct->store.slug.current == $handle][0]{
    "initialHeaderStyle": 'transparent-black',
    "showFooter": true,
    "title":  mainProduct->store.title,
    "pageBuilder": [
      {
        "_type": "sectionProductConversionArea",
        "priority": true,
          "_key": "sectionProductConversionArea-" + mainProduct -> store.gid,
          kits{
            _key,
            "productId": product -> store.gid,
            isMain
          }[],
          "shopifyIds": {
            "productBasic": [
              ...kits[].product -> store.gid,
              ...mainProduct.store.gid
            ],
            "variantBasic": [
              ...{"ids": overwrittenProductOptions[].values[].additionalProductVariant -> store.gid}.ids[]
            ]
          },
        importantFeatures{
        ${coalesceLocaleField('key', localeId)},
        ${coalesceLocaleField('value', localeId)},
        }[],
        overwrittenProductOptions{
          _key,
          name,
          ${coalesceLocaleField('overwrittenName', localeId)},
          ${coalesceLocaleField('guidanceModalLabel', localeId)},
          showGuidanceModal,
          wideElements,
          showPrice,
          expandAllValues,
          values{
            _key,
            title,
            ${coalesceLocaleField('overwrittenTitle', localeId)},
            image${BASE_IMAGE_PROJECTION},
            ${coalesceLocaleField(
              'additionalDescription',
              localeId,
            )}[]${PORTABLE_TEXT_PROJECTION},
            "additionalProductVariantId": additionalProductVariant -> store.gid,
            showNotifyMe,
            "features": features{
              "val": coalesce(${localeId}, ${DEFAULT_LOCALE_ID})
            }[].val
          }[]
        }[],
        ${coalesceLocaleField(
          'additionalDescription',
          localeId,
        )}[]${PORTABLE_TEXT_PROJECTION},
        video{
          ${coalesceLocaleField('title', localeId)},
          thumbnail${BASE_IMAGE_PROJECTION},
          youtubeVideoUrl
        },
        "extraMedia": selectedOptionsBasedGallery[]{
          boardSize,
          boardFinish,
          model3dUUID,
          "items": medias[]${BASE_IMAGE_PROJECTION}
        },
        "productPageConfig":${PRODUCT_PAGE_CONFIG_PROJECTION},
        anatomy {
          ${coalesceLocaleField('title', localeId)},
          showAnatomy,
          ${coalesceLocaleField('description', localeId)},
          anatomyImage${BASE_IMAGE_PROJECTION},
          hotspots[] {
            x,
            y,
            ${coalesceLocaleField('title', localeId)},
            ${coalesceLocaleField('description', localeId)},
          },
        },
      },
      ...pageBuilder[]${PAGE_BUILDER_PROJECTION}
    ],



  }`

  return await getClient(preview).fetch(query, { handle })
}
