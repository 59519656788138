import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Loader } from '@liftfoils/components'
import { styled } from '@liftfoils/styles'

type SubmitButtonProps = {
  text?: string
  helpText?: string
  invalid?: boolean
  success?: boolean
  loading?: boolean
  disabled?: boolean
}

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  mt: '$6',
})

const HelpText = styled('span', {
  position: 'relative',
  lift_font: 'body06',
  mt: '$6',
  variants: {
    invalid: {
      true: {
        color: '$red500',
      },
    },
    success: {
      true: {
        color: '$teal500',
      },
    },
  },
})

export const SubmitButton: FC<SubmitButtonProps> = ({
  text,
  helpText,
  success,
  invalid,
  loading,
  disabled,
}) => {
  const { t } = useTranslation('forms')

  const buttonText = text || t('defaultSubmitButtonLabel')
  return (
    <Container>
      <Button
        type="submit"
        appearance={'solidGray500'}
        size={'large'}
        disabled={disabled}
      >
        {loading ? <Loader /> : buttonText}
      </Button>
      {helpText && (
        <HelpText invalid={invalid} success={success}>
          {helpText}
        </HelpText>
      )}
    </Container>
  )
}
