import { FC } from 'react'
import { Modal } from '@liftfoils/components'

import { SearchModalContent } from './SearchModalContent'
import { SiteConfiguration } from '@liftfoils/models'
import { breakpoints, useMediaQuery } from '@liftfoils/styles'

export const SearchModal: FC<{
  isOpen: boolean
  closeSearch: () => void
  searchConfig: SiteConfiguration['search']
}> = ({ isOpen = false, closeSearch, searchConfig }) => {
  const isMd = useMediaQuery(breakpoints['md'])

  return (
    <Modal
      preventScroll={true}
      isOpen={isOpen}
      onRequestClose={closeSearch}
      size={isMd ? 'stretch-x' : 'stretch'}
      position={'top'}
    >
      <SearchModalContent
        closeSearch={closeSearch}
        searchConfig={searchConfig}
      />
    </Modal>
  )
}
