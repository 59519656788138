import { FC } from 'react'

import { styled } from '@liftfoils/styles'
import { MediaFieldType, PortableTextBlocks } from '@liftfoils/models'
import { Container } from '../Container/Container'
import { ImageWithCaption } from '../ImageWithCaption/ImageWithCaption'

const Wrap = styled('div', {
  position: 'relative',
  $pb: 'M',
  '@md': {
    gridTemplateColumns: '1fr 1fr',
    $pb: 'L',
  },
  variants: {
    smallMargin: {
      true: {
        $pb: 'M',
      },
    },
    alignedToContent: {
      true: {
        maxWidth: '980px',
        mx: 'auto',
        $py: 'M',
      },
    },
  },
})

export type StandardImageProps = {
  _type: 'standardImage' | 'wideImage'
  _key: string
  media: MediaFieldType
  caption: PortableTextBlocks
}

export const StandardImage: FC<StandardImageProps> = ({
  _type,
  media,
  caption,
}) => {
  const alignedToContent = _type === 'standardImage'
  return (
    <Container>
      <Wrap
        alignedToContent={alignedToContent}
        smallMargin={!alignedToContent && !!caption}
      >
        {media && (
          <ImageWithCaption media={media} caption={caption} sizes="100vw" />
        )}
      </Wrap>
    </Container>
  )
}
