import { Button } from '../Button/Button'
import React from 'react'
import { useAccount } from '@liftfoils/services/shopify-service'

export const B2BToggle = () => {
  const { overrideB2B, isB2B } = useAccount()
  if (process.env.NODE_ENV !== 'development') return null
  return (
    <Button
      onClick={() => {
        overrideB2B(!isB2B)
      }}
      appearance={isB2B ? 'solidTeal500' : 'solidGray500'}
      size="small"
    >
      B2B is {isB2B ? 'On' : 'Off'}
    </Button>
  )
}
