import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

type GearSelectorContextType = {
  openModal: () => void
  closeModal: () => void
  isOpen: boolean
}

export const GearSelectorContext = createContext<GearSelectorContextType>({
  openModal: () => undefined,
  closeModal: () => undefined,
  isOpen: false,
})

export const GearSelectorContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  return (
    <GearSelectorContext.Provider value={{ closeModal, isOpen, openModal }}>
      {children}
    </GearSelectorContext.Provider>
  )
}

export const useGearSelectorContext = () => useContext(GearSelectorContext)
