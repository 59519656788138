import { FC, PropsWithChildren } from 'react'
import { Link } from '../Link/Link'
import {
  PortableText as SanityPortableText,
  PortableTextComponents,
  PortableTextMarkComponentProps,
} from '@portabletext/react'
import { TypedObject } from '@portabletext/types'
import { PortableTextBlocks } from '@liftfoils/models'
type MarkComponent<T extends TypedObject = any> = (
  props: PropsWithChildren<PortableTextMarkComponentProps<T>>,
) => JSX.Element

export type PortableTextProps = {
  value?: PortableTextBlocks
  additionalComponents?: PortableTextComponents
  className?: string
}

const highlighted: MarkComponent = ({ children }) => {
  return <span className="highlighted">{children}</span>
}
const superscript: MarkComponent = ({ children }) => {
  return <sup className="superscript">{children}</sup>
}
const link: MarkComponent<{
  _type: string
  href: string
  linkType?: string
}> = ({ value, children }) => {
  return <Link {...value}>{children}</Link>
}

export const PortableText: FC<PortableTextProps> = ({
  value,
  additionalComponents,
}) => {
  const components: PortableTextComponents = {
    ...additionalComponents,
    marks: {
      link,
      superscript,
      highlighted,
      ...(additionalComponents?.marks ? additionalComponents.marks : {}),
    },
  }
  if (!value) return null
  return <SanityPortableText value={value} components={components} />
}
