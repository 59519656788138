import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import {
  CUSTOMER_ACCOUNT_DEVELOPMENT_MODE,
  SHOPIFY_SESSION_COOKIE_KEY_NAME,
} from '../shopify.config'
import {
  exchangeToken,
  authorize,
  obtainToken,
  getUserData,
  refreshToken,
} from './authActions/client'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'

type Account = {
  email: string
  id: string
  firstName: string
  customerAccessToken?: string
  companyLocationId?: string
}

const CustomerAccountContext = createContext<{
  account: Account | undefined
  authorize: () => void
  isB2B: boolean
  overrideB2B: (b2b: boolean) => void
  processing: boolean
  refreshUserSession: () => void
}>({
  authorize: () => undefined,
  account: undefined,
  isB2B: false,
  overrideB2B: () => undefined,
  processing: true,
  refreshUserSession: () => undefined,
})

export const CustomerAccountProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [account, setAccount] = useState<Account | undefined>(undefined)
  const [processing, setProcessing] = useState(true)
  const [overridenB2B, setOverrideB2B] = useState(
    process.env.NODE_ENV === 'development' ? false : undefined,
  )
  const { query, replace, pathname, locale } = useRouter()

  const [localeId] = getLocaleRegionIdFromPath(locale)

  const logOut = () => {
    Cookies.remove(SHOPIFY_SESSION_COOKIE_KEY_NAME)
    setAccount(undefined)
    setProcessing(false)
  }

  const updateAccountData = async (access_token: string) => {
    const userData = await getUserData(localeId, access_token)
    setAccount(userData)
    setProcessing(false)
  }
  const overrideB2B = useCallback((newB2B: boolean) => {
    if (process.env.NODE_ENV === 'development') {
      setOverrideB2B(newB2B)
    }
  }, [])
  const refreshUserSession = async () => {
    setProcessing(true)

    // obtain session from Cookies
    const sessionCookie = Cookies.get(SHOPIFY_SESSION_COOKIE_KEY_NAME)
    const sessionAccessToken = sessionCookie
      ? JSON.parse(sessionCookie)['access_token']
      : undefined

    // no session, log out
    if (!sessionAccessToken) return logOut()

    // refresh access_token
    const refreshResponse = await refreshToken()

    // session expired, log out
    if (refreshResponse.error) return logOut()

    // exchange access_token
    const exchangedToken = await exchangeToken()

    // session expired, log out
    if (!exchangedToken) return logOut()

    // exchanged token is valid, update account
    await updateAccountData(exchangedToken)
  }

  const codeFromQuery = query['code']

  useEffect(() => {
    if (!codeFromQuery) return
    const initUserSession = async () => {
      console.log('CustomerAccountProvider -- initUserSession')
      if (!CUSTOMER_ACCOUNT_DEVELOPMENT_MODE) {
        replace(pathname, undefined, { shallow: true }) // clear "code" from query
      }
      await obtainToken(codeFromQuery as string)
      const exchangedToken = await exchangeToken()

      updateAccountData(exchangedToken)
    }
    initUserSession()
  }, [codeFromQuery])

  return (
    <CustomerAccountContext.Provider
      value={{
        account,
        authorize,
        refreshUserSession,
        isB2B:
          overridenB2B ||
          !!(account?.companyLocationId && account?.customerAccessToken),
        overrideB2B,
        processing,
      }}
    >
      {children}
    </CustomerAccountContext.Provider>
  )
}
export const useAccount = () => useContext(CustomerAccountContext)
