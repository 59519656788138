import { useEffect, useState } from 'react'

// TODO: add type from breakpoints.values -> 'min-width:430 | min-width:740 ...'
export function useMediaQuery(mediaQuery: string): boolean {
  const [matches, setMatches] = useState<boolean>(false)

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery)
    setMatches(mediaQueryList.matches)

    const handler = (e: MediaQueryListEvent) => {
      setMatches(e.matches)
    }

    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', handler)

      return () => {
        mediaQueryList.removeEventListener('change', handler)
      }
    } else {
      mediaQueryList.addListener(handler)

      return () => {
        mediaQueryList.removeListener(handler)
      }
    }
  }, [matches])

  return matches
}
