import React from 'react'

export const Pin = () => {
  return (
    <svg
      width="11"
      height="20"
      viewBox="0 0 11 20"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.18712 10.6289L5.93865 19.6252C5.87117 20.1249 5.16258 20.1249 5.09509 19.6252L3.84663 10.6289C1.61963 9.9292 0 7.86339 0 5.43107C0 2.43232 2.46319 0 5.5 0C8.53681 0 11 2.43232 11 5.43107C11 7.86339 9.41411 9.9292 7.18712 10.6289Z" />
    </svg>
  )
}
