import { FC } from 'react'
import { SanityImage } from './components/SanityImage'
import { MuxVideo } from './components/MuxVideo'
import { styled } from '@liftfoils/styles'
import { MediaProps } from './models/MediaProps'
import { ShopifyImage } from './components/ShopifyImage'

const MediaFillWrap = styled('div', {
  position: 'relative',
  variants: {
    fill: {
      true: {
        height: '100%',
        width: '100%',
      },
    },
  },
})

export const Media: FC<MediaProps> = ({
  layout = 'responsive',
  objectFit = 'cover',
  sizes,
  priority,
  mediaPayload,
  alt,
  loop,
  autoplay,
  muted,
  controlsPosition,
  withSoundControl,
  videoOverride,
  hardcropRatio,
  onPlaybackFinish,
  isVideoPaused,
}) => {
  return (
    <MediaFillWrap fill={layout === 'fill'}>
      {(() => {
        switch (mediaPayload?.type) {
          case 'sanity-image':
            return (
              <SanityImage
                alt={alt}
                mediaPayload={mediaPayload}
                sizes={sizes}
                objectFit={objectFit}
                priority={priority}
                layout={layout}
                // will hardcrop cosidering focal point
                hardcropRatio={hardcropRatio}
              />
            )
          case 'mux-video':
            return (
              <MuxVideo
                mediaPayload={mediaPayload}
                layout={layout}
                priority={priority}
                loop={loop}
                sizes={sizes}
                muted={muted}
                controlsPosition={controlsPosition}
                withSoundControl={withSoundControl}
                autoplay={autoplay}
                alt={alt}
                isVideoPaused={isVideoPaused}
                onPlaybackFinish={onPlaybackFinish}
                videoOverride={videoOverride}
              />
            )
          case 'shopify-image':
            return (
              <ShopifyImage
                alt={alt}
                sizes={sizes}
                objectFit={objectFit}
                mediaPayload={mediaPayload}
                layout={layout}
                priority={priority}
                loop={loop}
                // will hardcrop always to the center
                hardcropRatio={hardcropRatio}
              />
            )
          default:
            return <div>Unsupported media source</div>
        }
      })()}
    </MediaFillWrap>
  )
}
