import { createClient } from 'next-sanity'
import { config } from './config'

const client = createClient({
  ...config,
  token: '',
})

export const hitMutation = async () => {
  return await client.mutate([
    {
      patch: {
        id: '38bc06da-ee0e-4cae-8316-2877d12baf5f',
        set: {
          overwrittenProductOptions: [],
        },
      },
    },
  ])
}
