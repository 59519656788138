import { FC, useState } from 'react'

import { Logo, MobileDrawerClose } from '@liftfoils/icons'
import { styled } from '@liftfoils/styles'

import { HeaderUtilities } from './HeaderUtilities'
import { MobileTabs } from './MobileTabs'
import { Button, Link } from '@liftfoils/components'
import { SiteConfiguration } from '@liftfoils/models'

const ROW_HEIGHT = '$headerHeight'

const ContentStyled = styled('div', {
  position: 'relative',
  background: 'white',
  border: 'none',
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateRows: `${ROW_HEIGHT} 1fr ${ROW_HEIGHT}`,
})

const Header = styled('div', {
  display: 'grid',
  gridTemplateColumns: `${ROW_HEIGHT} 3fr ${ROW_HEIGHT}`,
  placeContent: 'stretch',
  position: 'sticky',
  top: 0,
  background: 'white',
  zIndex: 1,
})

const Body = styled('div', {
  borderTop: '1px solid $gray100',
  overflow: 'auto',

  '& > *': {
    height: ROW_HEIGHT,
    width: '100%',
  },

  '& > button': {
    color: '$gray500',
  },
})
const Footer = styled('div', {
  display: 'grid',
  justifyContent: 'center',
})
const NavItemButton = styled(Button, {
  color: '$gray500',
  lift_font: 'heading02',
  borderBottom: '1px solid $gray100',
  display: 'grid',
  alignItems: 'center',
  textAlign: 'left',
  pl: '$8',
  pr: '$7',
  gridTemplateColumns: '1fr auto',
})
const NavItemLink = styled(Link, {
  color: '$gray500',
  lift_font: 'heading02',
  borderBottom: '1px solid $gray100',
  display: 'grid',
  alignItems: 'center',
  textAlign: 'left',
  pl: '$8',
  pr: '$7',
  gridTemplateColumns: '1fr auto',
})

type ContentProps = {
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void
  header: SiteConfiguration['header']
  isShippingModalOpen: boolean
  handleOpenShippingModal: () => void
  handleCloseShippingModal: () => void
  searchConfig: SiteConfiguration['search']
}

export const MobileDrawer: FC<ContentProps> = ({
  onRequestClose,
  header,
  isShippingModalOpen,
  handleOpenShippingModal,
  handleCloseShippingModal,
  searchConfig,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null)

  return (
    <ContentStyled>
      <Header>
        <Button onClick={onRequestClose} appearance={'gridCenter'}>
          <MobileDrawerClose />
        </Button>
        <Link href={'/'} appearance={'gridCenter'}>
          <Logo />
        </Link>
      </Header>
      <Body>
        {header.tabs.map((t, i: number) => {
          return t.type === 'withoutSubnavigation' ? (
            <NavItemLink key={t.title} href={t.link.href}>
              <span>{t.title}</span>
            </NavItemLink>
          ) : (
            <NavItemButton key={t.title} onClick={() => setActiveTabIndex(i)}>
              <span>{t.title}</span>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7 8L20.4 15.7L12.7 23.4L12 22.7L19.1 15.7L12 8.7L12.7 8Z"
                  fill="#474646"
                />
              </svg>
            </NavItemButton>
          )
        })}
      </Body>
      <Footer>
        <HeaderUtilities
          appearance={'outlineGray300'}
          isShippingModalOpen={isShippingModalOpen}
          handleOpenShippingModal={handleOpenShippingModal}
          handleCloseShippingModal={handleCloseShippingModal}
          searchConfig={searchConfig}
        />
      </Footer>
      <MobileTabs
        activeTabIndex={activeTabIndex}
        onRequestTabClose={() => setActiveTabIndex(null)}
        header={header}
      />
    </ContentStyled>
  )
}
