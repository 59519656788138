import React from 'react'

export const LinkIcon = () => {
  return (
    <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.797 3.37589C10.506 3.66686 10.0343 3.66686 9.74334 3.37589C9.45237 3.08492 9.45237 2.61325 9.74334 2.32228L10.8104 1.25489C12.4835 -0.418295 15.1962 -0.418295 16.8694 1.25489C18.5425 2.92807 18.5425 5.64107 16.8694 7.31425L12.6546 11.5291C10.9814 13.2022 8.26836 13.2022 6.59518 11.5291C6.30421 11.2381 6.30421 10.7664 6.59518 10.4755C6.88615 10.1845 7.35819 10.1845 7.64916 10.4755C8.7404 11.5667 10.5093 11.5667 11.6006 10.4755L15.8157 6.26027C16.907 5.16903 16.907 3.4001 15.8157 2.30887C14.7245 1.21763 12.9552 1.21763 11.864 2.30887L10.797 3.37589Z"
        fill="white"
      />
      <mask
        id="mask0_2_4007"
        maskUnits="userSpaceOnUse"
        x="0"
        y="5"
        width="12"
        height="13"
      >
        <path d="M0.125 5.21631H11.8723V18.0002H0.125V5.21631Z" fill="white" />
      </mask>
      <g mask="url(#mask0_2_4007)">
        <path
          d="M7.45229 14.6244C7.74326 14.3334 8.21493 14.3334 8.5059 14.6244C8.79687 14.9153 8.79687 15.387 8.5059 15.678L7.43888 16.7454C5.7657 18.4186 3.05307 18.4186 1.37989 16.7454C-0.293295 15.0722 -0.293295 12.3592 1.37989 10.686L5.59469 6.47119C7.26787 4.79801 9.98088 4.79801 11.6541 6.47119C11.945 6.76217 11.945 7.23383 11.6541 7.5248C11.3631 7.81578 10.8911 7.81578 10.6001 7.5248C9.50884 6.43357 7.73991 6.43357 6.64867 7.5248L2.43349 11.74C1.34226 12.8312 1.34226 14.6002 2.43349 15.6914C3.52473 16.7826 5.29403 16.7826 6.38527 15.6914L7.45229 14.6244Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
