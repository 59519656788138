import { FC } from 'react'

import { styled } from '@liftfoils/styles'
import { MediaFieldType, PortableTextBlocks } from '@liftfoils/models'
import { PortableText } from '../PortableText/PortableText'
import { Media } from '../Media/Media'

const Wrap = styled('div', {
  position: 'relative',
  display: 'grid',
  rowGap: '$7',
})

const Caption = styled('div', {
  lift_font: 'body03',
  color: '$white_06',
  '& > p': {
    margin: '0!important',
  },
  b: {
    color: 'white',
  },
  a: {
    textDecoration: 'underline',
  },
})

export type ImageWithCaptionProps = {
  media: MediaFieldType
  sizes?: string
  caption?: PortableTextBlocks
}

export const ImageWithCaption: FC<ImageWithCaptionProps> = ({
  media,
  caption,
  sizes,
}) => {
  return (
    <Wrap>
      <Media
        {...media}
        layout={'responsive'}
        sizes={sizes}
        alt={media?.alt ?? ''}
      />
      {caption && (
        <Caption>
          <PortableText value={caption} />
        </Caption>
      )}
    </Wrap>
  )
}
