import { ShopifyProduct } from '@liftfoils/models'
import { FC } from 'react'

type ProductReviewsStarsProps = {
  shopifyProduct: ShopifyProduct
}
export const ProductReviewsStars: FC<ProductReviewsStarsProps> = ({
  shopifyProduct,
}) => {
  return (
    <span
      className="stamped-product-reviews-badge stamped-main-badge"
      data-id={shopifyProduct.id.replace('gid://shopify/Product/', '')}
      data-product-sku={shopifyProduct.handle}
      data-product-title={shopifyProduct.title}
    ></span>
  )
}
