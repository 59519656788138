import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { submitForm } from '@liftfoils/services/api-service'
import { validateEmail } from '../helpers/validateEmail'
import { validateRequired } from '../helpers/validateRequired'
import { useFormSubmitState } from '../helpers/useFormSubmitState'
import { FormTextField, FormPhoneField, SubmitButton } from '../inputs'
import { useRouter } from 'next/router'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'

interface ContactFormProps {
  className?: string
}

type FormValues = {
  phoneNumber: string
  name: string
  email?: string
  message: string
}

const defaultValues: FormValues = {
  phoneNumber: '',
  name: '',
  email: '',
  message: '',
}

const Form = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$4',
})

export const ContactForm: FC<ContactFormProps> = ({ className }) => {
  const { t } = useTranslation('forms')
  const form = useForm<FormValues>({
    defaultValues,
  })

  const { locale } = useRouter()
  const [, regionId] = getLocaleRegionIdFromPath(locale)

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = form

  const { invalid, success, setSuccess, setError, submitMessage } =
    useFormSubmitState<FormValues>({ form, defaultValues })

  const onSubmit = async (data: FormValues) => {
    try {
      const response = await submitForm('contactForm', {
        ...data,
        region: regionId,
      })
      if (!response.ok) {
        return setError()
      }
      return setSuccess()
    } catch {
      return setError()
    }
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className={className}
      name="contact-form"
    >
      <FormPhoneField
        control={control}
        rules={validateRequired(t)}
        name="phoneNumber"
        label={t('contactForm.phoneFieldLabel')}
        form={form}
      />

      <FormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="name"
        name="name"
        label={t('contactForm.nameFieldLabel')}
      />

      <FormTextField
        control={control}
        rules={{ ...validateRequired(t), ...validateEmail(t) }}
        autoComplete="email"
        type="email"
        name="email"
        label={t('contactForm.emailFieldLabel')}
      />

      <FormTextField
        control={control}
        rules={validateRequired(t)}
        name="message"
        label={t('contactForm.messageFieldLabel')}
        multiline
        rows={8}
      />

      <SubmitButton
        text={t('contactForm.submitButtonLabel')}
        helpText={submitMessage}
        invalid={invalid}
        success={success}
        loading={isSubmitting}
      />
    </Form>
  )
}
