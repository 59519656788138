import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import TRANSLATIONS_EN from './locales/en.json'
import TRANSLATIONS_DE from './locales/de.json'
import TRANSLATIONS_IT from './locales/it.json'
import TRANSLATIONS_ES from './locales/es.json'
import TRANSLATIONS_FR from './locales/fr.json'

const getI18n = (locale: string) => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: TRANSLATIONS_EN,
        de: TRANSLATIONS_DE,
        it: TRANSLATIONS_IT,
        es: TRANSLATIONS_ES,
        fr: TRANSLATIONS_FR,
      },
      fallbackLng: 'en',
      lng: locale,
    })
  return i18n
}

export default getI18n
