import { styled } from '@liftfoils/styles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Star } from '@liftfoils/icons'
import { ShopifyResolvedProductBasic } from '@liftfoils/shopify-sdk'
import { ROUTE_BASE_PRODUCTS } from '@liftfoils/configs'
import { MediaPlaceholder } from '../Media/components/MediaPlaceholder'
import { Media } from '../Media/Media'
import {
  ContextualizedProductPrice,
  ContextualizedProductVariantPrice,
} from '../Price'
import { Link } from '../Link/Link'

const Overlay = styled('div', {
  pointerEvents: 'none',
  border: '2px solid $teal500',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 2,
  borderRadius: '$r1',
  opacity: 0,
  transition: 'opacity 100ms',
})
const RootLink = styled(Link, {
  position: 'relative',
  display: 'grid',
})

const CardWrap = styled('div', {
  gridArea: '1/1',
  display: 'grid',
  borderRadius: '$r1',
  gridTemplateRows: 'auto $9 1fr',
  border: '1px solid $gray200',
  overflow: 'hidden',
  '&:hover': {
    [`${Overlay}`]: {
      opacity: 1,
    },
  },
})

const Badges = styled('div', {
  px: '$7',
  alignSelf: 'end',
})
const Badge = styled('div', {
  color: '$teal500',
  lift_font: 'body03',
})

const PriceWrap = styled('div', {
  lift_font: 'caps04',
  color: '$gray300',
  transition: 'color 100ms',
})

const Footer = styled('div', {
  position: 'relative',
  px: '$7',
  pb: '$9',
  color: '$gray500',
})

const Title = styled('h3', {
  lift_font: 'caps04',
  mb: '$4',
  transition: 'color 100ms',
})
const Rating = styled('div', {
  padding: '$3 0',
})
const RatingLabel = styled('span', {
  lift_font: 'body06',
  color: '$gray500',
  marginLeft: '$4',
})
type ProductCardProps = {
  product: ShopifyResolvedProductBasic
}

export const ProductCard: FC<ProductCardProps> = ({ product }) => {
  const { t } = useTranslation('product')
  const { handle, featuredImage, title, priceRange, metafields, variants } =
    product
  const href = `${ROUTE_BASE_PRODUCTS}/${handle}`
  const isNew = product.tags.includes('new')
  const rating = metafields.rating

  const variantsPriceDiffers =
    priceRange.maxVariantPrice.amount !== priceRange.minVariantPrice.amount

  const firstProductVariant = variants.nodes[0]

  return (
    <RootLink href={href}>
      <CardWrap>
        <MediaPlaceholder ratio="square">
          {featuredImage && (
            <Media
              mediaPayload={featuredImage}
              objectFit="contain"
              layout="fill"
              sizes="(min-width: 1000px) 32vw, 50vw"
              alt=""
            />
          )}
        </MediaPlaceholder>
        <Badges>{isNew && <Badge>{t('new')}</Badge>}</Badges>
        <Footer>
          {title && <Title>{title}</Title>}
          <PriceWrap>
            {variantsPriceDiffers ? (
              <>
                <ContextualizedProductPrice
                  priceRange={priceRange}
                  productId={product.id}
                />
              </>
            ) : (
              <ContextualizedProductVariantPrice
                price={firstProductVariant.price}
                compareAtPrice={firstProductVariant.compareAtPrice ?? undefined}
                productVariantId={firstProductVariant.id}
              />
            )}
          </PriceWrap>

          {rating && (
            <Rating>
              <Star />
              <RatingLabel>{rating}</RatingLabel>
            </Rating>
          )}
        </Footer>
        <Overlay />
      </CardWrap>
    </RootLink>
  )
}
