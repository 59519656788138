import { ShopifyResolvedCartLine } from '@liftfoils/shopify-sdk'

export const getLinesCost = (lines?: ShopifyResolvedCartLine[]) => {
  if (!(lines && lines.length > 0)) return undefined
  console.log('getLinesCost', lines)
  return {
    amount: lines
      .reduce((acc, line) => {
        return Number(line.cost.totalAmount.amount) + acc
      }, 0)
      .toString(),
    currencyCode: lines[0].cost.amountPerQuantity.currencyCode,
  }
}
