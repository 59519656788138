import React from 'react'
import { styled } from '@stitches/react'

const Root = styled('svg', {})

export const ArrowLeft = () => {
  return (
    <Root
      width="12"
      height="10"
      viewBox="0 0 12 10"
      xmlns="http://www.w3.org/2000/svg"
      fill={'currentColor'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.48835 9.22659C4.56518 9.30343 4.69324 9.30343 4.77007 9.22659L5.48718 8.50923C5.56401 8.43239 5.56401 8.30434 5.48718 8.22776L2.61874 5.35906L11.6851 5.35906C11.7875 5.35906 11.8897 5.25687 11.8897 5.15443V4.12998C11.8897 4.02754 11.7875 3.92509 11.6851 3.92509L2.61874 3.92509L5.48718 1.05665C5.56401 0.979817 5.56401 0.851761 5.48718 0.774928L4.77007 0.0578171C4.69324 -0.0192724 4.56518 -0.0192724 4.48835 0.0578171L0.057625 4.48854C-0.0192083 4.56537 -0.0192083 4.69343 0.057625 4.77026L4.48835 9.22659Z"
      />
    </Root>
  )
}
