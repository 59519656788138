import React, { FC } from 'react'
import { useRouter } from 'next/router'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { ShopifyMoneyFieldsFragment } from '@liftfoils/shopify-sdk'

export const PricePrimitive: FC<{
  price?: ShopifyMoneyFieldsFragment
}> = ({ price }) => {
  const { locale } = useRouter()

  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)

  const internationalizedPrice =
    price &&
    new Intl.NumberFormat(`${localeId}-${regionId}`, {
      style: 'currency',
      currency: price.currencyCode,
    }).format(Number(price.amount))

  return <>{price && internationalizedPrice}</>
}
