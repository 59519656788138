import { FC, PropsWithChildren } from 'react'
import NextLink, { LinkProps } from 'next/link'
import {
  StyledClickable,
  StyledClickableProps,
} from '../StyledClickable/StyledClickable'
import { LinkAction } from './LinkAction'
import { Action } from '@liftfoils/models'

type LiftLinkProps = {
  action?: Action
  href?: LinkProps['href']
  shallow?: boolean
  scroll?: boolean
} & StyledClickableProps & {
    target?: '_blank'
    className?: string
    download?: boolean
  }

export const Link: FC<PropsWithChildren<LiftLinkProps>> = ({
  action,
  children,
  download,
  size,
  appearance,
  href,
  tabIndex,
  target,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  className,
  shallow,
  scroll,
}) => {
  const elementProps = {
    download,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    tabIndex,
    target,
  }

  if (href) {
    return (
      <StyledClickable
        as={NextLink}
        className={className}
        appearance={appearance}
        size={size}
        {...elementProps}
        href={href}
        passHref
        shallow={shallow}
        scroll={scroll}
      >
        {children}
      </StyledClickable>
    )
  }

  if (action) {
    return (
      <LinkAction
        action={action}
        className={className}
        renderButton={(actionProps) => (
          <StyledClickable
            {...elementProps}
            className={className}
            appearance={appearance}
            size={size}
            {...actionProps}
            as={'button'}
          >
            {children}
          </StyledClickable>
        )}
      ></LinkAction>
    )
  }

  return null
}
