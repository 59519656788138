import { FC, useEffect } from 'react'
import Script from 'next/script'
import { useRouter } from 'next/router'

declare global {
  interface Window {
    fbq: any
  }
}

const pageview = () => {
  window.fbq('track', 'PageView')
}

const handleRouteChange = () => {
  pageview()
}

export const FacebookPixelService: FC<{ id: string }> = ({ id }) => {
  const { events } = useRouter()
  useEffect(() => {
    // the below will only fire on route changes (not initial load - that is handled in the script below)
    events.on('routeChangeComplete', handleRouteChange)
    return () => {
      events.off('routeChangeComplete', handleRouteChange)
    }
  }, [events])
  return (
    <>
      {/*Meta Pixel Code */}
      <Script id={'facebook-pixel'}>
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', ${id});
          fbq('track', 'PageView');
        `}
      </Script>
      <noscript>
        <img
          height={'1'}
          width={'1'}
          style={{ display: 'none' }}
          src={`https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`}
        />
      </noscript>
      {/*End Meta Pixel Code*/}
    </>
  )
}
