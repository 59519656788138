import { getSearchResults } from '@liftfoils/services/shopify-service'
import type { ShopifyResolvedProductBasic } from '@liftfoils/shopify-sdk'

async function getShopifyProductsResultsByQuery(
  query: string,
): Promise<Array<ShopifyResolvedProductBasic>> {
  if (query === '') {
    return []
  }

  const results = await getSearchResults({ query, count: 100 })
  return results.products
}

export { getShopifyProductsResultsByQuery }
