import { FC, useRef, useState } from 'react'
import { styled } from '@liftfoils/styles'
import { SectionProductConversionAreaProps } from '@liftfoils/models'
import { PortableText } from '../PortableText/PortableText'
import { Media } from '../Media/Media'
import { Close } from '@liftfoils/icons'
import { flushSync } from 'react-dom'

const Main = styled('div', {
  position: 'relative',
  background: '$white',
})

const Container = styled('div', {
  position: 'relative',
})

const Content = styled('div', {
  '@lg': {
    position: 'sticky',
    bottom: 0,
    display: 'grid',
    gridTemplateColumns: '1fr 330px',
    $mr: 'L',
  },
})

const CardList = styled('div', {
  $p: 'M',
  color: '$gray300',
  borderTop: '1px solid $gray200',

  '@lg': {
    padding: 0,
    $pt: 'L',
    border: 'none',
    width: 330,
  },
})

const Card = styled('button', {
  $p: 'S',
  lift_font: 'body02',
  background: '$gray200',
  borderRadius: '$r1',
  marginBottom: '$6',
  textAlign: 'left',
  width: '100%',
  transition: 'background 150ms ease',
  color: '$gray300',

  variants: {
    active: {
      true: {
        background: '$teal500',
        color: '$white',
      },
    },
  },
})

const ImageContainer = styled('div', {
  position: 'relative',
  height: 'auto',
  maxWidth: '100vw',
  margin: '0 auto',
  aspectRatio: '1 / 1',

  '@lg': {
    position: 'sticky',
    height: '100vh',
    maxWidth: 'calc(100vw - 330px)',
    top: 0,
  },
})

const ProductName = styled('div', {
  left: 0,
  top: 0,
  $p: 'M',
  zIndex: 1,
  lift_font: 'heading02',

  'p:last-child': {
    color: '$gray300',
  },

  '@lg': {
    position: 'fixed',
  },
})

const CardTitle = styled('p', {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: '$6',
  marginBottom: '$6',
  color: '$black',

  variants: {
    active: {
      true: {
        color: '$white',
      },
    },
  },
})

const HotSpot = styled('button', {
  background: '$white_04',
  borderRadius: '$rMax',
  display: 'grid',
  placeContent: 'center',
  lift_font: 'body02',
  zIndex: 1,
  position: 'absolute',
  backdropFilter: 'blur(10px)',
  transform: 'translate(-50%, -50%)',
  transition: 'background 150ms ease',
  width: 36,
  height: 36,

  '@lg': {
    width: 53,
    height: 53,
  },

  variants: {
    active: {
      true: {
        background: '$teal500',
        color: '$white',
      },
    },
  },
})

const CloseButton = styled('button', {
  position: 'fixed',
  right: 0,
  top: 0,
  $pr: 'S',
  $pt: 'M',
  background: 'none',
  zIndex: 1,
})

type AnatomyProps = SectionProductConversionAreaProps['anatomy'] & {
  onClose: () => void
}

export const Anatomy: FC<AnatomyProps> = ({
  anatomyImage,
  hotspots,
  description,
  title,
  onClose,
}) => {
  const [activeHotspot, setActiveHotspot] = useState<number>(-1)
  const activeCardRef = useRef<HTMLButtonElement>(null)
  const detailsRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const updateActiveHotspot = (idx: number) => {
    flushSync(() => {
      setActiveHotspot(idx)
    })
    activeCardRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }
  return (
    <Main ref={containerRef}>
      <ProductName>
        {title && <p>{title}</p>}
        {description && <p>{description}</p>}
      </ProductName>
      <CloseButton onClick={onClose}>
        <Close />
      </CloseButton>

      <Container>
        <Content>
          <ImageContainer>
            {hotspots.map((hotspot, idx) => (
              <HotSpot
                key={`hotspot-${hotspot.title}-${idx}`}
                style={{ top: `${hotspot.y}%`, left: `${hotspot.x}%` }}
                active={activeHotspot === idx}
                onClick={() => updateActiveHotspot(idx)}
              >
                {idx + 1}
              </HotSpot>
            ))}
            <Media
              {...anatomyImage}
              layout="fill"
              objectFit="contain"
              sizes="100vw"
              alt={anatomyImage.alt ?? ''}
            />
          </ImageContainer>
          <CardList ref={detailsRef}>
            {hotspots.map((hotspot, idx) => {
              return (
                <Card
                  key={`card-${hotspot.title}-${idx}`}
                  active={activeHotspot === idx}
                  ref={activeHotspot === idx ? activeCardRef : null}
                  onClick={() => updateActiveHotspot(idx)}
                >
                  <CardTitle active={activeHotspot === idx}>
                    <span>{idx + 1}</span>
                    <span>{hotspot.title}</span>
                  </CardTitle>
                  <PortableText value={hotspot.description} />
                </Card>
              )
            })}
          </CardList>
        </Content>
      </Container>
    </Main>
  )
}
