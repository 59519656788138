import { useRef, useState, forwardRef, useImperativeHandle } from 'react'
import { styled } from '@liftfoils/styles'

const AccordionRoot = styled('div', {
  display: 'block',
  height: '0px',
  overflow: 'hidden',
})

const AccordionWrapper = styled('div', {
  display: 'grid',
  variants: {
    isVisible: {
      true: {
        opacity: 1,
        visibility: 'visible',
      },
      false: {
        opacity: 0,
        visibility: 'hidden',
      },
    },
  },
})

export interface AccodrionMethods {
  open: () => void
  close: () => void
}

type AccordionProps = {
  transitionDuration?: number
  initOpen?: boolean
  children: any
}

export const Accordion = forwardRef<AccodrionMethods, AccordionProps>(
  function Accordion(props, ref) {
    const [accordionHeight, setAccordionHeight] = useState(
      props.initOpen ? 'auto' : '0px',
    )
    const [isOpen, setIsOpen] = useState(!!props.initOpen)

    const transitionDuration = props.transitionDuration
      ? props.transitionDuration
      : 300

    const accordionRootRef = useRef<HTMLDivElement>(null)
    const accordionWrapperRef = useRef<HTMLDivElement>(null)

    const open = () => {
      if (!isOpen && accordionRootRef.current && accordionWrapperRef.current) {
        setAccordionHeight(
          accordionWrapperRef.current?.getBoundingClientRect().height + 'px',
        )
        setIsOpen(true)
        setTimeout(function () {
          setAccordionHeight('auto')
        }, transitionDuration)
      }
    }

    const close = () => {
      if (isOpen && accordionRootRef.current && accordionWrapperRef.current) {
        setAccordionHeight(
          accordionWrapperRef.current?.getBoundingClientRect().height + 'px',
        )
        setIsOpen(false)
        setTimeout(function () {
          setAccordionHeight('0px')
        }, 20)
      }
    }

    useImperativeHandle(ref, () => ({
      open,
      close,
    }))

    return (
      <AccordionRoot
        ref={accordionRootRef}
        css={{
          height: accordionHeight,
          transitionDuration: transitionDuration + 'ms',
        }}
      >
        <AccordionWrapper
          isVisible={isOpen}
          ref={accordionWrapperRef}
          role="region"
          css={{ transitionDuration: transitionDuration + 'ms' }}
        >
          {props.children}
        </AccordionWrapper>
      </AccordionRoot>
    )
  },
)
