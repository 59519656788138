import { Action } from '@liftfoils/models'
import { breakpoints, useMediaQuery } from '@liftfoils/styles'
import dynamic from 'next/dynamic'
import { FC, ReactNode, useState } from 'react'
import { Modal } from '../../../Modal/Modal'

const DynamicStoryModalContent = dynamic(
  // @ts-ignore
  () => import('./StoryModalContent').then((mod) => mod.StoryModalContent),
  { ssr: false },
)

type ActionProps = {
  onClick?: () => void
}

type StoryModalProps = {
  children?: ReactNode
  action?: Action
  renderButton: (actionProps: ActionProps) => JSX.Element
}

export const StoryModal: FC<StoryModalProps> = ({ action, renderButton }) => {
  const matchesMd = useMediaQuery(breakpoints.md)
  const [isOpen, setIsOpen] = useState(false)
  const toggleModal = () => setIsOpen(!isOpen)

  const actionProps = {
    onClick: () => toggleModal(),
  }

  return (
    <>
      {renderButton(actionProps)}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        position="top"
        size={matchesMd ? 'intrinsic' : 'stretch-x'}
        overlayVariant="blur"
      >
        <DynamicStoryModalContent action={action} onClose={toggleModal} />
      </Modal>
    </>
  )
}
