import {
  createShopifySdk,
  formatShopifyProductBasic,
  ShopifyCountryCode,
  ShopifyProductRecommendationIntent,
  ShopifyResolvedProductBasic,
} from '@liftfoils/shopify-sdk'
import { createStorefrontApiClient } from './createStorefrontApiClient'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'

export const getRecommendedProducts = async (
  productId: string,
  locale?: string,
  intent?: ShopifyProductRecommendationIntent,
) => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
  const shopifyClient = createStorefrontApiClient(localeId)

  try {
    return await createShopifySdk(shopifyClient)
      .productRecommendations({
        regionId: regionId as ShopifyCountryCode,
        productId,
        intent,
      })
      .then((res) => {
        const productsTrimmed = res.data.productRecommendations?.slice(0, 3)
        if (!productsTrimmed) return []

        return productsTrimmed.reduce<ShopifyResolvedProductBasic[]>(
          (acc, product) => {
            return [...acc, formatShopifyProductBasic(product)]
          },
          [],
        )
      })
  } catch (err) {
    console.error(err)
  }
}
