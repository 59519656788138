import React, { FC } from 'react'
import { PricePrimitive } from './PricePrimitive'
import { ShopifyMoneyFieldsFragment } from '@liftfoils/shopify-sdk'

export const ProductVariantPrice: FC<{
  price?: ShopifyMoneyFieldsFragment | null
  compareAtPrice?: ShopifyMoneyFieldsFragment | null
  ignoreCompareAtPrice?: boolean
}> = ({ price, compareAtPrice, ignoreCompareAtPrice }) => {
  if (!price) return null

  return (
    <>
      <PricePrimitive price={price} />
      {compareAtPrice && !ignoreCompareAtPrice && (
        <>
          &nbsp;
          <s>
            <PricePrimitive price={compareAtPrice} />
          </s>
        </>
      )}
    </>
  )
}
