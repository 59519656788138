import React, { PropsWithChildren } from 'react'
import { styled } from '@liftfoils/styles'

const Wrap = styled('div', {
  $hasContainerMX: true,
})

export const Container: React.FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return <Wrap className={className}>{children}</Wrap>
}
