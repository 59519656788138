import { FC, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { styled } from '@liftfoils/styles'
import { Logo } from '@liftfoils/icons'
import { PromoBarType, SiteConfiguration } from '@liftfoils/models'
import { HeaderProvider, useHeaderContext } from './HeaderContext'
import { Modal, Link, Button, Gradient } from '@liftfoils/components'
import { BurgerIcon } from './BurgerIcon'
import { HeaderUtilities } from './HeaderUtilities'
import { MobileDrawer } from './MobileDrawer'
import { CartQuantity } from './CartQuantity'
import { PromoBar } from './PromoBar/PromoBar'
// import { RegionSelectBar } from './RegionSelectBar'
import { useInView } from 'react-intersection-observer'
import { DesktopNavItem } from './DesktopNavItem'
import { NAV_ENTERING_TIME } from './header.config'
import { useAssistanceContext } from '@liftfoils/assistance'
import { ModalAssistance } from './ModalAssistance'
import { useAccount } from '@liftfoils/services/shopify-service'

const GradientWrap = styled('div', {
  position: 'relative',
})
const Root = styled('div', {
  position: 'sticky',
  top: 0,
  zIndex: 10,
})
const Wrap = styled('div', {
  height: '$headerHeight',
  gridTemplateColumns: '$headerHeight 2fr $headerHeight',
  '@lg': {
    px: '$8',
    gridTemplateColumns: '1fr 2fr 1fr',
  },
  position: 'absolute',
  width: '100%',
  display: 'grid',
  alignContent: 'stretch',
  transition: `background ${NAV_ENTERING_TIME}ms, transform ${NAV_ENTERING_TIME}ms ease-out, box-shadow ${NAV_ENTERING_TIME}ms`,
  variants: {
    hasBackground: {
      true: {
        background: 'white',
      },
    },
    color: {
      black: {
        color: 'black',
      },
      white: {
        color: 'white',
      },
    },
    isShifted: {
      true: {
        transform: 'translateY(-100%)',
      },
    },
    hasShadow: {
      true: {
        boxShadow: '0 2px 18px 0 rgba(0, 0, 0, 0.05)',
      },
    },
  },
})

const UtilitiesWrap = styled('div', {
  display: 'none',
  '@lg': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
})

const Indicator = styled('div', {
  bg: 'rgba(200,200,200,0.2)',
  height: '$buttonHeightS',
  margin: '21px 0',
  width: '$4',
  position: 'absolute',
  borderRadius: '$rMax',
  blur: '10px',
  pointerEvents: 'none',
  variants: {
    visibility: {
      visible: {
        opacity: 1,
        transition: '200ms',
      },
      hidden: {
        opacity: 0,
        transition: 'opacity 200ms, left 0ms, width 0ms 10ms',
      },
    },
  },
})

const Backdrop = styled('div', {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  background: '$gray500_02',
  pointerEvents: 'none',
  transition: `opacity ${NAV_ENTERING_TIME}ms`,
  variants: {
    isVisible: {
      false: {
        opacity: 0,
      },
    },
  },
})
const BurgerWrap = styled('div', {
  display: 'grid',
  width: '$headerHeight',
  height: '$headerHeight',
  '@lg': {
    display: 'none',
  },
})
const LogoWrap = styled('div', {
  justifySelf: 'center',
  alignSelf: 'center',
  '@lg': {
    justifySelf: 'start',
  },
})
const MobileCartWrap = styled('div', {
  placeSelf: 'center end',
  mr: '$7',
  '@lg': {
    display: 'none',
  },
})

const TopBarsObserver = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  height: 'calc($promoBarHeight + $regionBarHeight)',
  width: '100%',
  visibility: 'hidden',
  pointerEvents: 'none',
})

const DesktopNavigationWrap = styled('nav', {
  display: 'none',
  '@lg': {
    display: 'grid',
    justifyContent: 'center',
  },
})

const ListWrap = styled('ul', {
  display: 'grid',
  gridAutoColumns: 'auto',
  gridAutoFlow: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$5',
  a: {
    zIndex: 1,
  },
  '@xl': {
    gap: '$8',
  },
  '@xxl': {
    gap: '$9',
  },
})

type Props = {
  initialTheme: 'default' | 'transparent' | 'transparent-black'
  header: SiteConfiguration['header']
  assistance: SiteConfiguration['assistance']
  searchConfig: SiteConfiguration['search']
  promoBarConfig?: PromoBarType
}

export const HeaderRaw: FC<Props> = ({
  initialTheme,
  header,
  assistance,
  searchConfig,
  promoBarConfig,
}) => {
  const router = useRouter()
  const [isShippingModalOpen, setShippingModalOpen] = useState(false)
  const isInitiallyTransparent = initialTheme !== 'default'
  const [shouldBeTransparent, setShouldBeTransparent] = useState(
    isInitiallyTransparent,
  )
  const [shouldBeShifted, setShouldBeShifted] = useState(false)
  const [isScrolledDown, setIsScrolledDown] = useState(false)
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const [activeLinkId, setActiveLinkId] = useState<string | undefined>(
    undefined,
  )
  const { assistanceState, closeAssistanceModal } = useAssistanceContext()
  const { isB2B } = useAccount()
  const {
    headerState: { activeTabId, navigationAnimationStatus },
    handleClose,
  } = useHeaderContext()
  const refPrevScrollPos = useRef<number>(0)
  const refIndicator = useRef<HTMLDivElement | null>(null)
  const refNav = useRef<HTMLUListElement | null>(null)
  const navigationMenuItemsRef = useRef<Record<string, HTMLSpanElement | null>>(
    {},
  )

  const { ref: observerRef, inView: inScrolledBelowTopBars } = useInView({
    threshold: 0,
  })

  const headerWhiteBackgroundScrollPosition = 400
  const isNavigationExited =
    navigationAnimationStatus === 'EXITED' ||
    navigationAnimationStatus === 'EXITING'

  const closeDrawer = () => {
    setShouldBeTransparent(isInitiallyTransparent)
    handleClose()
  }

  const setIndicatorOnActiveLink = () => {
    if (!refIndicator.current || !activeLinkId) return
    const elem = navigationMenuItemsRef?.current[activeLinkId]

    if (elem) {
      const rect = elem.getBoundingClientRect()
      refIndicator.current.style.width = `${rect.width}px`
      refIndicator.current.style.left = `${rect.left}px`
    } else {
      refIndicator.current.style.width = `0px`
    }
  }

  useEffect(() => {
    window.addEventListener('resize', setIndicatorOnActiveLink)
    return () => {
      window.removeEventListener('resize', setIndicatorOnActiveLink)
    }
  }, [])

  useEffect(() => {
    setIndicatorOnActiveLink()
  }, [activeLinkId])

  useEffect(() => {
    setActiveLinkId(
      header?.tabs?.find((tab) => tab.link.href === router.asPath)?.id,
    )
  }, [router.asPath])

  useEffect(() => {
    if (!refNav.current || !refIndicator.current) return
    if (!activeTabId) {
      setIndicatorOnActiveLink()
      return
    }
    const elem = navigationMenuItemsRef?.current[activeTabId]
    if (elem) {
      const rect = elem.getBoundingClientRect()
      refIndicator.current.style.width = `${rect.width}px`
      refIndicator.current.style.left = `${rect.left}px`
      setShouldBeTransparent(false)
    }
  }, [activeTabId])

  useEffect(() => {
    setShouldBeTransparent(isInitiallyTransparent)
  }, [isInitiallyTransparent])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos: number = window.scrollY
      if (
        currentScrollPos > refPrevScrollPos.current &&
        !inScrolledBelowTopBars
      ) {
        setShouldBeShifted(true)
      } else {
        setShouldBeShifted(false)
      }

      if (currentScrollPos < headerWhiteBackgroundScrollPosition) {
        setIsScrolledDown(false)
      } else {
        setIsScrolledDown(true)
      }
      refPrevScrollPos.current = window.scrollY
    }
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  useEffect(() => {
    const handleRouteChange = () => {
      // close mobile/destkop nav
      setShouldBeTransparent(true)
      if (isMobileNavOpen) setMobileNavOpen(false)
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [isMobileNavOpen, router.events])

  // computed

  const isShifted = shouldBeShifted && isNavigationExited
  const isTextWhite =
    initialTheme === 'transparent' && isNavigationExited && !isScrolledDown

  const isTransparent =
    shouldBeTransparent && !isScrolledDown && isNavigationExited

  const navButtonAppearance = isTextWhite ? 'bareWhite' : 'bareGray'

  const svgPathStyle = {
    fill: isTextWhite ? 'white' : 'black',
    transition: `fill ${NAV_ENTERING_TIME}ms`,
  }

  const handleOpenShippingModal = () => {
    setShippingModalOpen(true)
  }
  const handleCloseShippingModal = () => {
    setShippingModalOpen(false)
  }

  return (
    <>
      {/*<RegionSelectBar handleOpenShippingModal={handleOpenShippingModal} />*/}
      {promoBarConfig?.isVisible && (
        <PromoBar promoBarConfig={promoBarConfig} />
      )}
      <GradientWrap>
        {initialTheme === 'transparent' && (
          <Gradient from={'top'} css={{ height: '10vw', zIndex: 1 }} />
        )}
      </GradientWrap>
      <TopBarsObserver ref={observerRef} />
      <Root>
        <Backdrop isVisible={!isNavigationExited} />
        <Wrap
          hasBackground={!isTransparent}
          color={isTextWhite ? 'white' : 'black'}
          hasShadow={isScrolledDown}
          isShifted={isShifted}
          onMouseLeave={closeDrawer}
        >
          <BurgerWrap>
            <Button
              onClick={() => setMobileNavOpen(true)}
              appearance={'gridCenter'}
            >
              <BurgerIcon pathStyle={svgPathStyle} />
            </Button>

            <Modal
              isOpen={isMobileNavOpen}
              onRequestClose={() => setMobileNavOpen(false)}
              size={'stretch'}
            >
              <MobileDrawer
                onRequestClose={() => setMobileNavOpen(false)}
                header={header}
                handleOpenShippingModal={handleOpenShippingModal}
                isShippingModalOpen={isShippingModalOpen}
                handleCloseShippingModal={handleCloseShippingModal}
                searchConfig={searchConfig}
              />
            </Modal>
          </BurgerWrap>
          <LogoWrap onMouseEnter={closeDrawer}>
            <Link href={`/`} appearance={'gridCenter'}>
              <Logo pathStyle={svgPathStyle} />
            </Link>
          </LogoWrap>

          <DesktopNavigationWrap>
            <ListWrap ref={refNav}>
              {header?.tabs?.map((tab) => {
                if (tab.b2bOnly && !isB2B) return null
                return (
                  <DesktopNavItem
                    key={tab.id}
                    tab={tab}
                    navButtonAppearance={navButtonAppearance}
                    onCloseRequest={closeDrawer}
                    ref={(el) => (navigationMenuItemsRef.current[tab.id] = el)}
                  />
                )
              })}
              <Indicator
                ref={refIndicator}
                visibility={
                  !isNavigationExited || activeLinkId ? 'visible' : 'hidden'
                }
              />
            </ListWrap>
          </DesktopNavigationWrap>

          <UtilitiesWrap onMouseEnter={closeDrawer}>
            <HeaderUtilities
              appearance={navButtonAppearance}
              isShippingModalOpen={isShippingModalOpen}
              handleOpenShippingModal={handleOpenShippingModal}
              handleCloseShippingModal={handleCloseShippingModal}
              searchConfig={searchConfig}
            />
          </UtilitiesWrap>
          <ModalAssistance
            assistance={assistance}
            isOpen={assistanceState.isOpen}
            handleClose={closeAssistanceModal}
          />
          <MobileCartWrap>
            <CartQuantity />
          </MobileCartWrap>
        </Wrap>
      </Root>
    </>
  )
}

export const Header: FC<Props> = (props) => (
  <HeaderProvider>
    <HeaderRaw {...props} />
  </HeaderProvider>
)
