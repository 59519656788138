import { styled } from '@liftfoils/styles'
import { B2BToggle } from '@liftfoils/components'
import { CartQuantity } from './CartQuantity'
import { ButtonAssistance } from './ButtonAssistance'
import { SearchButton } from '@liftfoils/search'
import { SiteConfiguration } from '@liftfoils/models'

const Utils = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$5',
})
export const HeaderUtilities = ({
  appearance,
  // isShippingModalOpen,
  // handleOpenShippingModal,
  // handleCloseShippingModal,
  searchConfig,
}: {
  appearance: 'bareWhite' | 'bareGray' | 'outlineGray300'
  isShippingModalOpen: boolean
  searchConfig: SiteConfiguration['search']
  handleOpenShippingModal: () => void
  handleCloseShippingModal: () => void
}) => {
  return (
    <Utils>
      <B2BToggle />
      <SearchButton appearance={appearance} searchConfig={searchConfig} />
      {/*<ShippingButton*/}
      {/*  appearance={appearance}*/}
      {/*  isShippingModalOpen={isShippingModalOpen}*/}
      {/*  handleOpenShippingModal={handleOpenShippingModal}*/}
      {/*  handleCloseShippingModal={handleCloseShippingModal}*/}
      {/*/>*/}
      <ButtonAssistance />
      <CartQuantity />
    </Utils>
  )
}
