import { groq } from 'next-sanity'
import { getClient } from './getClient'

export const createGearSelectorProductsQuery = (
  answers: Record<string, string>,
  gearSelectorType: 'efoil' | 'classicFoil',
  localeId: string,
) => {
  if (!Object.keys(answers).length) {
    throw new Error(
      `'Invalid input. Expected to get answers object, but got ${answers}.'`,
    )
  }

  const answersQuery = Object.keys(answers)
    .map((key) => {
      return `"${answers[key]}" in answers[] -> title.${localeId}`
    })
    .join(' && ')

  const id =
    gearSelectorType === 'efoil'
      ? 'eFoilsGearSelector'
      : 'classicFoilGearSelector'

  return groq`(*[_type == "classicFoilGearSelector" && _id == "${id}"]
    .gearSelectorResults[${answersQuery}] {
      "productIds": resultProducts[] -> store.gid,
      "mainProduct": {
        "path": mainProduct -> path.current,
        "kitsLength": count( mainProduct -> kits[] ),
        "title": mainProduct -> mainProduct -> store.title
      }
    })[0]`
}

export type GearSelectorSanityMainProduct = {
  path?: string
  title?: string
  kitsLength?: number
}
export type GearSelectorSanityProductsData = {
  productIds: string[]
  mainProduct: GearSelectorSanityMainProduct
}

export const getGearSelectorProducts = async (
  answers: Record<string, string>,
  gearSelectorType: 'efoil' | 'classicFoil',
  localeId: string,
  preview = false,
): Promise<GearSelectorSanityProductsData> => {
  const query = createGearSelectorProductsQuery(
    answers,
    gearSelectorType,
    localeId,
  )

  if (!query) throw new Error('Could not create query.')
  return await getClient(preview).fetch(query)
}
