import { Button, Link, ProductCard } from '@liftfoils/components'
import { ArrowRightGraySmall, MobileDrawerClose } from '@liftfoils/icons'
import { SiteConfiguration } from '@liftfoils/models'
import {
  getSearchResults,
  getSearchSuggestions,
} from '@liftfoils/services/shopify-service'
import {
  ShopifyResolvedProductBasic,
  ShopifySearchSuggestionFieldsFragment,
} from '@liftfoils/shopify-sdk'
import { styled } from '@liftfoils/styles'
import debounce from 'lodash.debounce'
import { useRouter } from 'next/router'
import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchInput } from './SearchInput'

const TRANSITION_TIME = 200

const Wrap = styled('div', {
  display: 'grid',
})

const TopWrap = styled('div', {
  display: 'grid',
  justifyContent: 'space-between',
  alignItems: 'center',
  gridTemplateColumns: '1fr auto',
  borderBottom: '1px solid $gray100',
  px: '$8',
  height: 76,
})

const SearchActions = styled('div', {
  display: 'flex',
  gap: 16,
})

const ClearButton = styled(Button, {
  lift_font: 'body03',
  color: '$gray300',
  padding: '8px 10px',
  '&:hover, &:focus': {
    color: '$gray500',
  },

  '@md': {
    padding: '10px 16px',
  },
})

const BottomWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$9',
  padding: '$9 $8',
  '@md': {
    padding: '$10 $8 $11 $8',
    gap: '$0',
    gridTemplateColumns: 'repeat(5,1fr)',
  },
})
const Suggestions = styled('div', {
  gridColumn: 1,
  gridRow: 1,
  transition: `opacity ${TRANSITION_TIME}ms, visibility ${TRANSITION_TIME}ms`,
  variants: {
    hidden: {
      true: {
        opacity: 0,
        visibility: 'hidden',
      },
    },
  },
})
const Results = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',

  gap: '$6',
  '@md': {
    gridColumn: '2 / 6',
    display: 'grid',
    gridTemplateColumns: 'repeat(4,1fr)',
  },
})
const SuggestionsTitle = styled('p', {
  color: '$gray500_06',
  lift_font: 'body03',
  mb: '$7',
})
const SuggestionsList = styled('ul', {
  listStyle: 'none',
  display: 'grid',
  alignItems: 'start',
  gap: '$7',
})

const StyledLinkText = styled('span', {
  color: '$gray500',
  lift_font: 'body03',
  marginLeft: '$5',
})

const AllResultsButtonWrapper = styled('div', {
  display: 'grid',
  placeItems: 'center',
  '@md': {
    gridColumn: '2 / 6',
    marginTop: 44,
  },
})

export const SearchModalContent: FC<{
  closeSearch: () => void
  searchConfig: SiteConfiguration['search']
}> = ({ closeSearch, searchConfig }) => {
  const { t } = useTranslation('search')
  const router = useRouter()

  const [searchInputValue, setSearchInputValue] = useState('')
  const [productsResults, setProductsResults] = useState<
    ShopifyResolvedProductBasic[] | undefined
  >()
  const [suggestionsResults, setSuggestionsResults] = useState<
    ShopifySearchSuggestionFieldsFragment[] | undefined
  >()
  const searchInputRef = useRef<HTMLInputElement>(null)
  const currentFetchSearchResultsDebounced = useRef<any>()

  const handleSearchResetRequest = () => {
    setSearchInputValue('')
    searchInputRef.current?.focus()
  }

  async function fetchShopifySuggestions(value: string) {
    const suggestionsResult = await getSearchSuggestions(
      { query: value },
      router.locale,
    )
    return suggestionsResult.queries
  }

  async function fetchShopifyProducts(value: string) {
    const searchResult = await getSearchResults(
      { query: value, count: 4 },
      router.locale,
    )
    return searchResult.products
  }

  const debouncedFetchSearchResults = debounce(async (value) => {
    const [shopifyProductsResult, shopifySuggestionsResult] =
      await Promise.allSettled([
        fetchShopifyProducts(value),
        fetchShopifySuggestions(value),
      ])

    if (shopifyProductsResult.status === 'fulfilled') {
      setProductsResults(shopifyProductsResult.value)
    }

    if (shopifySuggestionsResult.status === 'fulfilled') {
      setSuggestionsResults(shopifySuggestionsResult.value)
    }
  }, 250)

  const handleSearchChange = (value: string) => {
    setSearchInputValue(value)

    if (currentFetchSearchResultsDebounced.current) {
      currentFetchSearchResultsDebounced.current.cancel()
    }

    if (value.length > 0) {
      currentFetchSearchResultsDebounced.current =
        debouncedFetchSearchResults(value)
    } else {
      setProductsResults([])
      setSuggestionsResults([])
    }
  }

  const isSearchValueEmpty = !searchInputValue || searchInputValue.length === 0

  return (
    <Wrap>
      <TopWrap>
        <SearchInput
          value={searchInputValue}
          onResetRequest={handleSearchResetRequest}
          onChange={handleSearchChange}
          onSubmit={(query) => {
            router.push(`/search?query=${query}`)
          }}
          ref={searchInputRef}
        />
        <SearchActions>
          {searchInputValue.length > 0 && (
            <ClearButton
              onClick={() => {
                setSearchInputValue('')
                setProductsResults([])
                setSuggestionsResults([])
                searchInputRef.current?.focus()
              }}
            >
              Clear
            </ClearButton>
          )}
          <Button onClick={closeSearch}>
            <MobileDrawerClose />
          </Button>
        </SearchActions>
      </TopWrap>

      <BottomWrap>
        <Suggestions
          hidden={
            suggestionsResults !== undefined && suggestionsResults.length > 0
          }
        >
          <SuggestionsTitle>{searchConfig?.title}</SuggestionsTitle>
          <SuggestionsList>
            {searchConfig?.links?.map((link) => (
              <li key={`suggestion-${link.title}`}>
                <Link {...link.link}>
                  <ArrowRightGraySmall />
                  <StyledLinkText>{link.title}</StyledLinkText>
                </Link>
              </li>
            ))}
          </SuggestionsList>
        </Suggestions>

        <Suggestions
          hidden={!suggestionsResults || suggestionsResults.length === 0}
        >
          <div>
            <SuggestionsTitle>{t('searchSuggestions')}</SuggestionsTitle>
            <SuggestionsList>
              {suggestionsResults &&
                suggestionsResults.map((suggestion) => (
                  <li key={`suggestion-${suggestion.text}`}>
                    <Button onClick={() => handleSearchChange(suggestion.text)}>
                      <ArrowRightGraySmall />
                      <StyledLinkText>{suggestion.text}</StyledLinkText>
                    </Button>
                  </li>
                ))}
            </SuggestionsList>
          </div>
        </Suggestions>
        <Results>
          {productsResults &&
            !isSearchValueEmpty &&
            productsResults?.map(
              (result) => result && <ProductCard product={result} />,
            )}
        </Results>
        {productsResults !== undefined && !isSearchValueEmpty && (
          <AllResultsButtonWrapper>
            <Link
              appearance="solidTeal500"
              size="medium"
              href={`/search?query=${searchInputValue}`}
            >
              Show all results
            </Link>
          </AllResultsButtonWrapper>
        )}
      </BottomWrap>
    </Wrap>
  )
}
