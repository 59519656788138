import { styled } from '@liftfoils/styles'
import { FC } from 'react'
import { PromoBarType } from '@liftfoils/models'
import { PortableText } from '@liftfoils/components'
import { useAccount } from '@liftfoils/services/shopify-service'

const Root = styled('div', {
  position: 'relative',
  zIndex: 11,
  height: '$promoBarHeight',
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$teal500',
  color: '$white',
  lift_font: 'body03',
})

const Description = styled('div', {
  px: '$7',
  a: {
    textDecoration: 'underline',
  },
})

export const PROMO_BAR_HEIGHT = 40
export const PromoBar: FC<{
  promoBarConfig: PromoBarType
}> = ({ promoBarConfig }) => {
  const b2bDescription = promoBarConfig.b2bDescription
  const description = promoBarConfig.description
  const { account } = useAccount()

  return (
    <Root>
      <Description>
        <PortableText
          value={
            account?.companyLocationId && b2bDescription
              ? b2bDescription
              : description
          }
        />
      </Description>
    </Root>
  )
}
