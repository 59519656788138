import { Button } from '@liftfoils/components'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@liftfoils/styles'

const StyledButton = styled(Button, {
  svg: {
    marginLeft: '0.25em',
    height: '1em',
    width: 'auto',
    fill: 'currentColor',
  },
})
const Text = styled('div', {
  lift_font: 'body03',
  color: '$gray300',
})

export const CopyLink: FC<{ url: string }> = ({ url }) => {
  const { t } = useTranslation('cart')
  const [isCopied, setIsCopied] = useState(false)
  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }
  const handleCopyClick = () => {
    copyTextToClipboard(url).then(() => {
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 3000)
    })
  }
  return (
    <div>
      {isCopied ? (
        <Text>{t('linkCopied')}</Text>
      ) : (
        <StyledButton appearance={'grayUnderline'} onClick={handleCopyClick}>
          {t('copyLink')}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
          </svg>
        </StyledButton>
      )}
    </div>
  )
}
