import {
  createShopifySdk,
  formatShopifyProductBasic,
  formatShopifyVariantBasic,
  ShopifyBuyerInput,
  ShopifyCountryCode,
  ShopifyResolvedProductBasic,
  ShopifyResolvedVariantBasic,
} from '@liftfoils/shopify-sdk'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { createStorefrontApiClient } from './createStorefrontApiClient'

export const getNodesWithBuyerContext = async (
  ids: Array<string>,
  locale: string,
  buyer?: ShopifyBuyerInput,
) => {
  const [countryCode, lang] = getLocaleRegionIdFromPath(locale) as [
    ShopifyCountryCode,
    string,
  ]

  const client = createStorefrontApiClient(lang)

  return await createShopifySdk(client)
    .getNodesWithBuyerContext({
      ids,
      countryCode,
      buyer,
    })
    .then(({ data }) => {
      return data.nodes.reduce<
        Array<ShopifyResolvedVariantBasic | ShopifyResolvedProductBasic>
      >((acc, val) => {
        if (val?.__typename === 'ProductVariant') {
          return [...acc, formatShopifyVariantBasic(val)]
        }

        if (val?.__typename === 'Product') {
          return [...acc, formatShopifyProductBasic(val)]
        }

        return acc
      }, [])
    })
}
