import { formatShopifyImage } from './formatShopifyImage'
import { ShopifyVariantBasicFieldsFragment } from '../generated/schemaSdk'
import { formatMetafields } from './formatMetafields'
import { ShopifyResolvedMediaItemPayload } from './formatShopifyMedia'
//import { formatProductMetafields } from './formatProductMetafields'
type VariantBasicMetafields = {
  images?: ShopifyResolvedMediaItemPayload[]
}
export const formatShopifyVariantBasic = (
  variant: ShopifyVariantBasicFieldsFragment,
) => ({
  ...variant,
  image: variant.image ? formatShopifyImage(variant.image) : null,
  product: {
    ...variant.product,
    //metafields: formatProductMetafields(variant.product.metafields),
  },
  __typename: 'ResolvedVariantBasic',
  metafields: formatMetafields<VariantBasicMetafields>(variant.metafields),
})

export type ShopifyResolvedVariantBasic = ReturnType<
  typeof formatShopifyVariantBasic
>
