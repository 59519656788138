import { MediaFieldType } from '@liftfoils/models'
import { getClient } from '@liftfoils/services/sanity-service'
import imageUrlBuilder from '@sanity/image-url'

import { shopifyImageLoader } from './shopifyImageLoader'

const builder = imageUrlBuilder(getClient(false))

export const seoImageLoader = (
  media?: MediaFieldType,
  altText?: string,
): { url: string; width: number; height: number; alt?: string } | null => {
  const type = media?.mediaPayload?.type
  if (!media?.mediaPayload) {
    return null
  }
  if (!type) {
    return null
  }

  const alt = altText || media.alt
  const width = 1200
  const height = 630
  switch (type) {
    case 'sanity-image':
      if (!media.mediaPayload.image?.asset) {
        return null
      }
      return {
        url: builder
          .image(media.mediaPayload.image)
          .width(width)
          .height(height)
          .url(),
        width,
        height,
        alt,
      }
    case 'shopify-image':
      if (!media.mediaPayload.image.src) {
        return null
      }
      return {
        url: shopifyImageLoader({
          src: media.mediaPayload.image.src,
          width,
          height,
        }),
        width,
        height,
        alt,
      }
    default:
      return null
  }
}
