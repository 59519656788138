import * as Sentry from '@sentry/nextjs'

export const logger = {
  /**
   * Sends error to sentry. The message param should be either string or Error type
   */
  error: (message: string | Error | unknown) => {
    console.error(message)
    if (typeof message === 'string') {
      if (process.env['CONTEXT'] !== 'production') {
        // console.log('[logger]', message)
      }
      try {
        throw new Error(message)
      } catch (e) {
        Sentry.captureException(e)
      }
      return
    }

    Sentry.captureException(message)
  },
}
