import { FC } from 'react'

export const ViewAnatomyIcon: FC = () => (
  <svg width="17" height="16" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.323242 0.412248V-0.00012207H7.35138V7.02677H0.323242V0.412248ZM9.10863 0.412248V-0.00012207H16.1357V7.02677H9.10863V0.412248ZM1.15059 0.825946H6.52398V6.2007H1.15059V0.825946ZM15.3094 0.825946H9.93497V6.2007H15.3094V0.825946ZM0.323242 8.78449V9.19818V15.8127H7.35138V8.78449H0.323242ZM9.10863 12.2979C9.10863 10.3622 10.6863 8.78449 12.6221 8.78449C14.5579 8.78449 16.1357 10.3622 16.1357 12.2979C16.1357 14.2336 14.5579 15.8127 12.6221 15.8127C10.6863 15.8127 9.10863 14.2336 9.10863 12.2979ZM1.15059 9.61188H6.52398V14.9853H1.15059V9.61188ZM15.3094 12.2979C15.3094 10.8091 14.1111 9.61188 12.6221 9.61188C11.1332 9.61188 9.93497 10.8091 9.93497 12.2979C9.93497 13.7867 11.1332 14.9853 12.6221 14.9853C14.1111 14.9853 15.3094 13.7867 15.3094 12.2979Z"
    />
  </svg>
)
