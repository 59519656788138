import { keyframes, styled } from '@liftfoils/styles'
import { FC, MouseEventHandler } from 'react'
import { VideoControlsPositionType } from '../models/MediaProps'
import { Play, Pause, SoundOn, SoundOff } from '@liftfoils/icons'

type VideoControlButtonsProps = {
  isPlaying?: boolean
  muted?: boolean
  isLoading?: boolean
  withSoundControl?: boolean
  controlsPosition?: VideoControlsPositionType
  onRequestPause: MouseEventHandler<HTMLButtonElement>
  onRequestPlay: MouseEventHandler<HTMLButtonElement>
  onRequestUnmute: MouseEventHandler<HTMLButtonElement>
  onRequestMute: MouseEventHandler<HTMLButtonElement>
}

const ANIM_DURATION = 300

const ControlWrapper = styled('div', {
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  padding: '$7',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'none',
  zIndex: 10,
  pointerEvents: 'none',
  variants: {
    controlsPosition: {
      top: {
        gridTemplateRows: 'auto 1fr',
      },
      bottom: {
        gridTemplateRows: '1fr auto',
      },
      none: {
        display: 'none',
      },
    },
  },
})

const SoundControl = styled('div', { position: 'relative' })
const PlayControl = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'end',
  gridColumn: '2',
})

const ControlButton = styled('button', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '$9',
  height: '$9',
  background: 'none',
  borderRadius: '$rMax',
  zIndex: 10,
  pointerEvents: 'all',

  '&:hover': {
    cursor: 'pointer',
  },

  '&:focus-visible': {
    outline: '$teal500_05 solid 3px',
  },
})

const SpinnerWrap = styled('div', {
  position: 'relative',
  gridColumn: '1/3',
  variants: {
    position: {
      top: {
        gridRow: '2',
      },
      bottom: {
        gridRow: '1',
      },
      none: {},
    },
  },
})

const rotate = keyframes({
  '0%': { transform: 'rotate(0)' },
  '100%': { transform: 'rotate(360deg)' },
})

const Spinner = styled('span', {
  gridArea: '1/1',
  color: '$white',
  display: 'block',
  alignSelf: 'center',
  justifySelf: 'center',
  content: '',
  width: '$9',
  height: '$9',
  margin: 'auto',
  border: '2px solid $gray500_02',
  borderTopColor: 'currentColor',
  borderRadius: '50%',
  opacity: 0,
  transitionProperty: 'opacity',
  variants: {
    isLoading: {
      true: {
        opacity: 1,
        transitionDuration: `${ANIM_DURATION}ms`,
        transitionDelay: `${ANIM_DURATION * 2}ms`,
      },
      false: {
        opacity: 0,
        transitionDuration: `${ANIM_DURATION / 2}ms`,
        transitionDelay: `${ANIM_DURATION / 2}ms`,
      },
    },
  },
  animation: `${rotate} 1s linear infinite`,
})

export const VideoControlButtons: FC<VideoControlButtonsProps> = ({
  isPlaying,
  isLoading,
  muted,
  onRequestPause,
  onRequestPlay,
  onRequestUnmute,
  onRequestMute,
  controlsPosition = 'none',
  withSoundControl = false,
}) => {
  if (controlsPosition === 'none') return null
  return (
    <ControlWrapper controlsPosition={controlsPosition}>
      <SpinnerWrap position={controlsPosition}>
        <Spinner isLoading={isLoading} />
      </SpinnerWrap>
      {withSoundControl && (
        <SoundControl>
          {muted ? (
            <ControlButton onClick={onRequestUnmute} aria-label="unmute">
              <SoundOff />
            </ControlButton>
          ) : (
            <ControlButton onClick={onRequestMute} aria-label="mute">
              <SoundOn />
            </ControlButton>
          )}
        </SoundControl>
      )}
      <PlayControl>
        {isPlaying ? (
          <ControlButton onClick={onRequestPause} aria-label="pause">
            <Pause />
          </ControlButton>
        ) : (
          <ControlButton onClick={onRequestPlay} aria-label="play">
            <Play />
          </ControlButton>
        )}
      </PlayControl>
    </ControlWrapper>
  )
}
