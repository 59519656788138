import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { createStorefrontApiClient } from './createStorefrontApiClient'
import { ShopifyInstanceId } from '@liftfoils/configs'
import {
  ShopifyCountryCode,
  ShopifyMutationCartLinesAddArgs,
  ShopifyMutationCartLinesUpdateArgs,
  ShopifyResolvedCart,
  createShopifySdk,
  formatShopifyCart,
  ShopifyMutationCartBuyerIdentityUpdateArgs,
} from '@liftfoils/shopify-sdk'

type CartClientProps = {
  cartId: string | null
  locale?: string
  shopifyInstance?: ShopifyInstanceId
}
type CartClientRes = Promise<ShopifyResolvedCart>

export type CartClient = {
  create: (email?: string) => CartClientRes
  fetch: (cartId: string) => CartClientRes
  addLines: (lines: ShopifyMutationCartLinesAddArgs['lines']) => CartClientRes
  updateLines: (
    lines: ShopifyMutationCartLinesUpdateArgs['lines'],
  ) => CartClientRes
  removeLines: (lineIds: string[]) => CartClientRes
  updateNote: (note: string) => CartClientRes
  updateDiscountCodes: (discountCodes: string[]) => CartClientRes
  updateCartBuyerIdentity: (
    args: ShopifyMutationCartBuyerIdentityUpdateArgs,
  ) => CartClientRes
}
export const createCartClient = ({
  locale,
  cartId,
}: CartClientProps): CartClient => {
  const [countryCode, language] = getLocaleRegionIdFromPath(locale) as [
    ShopifyCountryCode,
    string,
  ]

  const client = createStorefrontApiClient(language)
  const sdk = createShopifySdk(client)

  return {
    create: (email) =>
      sdk
        .cartCreate({ cartInput: { buyerIdentity: { countryCode, email } } })
        .then((res) => formatShopifyCart(res.data.cartCreate?.cart)),
    updateNote: async (note) => {
      if (!cartId) {
        throw new Error('[createCartClient] updateNote called without cartId')
      }
      return sdk
        .cartNoteUpdate({
          cartId,
          note,
          countryCode,
        })
        .then((res) => formatShopifyCart(res.data.cartNoteUpdate?.cart))
    },
    addLines: async (lines) => {
      if (!cartId) {
        throw new Error('[createCartClient] addLines called without cartId')
      }
      return sdk
        .cartLinesAdd({ cartId, lines, countryCode })
        .then((res) => formatShopifyCart(res.data.cartLinesAdd?.cart))
    },
    updateLines: async (lines) => {
      if (!cartId) {
        throw new Error('[createCartClient] updateLines called without cartId')
      }
      return sdk
        .cartLinesUpdate({ cartId, lines, countryCode })
        .then((res) => formatShopifyCart(res.data.cartLinesUpdate?.cart))
    },
    removeLines: async (lineIds) => {
      if (!cartId) {
        throw new Error('[createCartClient] removeLines called without cartId')
      }
      return sdk
        .cartLinesRemove({ cartId, lineIds, countryCode })
        .then((res) => formatShopifyCart(res.data.cartLinesRemove?.cart))
    },
    fetch: async (specificCartId) =>
      sdk
        .getCart({
          cartId: specificCartId,
          countryCode,
        })
        .then((res) => formatShopifyCart(res.data.cart)),

    updateDiscountCodes: async (discountCodes) => {
      if (!cartId) {
        throw new Error(
          '[createCartClient] updateDiscountCodes called without cartId',
        )
      }
      return sdk
        .cartDiscountCodesUpdate({
          cartId,
          discountCodes,
          countryCode,
        })
        .then((res) =>
          formatShopifyCart(res.data.cartDiscountCodesUpdate?.cart),
        )
    },

    updateCartBuyerIdentity: async ({ cartId, buyerIdentity }) => {
      return sdk
        .cartBuyerIdentityUpdate({ cartId, buyerIdentity })
        .then((res) => {
          return formatShopifyCart(res.data.cartBuyerIdentityUpdate?.cart)
        })
    },
  }
}
