import {
  createShopifySdk,
  formatShopifyProductBasic,
  formatShopifyVariantBasic,
  ShopifyCountryCode,
  ShopifyData,
  ShopifyDataSectionIds,
  ShopifyGetNodesByIdQueryVariables,
  ShopifyResolvedProductBasic,
  ShopifyResolvedVariantBasic,
} from '@liftfoils/shopify-sdk'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { LocaleId } from '@liftfoils/configs'
import { createStorefrontApiClient } from './createStorefrontApiClient'
import { PageBuilderSection } from '@liftfoils/models'

const __withoutNulls = (
  shopifyIds: ShopifyDataSectionIds,
  shopifyData: ShopifyGetNodesByIdQueryVariables,
) =>
  Object.entries(shopifyIds).reduce((acc, val) => {
    const [key, value] = val
    const prevValues = shopifyData[key as keyof ShopifyDataSectionIds] || []
    return {
      ...acc,
      [key]: [...prevValues, ...value.filter((id) => !!id)],
    }
  }, {})

export const __getShopifyDataByIds = async (
  variables: ShopifyGetNodesByIdQueryVariables,
  shopifyClientLocaleId: LocaleId,
) => {
  const shopifyClient = createStorefrontApiClient(shopifyClientLocaleId)
  return await createShopifySdk(shopifyClient)
    .getNodesById(variables)
    .then((res) => {
      return {
        productBasic: res.data.productBasic.reduce((acc, val) => {
          return val && val.__typename === 'Product'
            ? {
                ...acc,
                [val.id]: formatShopifyProductBasic(val),
              }
            : acc
        }, {} as Record<string, ShopifyResolvedProductBasic>),
        variantBasic: res.data.variantBasic.reduce((acc, val) => {
          return val && val.__typename === 'ProductVariant'
            ? {
                ...acc,
                [val.id]: formatShopifyVariantBasic(val),
              }
            : acc
        }, {} as Record<string, ShopifyResolvedVariantBasic>),
      }
    })
}

export const getShopifyDataFromPageBuilder = async (
  pageBuilder: PageBuilderSection[] | null,
  locale: string,
): Promise<ShopifyData> => {
  if (!pageBuilder)
    return {
      productBasic: {},
      variantBasic: {},
    }

  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)

  const variables = pageBuilder.reduce<ShopifyGetNodesByIdQueryVariables>(
    (acc, section) => {
      if ('shopifyIds' in section && !!section.shopifyIds) {
        return {
          ...acc,
          ...__withoutNulls(section.shopifyIds, acc),
        }
      }

      return acc
    },
    {
      productBasic: [],
      variantBasic: [],
      collectionBasic: [],
      regionId: regionId as ShopifyCountryCode,
    },
  )

  return await __getShopifyDataByIds(variables, localeId)
}

export const getShopifyDataFromArrayIds = async (
  ids: string[],
  locale: string,
): Promise<ShopifyData> => {
  if (!ids || ids.length == 0)
    return {
      productBasic: {},
      variantBasic: {},
    }

  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)

  const variables = {
    productBasic: ids,
    variantBasic: ids,
    collectionBasic: [] as string[],
    regionId: regionId as ShopifyCountryCode,
  }

  return await __getShopifyDataByIds(variables, localeId)
}
