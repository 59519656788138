import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { GearType } from './getGearSelectorContentByPath'

export const getGearSelectorPathByType = async (
  gearSelectorType: GearType,
  preview = false,
): Promise<string | false> => {
  const query = groq`*[!(_id in path('drafts.**')) && _type == "classicFoilGearSelector" && gearSelectorType == "${gearSelectorType}"][0].path.current`

  const path = await getClient(preview).fetch(query)
  if (!path) return false

  return path
}
