export const Pause = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.88889 4H6.96296V12H4.88889V4ZM9.03703 4H11.1111V12H9.03703V4Z"
        fill="white"
      />
    </svg>
  )
}
