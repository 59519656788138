export const screenSizes = {
  sm: 430,
  md: 769,
  lg: 1000,
  xl: 1300,
  xxl: 1900,
}

export type ScreenSize = keyof typeof screenSizes

export type Breakpoint = { value: number; name: string }

export const BREAKPOINTS_ARRAY = Object.keys(screenSizes).map((name) => ({
  name: name,
  value: screenSizes[name as keyof typeof screenSizes],
}))

export const breakpoints: Record<string, string> = Object.entries(
  screenSizes,
).reduce((acc, [name, size]) => {
  return {
    ...acc,
    [name]: `(min-width: ${size.toString()}px)`,
  }
}, {})
