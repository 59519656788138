import {
  createShopifySdk,
  ShopifyCountryCode,
  ShopifyGetSearchSuggestionsQueryVariables,
} from '@liftfoils/shopify-sdk'
import { createStorefrontApiClient } from './createStorefrontApiClient'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'

export const getSearchSuggestions = async (
  variables: ShopifyGetSearchSuggestionsQueryVariables,
  locale?: string,
) => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
  const shopifyClient = createStorefrontApiClient(localeId)

  return await createShopifySdk(shopifyClient)
    .getSearchSuggestions({
      ...variables,
      regionId: regionId as ShopifyCountryCode,
    })
    .then((res) => {
      if (!res.data) {
        return {
          queries: [],
        }
      }

      return {
        queries: res.data.predictiveSearch?.queries || [],
      }
    })
}
