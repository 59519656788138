import {
  isSupportedRegion,
  DEFAULT_REGION_ID,
  DEFAULT_LOCALE_ID,
  RegionId,
  LocaleId,
  isSupportedRegionLocale,
  regions,
} from '@liftfoils/configs'
import { decodeLocaleString } from './decodeLocaleString'

export const getLocaleRegionIdFromPath = (
  localeRegionString?: string,
): [regionId: RegionId, localeId: LocaleId] => {
  const { regionId: givenRegionId, localeId: givenLocaleId } =
    decodeLocaleString(localeRegionString)

  const regionId: RegionId = (() => {
    if (!givenRegionId || !isSupportedRegion(givenRegionId)) {
      return DEFAULT_REGION_ID
    }
    return givenRegionId
  })()

  // regionId is always "supported" since we resolve it first
  const localeId: LocaleId = (() => {
    if (!givenLocaleId) {
      return (
        regions.find(({ id }) => id === regionId)?.defaultLocale ||
        DEFAULT_LOCALE_ID
      )
    }

    // locale is set but not supported for given region
    if (givenLocaleId && !isSupportedRegionLocale(regionId, givenLocaleId)) {
      const tes =
        regions.find(({ id }) => id === regionId)?.defaultLocale ||
        DEFAULT_LOCALE_ID
      return tes
    }

    return givenLocaleId
  })()

  return [regionId, localeId]
}
