import React from 'react'
import { styled } from '@stitches/react'

const Root = styled('svg', {})

export const ArrowRight = () => {
  return (
    <Root
      width="12"
      height="10"
      viewBox="0 0 12 10"
      xmlns="http://www.w3.org/2000/svg"
      fill={'currentColor'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.4013 9.22659C7.32447 9.30343 7.19641 9.30343 7.11958 9.22659L6.40247 8.50923C6.32563 8.43239 6.32563 8.30434 6.40247 8.22776L9.27091 5.35906L0.204579 5.35906C0.102135 5.35906 -5.34058e-05 5.25687 -5.34058e-05 5.15443V4.12998C-5.34058e-05 4.02754 0.102135 3.92509 0.204579 3.92509L9.27091 3.92509L6.40247 1.05665C6.32563 0.979817 6.32563 0.851761 6.40247 0.774928L7.11958 0.0578171C7.19641 -0.0192724 7.32447 -0.0192724 7.4013 0.0578171L11.832 4.48854C11.9089 4.56537 11.9089 4.69343 11.832 4.77026L7.4013 9.22659Z"
      />
    </Root>
  )
}

export const ArrowRightGraySmall = () => {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Arrow">
        <path
          id="Fill 1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.15728 7.81324C6.09337 7.87714 5.98685 7.87714 5.92294 7.81324L5.32646 7.21654C5.26255 7.15263 5.26255 7.04611 5.32646 6.98242L7.71239 4.59627H0.171134C0.0859222 4.59627 0.000923157 4.51127 0.000923157 4.42606V3.57394C0.000923157 3.48873 0.0859222 3.40352 0.171134 3.40352H7.71239L5.32646 1.01758C5.26255 0.953671 5.26255 0.847156 5.32646 0.783247L5.92294 0.186763C5.98685 0.122641 6.09337 0.122641 6.15728 0.186763L9.84269 3.87218C9.9066 3.93609 9.9066 4.04261 9.84269 4.10651L6.15728 7.81324Z"
          fill="#474646"
          fillOpacity="0.6"
        />
      </g>
    </svg>
  )
}
