import React from 'react'
import NextImage, { ImageProps } from 'next/legacy/image'
import { MediaSizesProps, buildSizes } from '../helpers/buildSizes'

export const Image: React.FunctionComponent<
  Omit<ImageProps, 'sizes'> & { sizes?: MediaSizesProps }
> = ({
  layout,
  alt,
  width,
  src,
  height,
  objectFit,
  sizes,
  priority,
  placeholder,
  loader,
  blurDataURL,
  objectPosition,
  lazyBoundary = '500px',
  className,
}) => {
  const isFillLayout = layout === 'fill'
  const imgSizes = buildSizes(sizes)
  return (
    <NextImage
      lazyBoundary={lazyBoundary}
      src={src}
      width={isFillLayout ? undefined : width}
      height={isFillLayout ? undefined : height}
      alt={alt}
      loader={loader}
      layout={layout}
      sizes={imgSizes}
      placeholder={placeholder}
      blurDataURL={blurDataURL}
      priority={priority}
      objectFit={isFillLayout ? objectFit ?? 'cover' : undefined}
      className={className}
      objectPosition={objectPosition}
    />
  )
}
