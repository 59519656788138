import { FC } from 'react'
import { ROUTE_BASE_PRODUCTS } from '@liftfoils/configs'
import Script from 'next/script'
import { ShopifyResolvedProductExtended } from '@liftfoils/shopify-sdk'

type ProductReviewsProps = {
  shopifyProduct: ShopifyResolvedProductExtended
}

declare global {
  interface Window {
    StampedFn: any
  }
}

export const ProductReviews: FC<ProductReviewsProps> = ({ shopifyProduct }) => {
  const productURL = `${process.env.NEXT_PUBLIC_SHOPIFY_US_DOMAIN}${ROUTE_BASE_PRODUCTS}/${shopifyProduct.handle}`

  const stampedApiKey = process.env.NEXT_PUBLIC_STAMPED_US_API_KEY
  const stampedSid = process.env.NEXT_PUBLIC_STAMPED_US_SID

  return (
    <>
      <div
        id="stamped-main-widget"
        data-product-id={shopifyProduct.id.replace(
          'gid://shopify/Product/',
          '',
        )}
        data-name={shopifyProduct.title}
        data-url={productURL}
        data-image-url={shopifyProduct.images?.[0]?.image.src ?? undefined}
        data-description={shopifyProduct.description}
        data-product-sku={shopifyProduct.handle}
      />
      {stampedApiKey && stampedSid && (
        <Script
          id={'stamped-script'}
          src="https://cdn1.stamped.io/files/widget.min.js"
          strategy="afterInteractive"
          onReady={() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            StampedFn.init({
              apiKey: stampedApiKey,
              sId: stampedSid,
            })
          }}
        />
      )}
    </>
  )
}
