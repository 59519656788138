import {
  ShopifyExternalVideoFieldsFragment,
  ShopifyMediaFieldsFragment,
  ShopifyVideoFieldsFragment,
} from '../generated/schemaSdk'
import { formatShopifyImage, ShopifyResolvedImage } from './formatShopifyImage'

const formatShopifyVideo = (
  input: ShopifyVideoFieldsFragment,
): { type: 'shopify-media-video'; video: ShopifyVideoFieldsFragment } => ({
  type: 'shopify-media-video',
  video: input,
})

const formatExternalVideo = (
  input: ShopifyExternalVideoFieldsFragment,
): {
  type: 'shopify-media-external-video'
  video: ShopifyExternalVideoFieldsFragment
} => ({
  type: 'shopify-media-external-video',
  video: input,
})

export const formatShopifyMedia = (media: ShopifyMediaFieldsFragment[]) =>
  media
    .map((item) => {
      switch (item.__typename) {
        case 'ExternalVideo': {
          return formatExternalVideo(item)
        }
        case 'Video': {
          return formatShopifyVideo(item)
        }
        case 'MediaImage': {
          return item.image ? formatShopifyImage(item.image) : null
        }
        // case 'Model3d': {
        //   // TODO
        //   return null
        // }
        default:
          return null
      }
    })
    .filter((m) => !!m)

export type ShopifyResolvedVideo = ReturnType<typeof formatShopifyVideo>
export type ShopifyResolvedExternalVideo = ReturnType<
  typeof formatExternalVideo
>

// TODO: replace with ReturnType<typeof formatShopifyMedia> - currently ReturnType also returns null value even when type guard is used.
export type ShopifyResolvedMediaItemPayload =
  | ShopifyResolvedVideo
  | ShopifyResolvedExternalVideo
  | ShopifyResolvedImage

export type ShopifyResolvedMedia = ShopifyResolvedMediaItemPayload[]
