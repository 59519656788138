import { styled } from '@liftfoils/styles'
import { useEffect, useRef } from 'react'

const Wrap = styled('div', {
  minHeight: '100vh',
  display: 'grid',
  placeItems: 'center',
})

export const CookieDeclaration = () => {
  const cookieDeclarationContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    /*
     * Cookiebot
     * This allows the cookie declaration to be loaded inside the page
     * Next Script does not allow script tags to load in the page's content
     * JSX loaded by regular script tag are not inserted into the DOM
     */
    const script = document.createElement('script')
    script.id = 'CookieDeclaration'
    script.type = 'text/javascript'
    script.async = true
    script.src =
      'https://consent.cookiebot.com/a30d8c2b-e0c9-4837-979c-4d708e2cafdd/cd.js'
    const targetElement = cookieDeclarationContainer.current

    if (targetElement) {
      targetElement.appendChild(script)
    }
  }, [])

  return (
    <Wrap>
      <div ref={cookieDeclarationContainer} id="cookiebot-declaration">
        {/*Cookiebot will insert declaration here*/}
      </div>
    </Wrap>
  )
}
