import {
  BUNDLE_ID_ADDITIONAL_PREFIX,
  BUNDLE_ID_BASE_PREFIX,
  BUNDLE_ID_KEY,
} from '@liftfoils/configs'
import {
  ShopifyResolvedCartLine,
  ShopifyResolvedVariantBasic,
} from '@liftfoils/shopify-sdk'

export const normalizeBundleId = (bundleId: string) => {
  return bundleId
    .replace(BUNDLE_ID_BASE_PREFIX, '')
    .replace(BUNDLE_ID_ADDITIONAL_PREFIX, '')
}

export const getCartLinesByBundleId: (
  cartLines: ShopifyResolvedCartLine[],
  bundleId: string,
) => ShopifyResolvedCartLine[] = (cartLines, bundleId) => {
  bundleId = normalizeBundleId(bundleId)
  return cartLines.reduce((acc: ShopifyResolvedCartLine[], line) => {
    const condition = line.attributes
      ?.find((a) => a.key === BUNDLE_ID_KEY)
      ?.value?.includes(bundleId)

    return condition ? [...acc, line] : acc
  }, [])
}

export const getVariantsByBundleId = (
  cartLines: ShopifyResolvedCartLine[],
  bundleId: string,
) => {
  bundleId = normalizeBundleId(bundleId)
  return cartLines.reduce((acc: ShopifyResolvedVariantBasic[], line) => {
    const condition = line.attributes
      ?.find((a) => a.key === BUNDLE_ID_KEY)
      ?.value?.includes(bundleId)
    return condition ? [...acc, line.merchandise] : acc
  }, [])
}

export const getLinesByBundleId = (
  cartLines: ShopifyResolvedCartLine[],
  bundleId: string,
) => {
  bundleId = normalizeBundleId(bundleId)
  return cartLines.reduce((acc: ShopifyResolvedCartLine[], line) => {
    const condition = line.attributes
      ?.find((a) => a.key === BUNDLE_ID_KEY)
      ?.value?.includes(bundleId)
    return condition ? [...acc, line] : acc
  }, [])
}

export const getBundleBaseCartLineByBundleId = (
  cartLines: ShopifyResolvedCartLine[],
  bundleId: string,
) => {
  bundleId = normalizeBundleId(bundleId)
  return cartLines.find(
    (line) =>
      line.attributes?.find((attr) => attr.key === BUNDLE_ID_KEY)?.value ===
      `${BUNDLE_ID_BASE_PREFIX}${bundleId}`,
  )
}

export const getBundleAdditionalCartLinesByBundleId = (
  cartLines: ShopifyResolvedCartLine[],
  bundleId: string,
) => {
  bundleId = normalizeBundleId(bundleId)
  return cartLines.filter(
    (line) =>
      line.attributes?.find((attr) => attr.key === BUNDLE_ID_KEY)?.value ===
      `${BUNDLE_ID_ADDITIONAL_PREFIX}${bundleId}`,
  )
}

export const getBundleId = (line: ShopifyResolvedCartLine) => {
  const rawId = line?.attributes?.find((a) => a.key === BUNDLE_ID_KEY)?.value
  if (!rawId) return undefined
  return normalizeBundleId(rawId)
}
