import {
  useState,
  createContext,
  FC,
  useContext,
  PropsWithChildren,
  useRef,
  useEffect,
} from 'react'
import {
  NAV_ENTERING_TIME,
  NAV_EXITING_TIME,
  NAV_DELAY_TIME,
  NAV_SWITCHING_TIME,
} from './header.config'
import { useRouter } from 'next/router'

export type NavigationAnimationStatus =
  | 'EXITED'
  | 'ENTERING'
  | 'ENTERED'
  | 'EXITING'
  | 'SWITCHING'

export type HeaderState = {
  activeTabId?: string
  lastSwitchedTabId?: string
  flyoutHeight: number
  navigationAnimationStatus: NavigationAnimationStatus
}

export type HeaderContextType = {
  handleOpen: (id: string, flyoutHeight: number) => void
  handleSwitch: (id: string, flyoutHeight: number) => void
  handleClose: () => void
  headerState: HeaderState
}

export const HeaderContext = createContext<HeaderContextType>({
  handleOpen: () => undefined,
  handleSwitch: () => undefined,
  handleClose: () => undefined,
  headerState: {
    flyoutHeight: 0,
    navigationAnimationStatus: 'EXITED',
  },
})

export const HeaderProvider: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter()

  const [headerState, setHeaderState] = useState<HeaderState>({
    flyoutHeight: 0,
    navigationAnimationStatus: 'EXITED',
  })

  // this timer is used to abort current animation while user made other action
  const transitionTimer = useRef<number | null>(null)
  const clearTransitionTimer = () =>
    transitionTimer.current && window.clearTimeout(transitionTimer.current)

  // this timer is to avoid flyout moving when user accidentally move mouse over nav item
  const delayTimer = useRef<number | null>(null)
  const clearDelayTimer = () =>
    delayTimer.current && window.clearTimeout(delayTimer.current)
  const withDelay = (callback: () => void) => {
    clearDelayTimer()
    delayTimer.current = window.setTimeout(callback, NAV_DELAY_TIME)
  }

  const handleClose: HeaderContextType['handleClose'] = () => {
    clearTransitionTimer()

    withDelay(() => {
      setHeaderState((prevState) => ({
        ...prevState,
        navigationAnimationStatus: 'EXITING',
        flyoutHeight: 0,
      }))
      transitionTimer.current = window.setTimeout(() => {
        setHeaderState({
          flyoutHeight: 0,
          navigationAnimationStatus: 'EXITED',
          activeTabId: undefined,
        })
      }, NAV_EXITING_TIME)
    })
  }

  const handleOpen: HeaderContextType['handleOpen'] = (id, flyoutHeight) => {
    clearTransitionTimer()
    withDelay(() => {
      setHeaderState({
        flyoutHeight,
        navigationAnimationStatus: 'ENTERING',
        activeTabId: id,
      })
      transitionTimer.current = window.setTimeout(() => {
        setHeaderState({
          flyoutHeight,
          navigationAnimationStatus: 'ENTERED',
          activeTabId: id,
        })
      }, NAV_ENTERING_TIME)
    })
  }

  const handleSwitch: HeaderContextType['handleSwitch'] = (
    id,
    flyoutHeight,
  ) => {
    clearTransitionTimer()
    withDelay(() => {
      setHeaderState({
        flyoutHeight,
        navigationAnimationStatus: 'SWITCHING',
        lastSwitchedTabId: headerState.activeTabId,
        activeTabId: id,
      })
      transitionTimer.current = window.setTimeout(() => {
        setHeaderState((prevState) => ({
          ...prevState,
          navigationAnimationStatus: 'ENTERED',
        }))
      }, NAV_SWITCHING_TIME)
    })
  }

  useEffect(() => {
    if (headerState.activeTabId) {
      document.addEventListener('scroll', handleClose)
    } else {
      document.removeEventListener('scroll', handleClose)
    }
    return () => {
      document.removeEventListener('scroll', handleClose)
    }
  }, [headerState.activeTabId])

  useEffect(() => {
    router.events.on('routeChangeStart', handleClose)
    return () => {
      router.events.off('routeChangeStart', handleClose)
    }
  }, [router.events])

  return (
    <HeaderContext.Provider
      value={{
        handleOpen,
        handleSwitch,
        handleClose,
        headerState,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export const useHeaderContext = (): HeaderContextType => {
  return useContext(HeaderContext)
}
