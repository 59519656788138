import { groq } from 'next-sanity'
import { getClient } from './getClient'

export const getEFoilPaths = async (
  localeRegionString: string,
  preview = false,
): Promise<{ shopifyHandle: string }[]> => {
  const query = groq`*[!(_id in path('drafts.**')) && _type == "efoil" ]{
    "shopifyHandle": mainProduct -> store.slug.current
  }`

  return await getClient(preview).fetch(query)
}
