import { FC, useEffect, useState } from 'react'
import { Button, ProductVariantPrice, Loader } from '@liftfoils/components'
import { styled } from '@liftfoils/styles'
import { useCart } from '../useCart'
import { useTranslation } from 'react-i18next'
import { useClientVariants } from '@liftfoils/services/shopify-service'
import { CartNotificationModal } from './CartNotificationModal'
import { GTMAddToCart } from '@liftfoils/services/gtm-service'
import { ShopifyResolvedVariantBasic } from '@liftfoils/shopify-sdk'

const ErrorMessage = styled('p', {
  lift_font: 'body06',
  color: '$red500',
  textAlign: 'right',
  mt: '$4',
})

const ButtonWrap = styled('div', {
  display: 'grid',
  color: 'white',
})

const Bull = styled('span', {})

type AddToCartButtonProps = {
  quantity?: number
  productVariant?: ShopifyResolvedVariantBasic
  attributes?: { key: string; value: string }[]
}

export const AddToCartButton: FC<AddToCartButtonProps> = ({
  productVariant,
  quantity,
  attributes,
}) => {
  const [errorNotification, setErrorNotification] = useState<string | null>(
    null,
  )
  const [cartModalOpen, setCartModalOpen] = useState<boolean>(false)

  const clientVariantsData = useClientVariants(
    productVariant ? [productVariant.id] : [],
  )

  const incomingProductVariant =
    (clientVariantsData?.variants && clientVariantsData.variants[0]) ??
    undefined
  const { cart, addCartLines, processing } = useCart()

  const { t } = useTranslation('cart')

  const handleCloseModal = () => {
    setCartModalOpen(false)
  }
  const createErrorNotification = (errorNotification: string) => {
    setErrorNotification(errorNotification)
    setTimeout(() => {
      setErrorNotification(null)
    }, 10000)
  }

  const handleClick = async () => {
    if (!productVariant) {
      createErrorNotification(t('chooseVariant'))
      return
    }

    const parsedQuantity = quantity ?? 1

    const newCartLine = {
      merchandiseId: productVariant.id,
      quantity: parsedQuantity,
      attributes,
    }

    const addedLines = await addCartLines([newCartLine])

    if (addedLines) {
      GTMAddToCart(addedLines[0], parsedQuantity)
    }

    setCartModalOpen(true)
    setTimeout(() => {
      handleCloseModal()
    }, 6000)
  }

  useEffect(() => {
    if (productVariant && errorNotification) {
      setErrorNotification(null)
    }
  }, [productVariant?.id, errorNotification])

  const latestCartLine = cart?.lines && cart.lines[0]

  const isLoading = clientVariantsData.processing || processing
  const unavailable =
    (productVariant && clientVariantsData && !incomingProductVariant) ||
    incomingProductVariant?.availableForSale === false
  const disabled = isLoading || unavailable

  return (
    <>
      <ButtonWrap>
        <Button
          appearance={'solidTeal500'}
          size={'large'}
          disabled={disabled}
          onClick={handleClick}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {unavailable ? (
                t('productUnavailable')
              ) : (
                <>
                  {incomingProductVariant && (
                    <>
                      <ProductVariantPrice
                        price={incomingProductVariant.price}
                      />
                      <Bull>&bull;</Bull>
                    </>
                  )}
                  {t('addToCart')}
                </>
              )}
            </>
          )}
        </Button>
        {errorNotification && <ErrorMessage>{errorNotification}</ErrorMessage>}
      </ButtonWrap>
      {incomingProductVariant && cart && (
        <CartNotificationModal
          message={t('addedToCart')}
          isOpen={cartModalOpen}
          handleClose={handleCloseModal}
          checkoutUrl={cart.checkoutUrl}
          newCartLines={latestCartLine ? [latestCartLine] : undefined}
        />
      )}
    </>
  )
}
