import { useEffect, useState } from 'react'
import {
  createShopifySdk,
  formatShopifyProductBasic,
  ShopifyCountryCode,
  ShopifyResolvedProductBasic,
} from '@liftfoils/shopify-sdk'

import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { useRouter } from 'next/router'
import { useAccount } from '../account/CustomerAccountProvider'
import { createStorefrontApiClient } from '../createStorefrontApiClient'

export const useClientProducts = (ids: Array<string>) => {
  const [clientProducts, setClientProducts] = useState<
    ShopifyResolvedProductBasic[] | undefined
  >(undefined)
  const [isLoading, setLoading] = useState(false)
  const { locale } = useRouter()
  const { account } = useAccount()
  const [countryCode, language] = getLocaleRegionIdFromPath(locale) as [
    ShopifyCountryCode,
    string,
  ]

  const { companyLocationId, customerAccessToken } = account ?? {}

  const client = createStorefrontApiClient(language)
  const sdk = createShopifySdk(client)

  useEffect(() => {
    const refreshVariants = async () => {
      setClientProducts(undefined)

      const buyer =
        customerAccessToken && companyLocationId
          ? { customerAccessToken, companyLocationId }
          : undefined
      setLoading(true)
      const { data } = await sdk.getNodesWithBuyerContext({
        ids,
        countryCode,
        buyer,
      })

      const incomingVariants = data.nodes
        .map((v) =>
          v?.__typename === 'Product' ? formatShopifyProductBasic(v) : null,
        )
        .filter(Boolean) as ShopifyResolvedProductBasic[]

      setClientProducts(incomingVariants)
      setLoading(false)
    }
    refreshVariants()
  }, [locale, JSON.stringify(account), JSON.stringify(ids)])

  return {
    processing: isLoading,
    products: clientProducts,
  }
}
