import { groq } from 'next-sanity'
import { selectRegionalizedContent } from '../helpers/selectRegionalizedContent'
import { DEFAULT_SHOPIFY_INSTANCE_ID, LocaleId } from '@liftfoils/configs'
import { createPortableTextProjection } from './createPortableTextProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'

export const createProductPageConfigProjection = (localeId: LocaleId) => {
  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(localeId)

  // TODO cleanup instanceId
  const instanceId = DEFAULT_SHOPIFY_INSTANCE_ID
  return groq`*[_type == "productPageConfigRegionalized"] {
          "productPageConfig":${selectRegionalizedContent(instanceId)}->{
          ${coalesceLocaleField(
            'additionalText',
            localeId,
          )}[]${PORTABLE_TEXT_PROJECTION},
          ${coalesceLocaleField('priceDisclaimer', localeId)}
          }
        }[0].productPageConfig
  `
}
