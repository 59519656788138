import React, { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { Close } from '@liftfoils/icons'
import { Button } from '../Button/Button'

const ContentHeader = styled('div', {
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr auto',
  pl: '$8',
  background: 'white',
  borderBottom: '1px solid $gray200',
  h1: {
    lift_font: 'body02',
  },
  button: {
    borderTopRightRadius: '$r1',
  },
  variants: {
    isRounded: {
      true: {
        borderTopLeftRadius: '$r1',
        borderTopRightRadius: '$r1',
      },
    },
  },
})
const CloseButtonWrap = styled('div', {
  width: '$11',
  height: '$11',
  display: 'grid',
  placeItems: 'center',
})
type ModalHeaderProps = {
  title: string
  onRequestClose: (event: React.MouseEvent | React.KeyboardEvent) => void
  isRounded?: boolean
  className?: string
}
export const ModalHeader: FC<ModalHeaderProps> = ({
  title,
  isRounded = true,
  onRequestClose,
  className,
}) => {
  return (
    <ContentHeader isRounded={isRounded} className={className}>
      <h1>{title}</h1>
      <Button onClick={onRequestClose} appearance={'flex'}>
        <CloseButtonWrap>
          <Close />
        </CloseButtonWrap>
      </Button>
    </ContentHeader>
  )
}
