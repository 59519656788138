import { useEffect, useState } from 'react'
import { createStorefrontApiClient } from '@liftfoils/services/shopify-service'
import { gql } from 'graphql-request'
import { styled } from '@liftfoils/styles'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { useRouter } from 'next/router'

type ShopifyProductRes = {
  id: string
  title: string
  handle: string
  options: {
    name: string
    values: string[]
  }[]
  variants: {
    edges: {
      node: {
        title: string
        selectedOptions: {
          name: string
          value: string
        }[]
        sku: string
      }
    }[]
  }
}
type ShopifyProduct = {
  id: string
  title: string
  handle: string
  options: {
    name: string
    values: string[]
  }[]
  variants: {
    title: string
    selectedOptions: {
      name: string
      value: string
    }[]
    sku: string
  }[]
}
const QUERY = gql`
  fragment ProductFragment on Product {
    title
    handle
    options {
      name
      values
    }
    variants(first: 200) {
      edges {
        node {
          title
          selectedOptions {
            name
            value
          }
          weight
          weightUnit
          sku
          product {
            handle
          }
        }
      }
    }
  }
  query ($ids: [ID!]!) {
    nodes(ids: $ids) {
      ...ProductFragment
    }
  }
`
const IDS_QUERY = gql`
  query {
    products(first: 250) {
      edges {
        node {
          id
        }
      }
    }
  }
`
const Wrap = styled('div', {
  display: 'grid',
  u: {
    textDecoration: 'underline',
  },
})
const Row = styled('pre', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '$5',
  borderBottom: '1px solid $gray100',
  margin: 0,
  py: '$7',
  variants: {
    sticky: {
      true: {
        position: 'sticky',
        top: '0',
        pt: '$headerHeight',
        background: 'white',
        zIndex: 1,
      },
    },
  },
})
const Variants = styled('div', {
  display: 'grid',
  gridGap: '$5',
})
const Variant = styled('div', {
  background: '$gray100',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  alignItems: 'center',
  justifyContent: 'center',
  px: '$5',
  gridGap: '$7',
  variants: {
    valid: {
      false: {
        background: 'pink',
      },
    },
  },
})
const Validation = styled('div', {
  justifySelf: 'end',
})
const SelectedOptions = styled('div', {
  opacity: 0.5,
})

export const ProductsDebugger = () => {
  const { locale } = useRouter()
  const [products, setProducts] = useState<ShopifyProduct[]>([])
  const [, language] = getLocaleRegionIdFromPath(locale)

  useEffect(() => {
    const shopifyClient = createStorefrontApiClient(language)
    const init = async () => {
      const ids = await shopifyClient
        .request<{ products: { edges: { node: { id: string } }[] } }>(IDS_QUERY)
        .then((res) => res.products.edges.map((e) => e.node.id))

      shopifyClient
        .request<{ nodes: Array<ShopifyProductRes> }>(QUERY, { ids: ids })
        .then((res) =>
          res.nodes.map((node) => {
            return {
              ...node,
              variants: node.variants.edges.map((e) => e.node),
            }
          }),
        )
        .then((products) => {
          if (products.length) {
            setProducts(products)
          }
        })
    }
    init()
  }, [])

  if (!products.length) {
    return null
  }
  const allSkus = products.reduce((acc: string[], p) => {
    const s: string[] = []
    p.variants.forEach((v) => s.push(v.sku))
    return [...acc, ...s]
  }, [])

  return (
    <Wrap>
      <Row sticky>
        <div>
          Products({products.length}) - <u>US instance of Shopify</u>
        </div>
        <div>Variants({allSkus.length})</div>
      </Row>
      {products &&
        products.map((p) => {
          if (p.handle === 'langify_image_container') return null
          return (
            <Row key={p.handle}>
              title: {p.title}
              <br />
              handle: {p.handle}
              <br />
              <Variants>
                {p.variants.map((v, i) => {
                  const empty = v.sku === ''
                  const duplicated =
                    !empty && allSkus.filter((s) => s === v.sku).length >= 2
                  const needsUpdate = v.sku.includes('update')
                  const valid = !empty && !duplicated
                  return (
                    <Variant valid={valid} key={i}>
                      <div>
                        title: {v.title} <br />
                        sku: {v.sku}
                        {v.selectedOptions.map((o, j) => (
                          <SelectedOptions key={j}>
                            {o.name}: {o.value} <br />
                          </SelectedOptions>
                        ))}
                      </div>
                      <Validation>
                        {empty && 'empty sku'}
                        {duplicated && 'duplicated sku'}
                        {needsUpdate && 'created by developer, needs update'}
                        &nbsp;
                        {valid ? '✅' : '❌'}
                      </Validation>
                    </Variant>
                  )
                })}
              </Variants>
            </Row>
          )
        })}
    </Wrap>
  )
}
