import {
  MapFiltersType,
  MediaFieldType,
  PortableTextBlocks,
} from '@liftfoils/models'
import {
  coalesceLocaleField,
  createBaseImageProjection,
  createMediaProjection,
  getClient,
} from '@liftfoils/services/sanity-service'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { groq } from 'next-sanity'

type SearchPageResult = {
  id: string
  title: string
  description: string | PortableTextBlocks
  image: MediaFieldType
  path: string
}

type SearchEventResult = {
  id: string
  title: string
  content: PortableTextBlocks
  path: string
  image: MediaFieldType
  startDate: string
  address: {
    state: string
    city: string
  } | null
}

type SearchLocationResult = {
  _id: string
  name: string
  addressLine1: string
  country: string
  city: string
  state: string
  phone: string
  email: string
  website: string
  path: string
  type: MapFiltersType
}

type SanitySearchResult = {
  _id: string
  _score: number
} & (
  | {
      _type: 'page'
      title: string
      description: string
      image: MediaFieldType
    }
  | {
      _type: 'story'
      title: string
      description: string
      image: MediaFieldType
      path: string
    }
  | {
      _type: 'event'
      title: string
      content: PortableTextBlocks
      path: string
      image: MediaFieldType
      startDate: string
      address: {
        state: string
        city: string
      }
    }
  | {
      _type: 'location'
      name: string
      addressLine1: string
      country: string
      city: string
      state: string
      phone: string
      email: string
      website: string
      locationType: MapFiltersType
    }
)

type PageDocument = Extract<SanitySearchResult, { _type: 'page' | 'story' }> & {
  path: string
}

type EventDocument = Extract<SanitySearchResult, { _type: 'event' }>

async function getSanityDocumentsResultsByQuery(
  query: string,
  locale: string,
): Promise<{
  pages: Array<SearchPageResult>
  events: Array<SearchEventResult>
  locations: Array<SearchLocationResult>
}> {
  if (query === '') {
    return {
      pages: [],
      events: [],
      locations: [],
    }
  }

  const pagesIdsToPaths = await getAllPagesIdsByPath()
  const [, localeId] = getLocaleRegionIdFromPath(locale)
  const pagesIds = Object.keys(pagesIdsToPaths)

  const PAGES_FILTERS = `(
      _type == "page"
        && _id in [${pagesIds.map((r) => `"${r}"`)}]
        && (
          title.${localeId} match "*${query}*"
          || seo.metaTitle.${localeId} match "*${query}*"
          || seo.metaDescription.${localeId} match "*${query}*"
        )
    )`

  const LOCATIONS_FILTER = `(
        _type == "location"
          && !(_id in path("drafts.**"))
          && (
            name match "*${query}*"
            || country match "*${query}*"
            || email match "*${query}*"
          )
      )`

  const STORIES_FILTER = `(
      _type == "story"
        && !(_id in path("drafts.**"))
        && (
          title.${localeId} match "*${query}*"
          || shortDescription.${localeId} match "*${query}*"
          || pt::text(content.${localeId}) match "*${query}*"
        )
    )`

  const EVENTS_FILTERS = `(
            _type == "event"
              && !(_id in path("drafts.**"))
              && (
                title.${localeId} match "*${query}*"
                || pt::text(introduction.${localeId}) match "*${query}*"
                || pt::text(content.${localeId}) match "*${query}*"
              )
          )`

  const PAGES_SCORE_SETTINGS = `score(
      // _type == "page"
      boost(seo.metaDescription.${localeId} match "*${query}*", 3),
      boost(seo.metaTitle.${localeId} match "*${query}*", 2),
  
      // _type == "story"
      boost(shortDescription.${localeId} match "*${query}*", 2)
    )`

  const EVENTS_SCORE_SETTINGS = `score(
      boost(pt::text(introduction.${localeId}) match "*${query}*", 2)
    )`

  const LOCATIONS_SCORE_SETTINGS = `score(
      name match "*${query}*",
      boost(country match "*${query}*", 3),
      boost(email match "*${query}*", 2),
    )`

  const PAGES_QUERY = groq`
      *[${PAGES_FILTERS} || ${STORIES_FILTER}] | ${PAGES_SCORE_SETTINGS} {
        _type,
        _id,
        ${coalesceLocaleField('title', localeId)},
  
        _type == "page" => {
          "description": seo.metaDescription.${localeId},
          "image": seo.ogImage${createBaseImageProjection(localeId)}
        },
  
        _type == "story" => {
          ${coalesceLocaleField('shortDescription', localeId, 'description')},
          "image": background${createMediaProjection(localeId)},
          "path": path.current
        },
      }
    `

  const EVENTS_QUERY = groq`
    *[${EVENTS_FILTERS}] | ${EVENTS_SCORE_SETTINGS} {
      _type,
      _id,
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('content', localeId)},
      "image": background${createMediaProjection(localeId)},
      "startDate": coalesce(startDate, startTime),
      address {
          state,
          city
      },
      "path": path.current
    }
    `

  const LOCATIONS_QUERY = groq`
    *[${LOCATIONS_FILTER}] | ${LOCATIONS_SCORE_SETTINGS} {
      _type,
      _id,
      name,
      addressLine1,
      country,
      city,
      state,
      phone,
      email,
      website,
      locationType
    }
    `

  type FetchOutput = {
    pages: Array<Extract<SanitySearchResult, { _type: 'page' | 'story' }>>
    events: Array<Extract<SanitySearchResult, { _type: 'event' }>>
    locations: Array<Extract<SanitySearchResult, { _type: 'location' }>>
  }

  const result = await getClient(false).fetch<FetchOutput>(
    `{ "pages": ${PAGES_QUERY}, "events": ${EVENTS_QUERY}, "locations": ${LOCATIONS_QUERY} }`,
  )

  return {
    pages: result.pages.map((p) => {
      return mapPageDocumentToSearchPageResult(
        p._type === 'page'
          ? {
              ...p,
              path: pagesIdsToPaths[p._id],
            }
          : p,
      )
    }),
    events: result.events.map(mapEventDocumentToSearchEventResult),
    locations: result.locations.map(mapLocationDocumentToSearchLocationResult),
  }
}

export { getSanityDocumentsResultsByQuery }
export type { SearchEventResult, SearchLocationResult, SearchPageResult }

function mapPageDocumentToSearchPageResult(p: PageDocument): SearchPageResult {
  return {
    id: p._id,
    title: p.title,
    description: p.description,
    image: p.image,
    path: p.path,
  }
}

function mapEventDocumentToSearchEventResult(
  e: EventDocument,
): SearchEventResult {
  return {
    id: e._id,
    title: e.title,
    content: e.content,
    path: e.path,
    image: e.image,
    startDate: e.startDate,
    address: e.address,
  }
}

function mapLocationDocumentToSearchLocationResult(
  l: Extract<SanitySearchResult, { _type: 'location' }>,
): SearchLocationResult {
  return {
    _id: l._id,
    name: l.name,
    addressLine1: l.addressLine1,
    country: l.country,
    city: l.city,
    state: l.state,
    phone: l.phone,
    email: l.email,
    website: l.website,
    type: l.locationType,
    path: '/locations?id=' + l._id,
  }
}

async function getAllPagesIdsByPath() {
  const query = groq`
      *[_type == "route" && !(_id in path("drafts.**"))] {
        "id": defaultEntry._ref,
        "path": path.current
      }
      `

  const response: Array<{ id: string; path: string }> = await getClient(
    false,
  ).fetch(query)

  return Object.fromEntries(response.map((page) => [page.id, page.path]))
}
