import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { MediaFieldType } from '@liftfoils/models'
import { useTranslation } from 'react-i18next'
import { Link } from '../Link/Link'
import { Media } from '../Media/Media'
import { StyledClickable } from '../StyledClickable/StyledClickable'

const StoryMediaWrap = styled('div', {
  position: 'relative',
  borderRadius: '$r1',
  overflow: 'hidden',
  transform: 'translateZ(0)',
  display: 'grid',

  '& > *': {
    gridArea: '1/1',
  },
})

const ButtonWrap = styled('div', {
  position: 'absolute',
  top: '$6',
  left: '$6',
  zIndex: 2,
})

const StoryTitle = styled('h3', {
  lift_font: 'body05',
  mt: '$7',
})

const StoryDate = styled('p', {
  lift_font: 'body03',
  mt: '$5',
  color: '$white_06',
})

const Wrap = styled('div', {
  display: 'block',
  width: '100%',
  variants: {
    isSliderItem: {
      true: {
        minWidth: '300px',
        scrollSnapAlign: 'start',
        '+ *': {
          $ml: 'S',
        },
      },
    },
    isVisible: {
      true: {
        display: 'block',
      },
      false: {
        display: 'none',
      },
    },
  },
})

type StoryCardProps = {
  title?: string
  category?: string
  path?: string
  date?: string
  background?: MediaFieldType
  isSliderItem?: boolean
  isVisible?: boolean
  sizes?: string
}

export const StoryCard: FC<StoryCardProps> = ({
  title,
  category,
  path,
  date,
  background,
  isSliderItem,
  isVisible,
  sizes,
}) => {
  const { t } = useTranslation('stories')

  return (
    <Wrap key={title} isSliderItem={isSliderItem} isVisible={isVisible}>
      <Link href={path} appearance={'blockWithOutline'}>
        <StoryMediaWrap>
          {category && (
            <ButtonWrap>
              <StyledClickable
                as={'div'}
                appearance={'blur'}
                size={'small'}
                css={{
                  pointerEvents: 'none',
                }}
              >
                {t(category)}
              </StyledClickable>
            </ButtonWrap>
          )}
          {background && (
            <Media
              {...background}
              layout="responsive"
              sizes={sizes}
              hardcropRatio="portrait2"
              alt={background?.alt ?? ''}
            />
          )}
        </StoryMediaWrap>
        {title && <StoryTitle>{title}</StoryTitle>}

        {date && <StoryDate>{date}</StoryDate>}
      </Link>
    </Wrap>
  )
}
