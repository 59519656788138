import { FC, useEffect } from 'react'
import { styled } from '@liftfoils/styles'
import { sleep } from '@liftfoils/utils'

const Root = styled('div', {
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  pt: '56.25%',
  '&> iframe': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
})

declare global {
  interface Window {
    YT: any
    onYouTubeIframeAPIReady: any
  }
}

type YouTubePlayerProps = { videoId: string; autoplay?: boolean }

export const YouTubePlayer: FC<YouTubePlayerProps> = ({
  videoId,
  autoplay = true,
}) => {
  const onPlayerReady = (event: any) => {
    if (window.innerWidth < 1024) {
      event.target.mute()
    }
  }
  const scriptId = 'ytPlayerScript'
  const playerId = `player${videoId}`

  useEffect(() => {
    const loadVideo = () => {
      return new window.YT.Player(playerId, {
        height: '260',
        width: '440',
        videoId: videoId,
        playerVars: {
          autoplay: autoplay ? 1 : 0,
          playsinline: 1,
          color: 'white',
        },
        events: {
          onReady: onPlayerReady,
        },
      })
    }
    const loadScript = () => {
      if (document.getElementById(scriptId)) return
      const tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      tag.id = scriptId
      window.onYouTubeIframeAPIReady = loadVideo
      tag.id = scriptId
      // window.onYouTubeIframeAPIReady = loadVideo // I had to comment it to make multiple videos in one page work
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag)
    }

    /**
     * It check multiple times that yt player script is done
     * Without this logic error occured when navigated from one
     * story to another or when refreshing page
     */

    const init = async () => {
      if (!window.YT) {
        loadScript()
      }
      let counter = 0
      while (!window.YT?.loaded && counter < 20) {
        loadScript()
        await sleep(500)
        counter++
      }
      if (!window.YT.loaded) {
        throw new Error('Could not get youtube iframe api')
      }
      loadVideo()
    }
    init()
  }, [])

  return (
    <Root>
      <div id={playerId} />
    </Root>
  )
}
