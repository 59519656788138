import { styled } from '@liftfoils/styles'
import React from 'react'

const Root = styled('svg')

export const PlayIcon = () => (
  <Root
    viewBox="0 0 24 24"
    fill="none"
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.653 6.117A.75.75 0 007.5 6.75v10.5a.75.75 0 001.153.633l8.25-5.25a.75.75 0 000-1.266l-8.25-5.25z"
      fill="currentColor"
    />
  </Root>
)
