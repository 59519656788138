import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { validateRequired } from '../helpers/validateRequired'
import { validateEmail } from '../helpers/validateEmail'
import { useFormSubmitState } from '../helpers/useFormSubmitState'

import { styled } from '@liftfoils/styles'
import { submitForm } from '@liftfoils/services/api-service'

import { FormTextField, SubmitButton, FormPhoneField } from '../inputs'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { useRouter } from 'next/router'

interface SupportFormProps {
  className?: string
}

type FormValues = {
  name?: string
  email?: string
  phoneNumber?: string
  subject?: string
  message?: string
}

const defaultValues: FormValues = {
  name: '',
  email: '',
  phoneNumber: '',
  subject: '',
  message: '',
}

const Form = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$4',
})

export const SupportForm: FC<SupportFormProps> = ({ className }) => {
  const { t } = useTranslation('forms')
  const form = useForm<FormValues>({
    defaultValues,
  })
  const { locale } = useRouter()
  const [, regionId] = getLocaleRegionIdFromPath(locale)

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = form

  const { invalid, success, setSuccess, setError, submitMessage } =
    useFormSubmitState<FormValues>({ form, defaultValues })

  const onSubmit = async (data: FormValues) => {
    try {
      const response = await submitForm('supportForm', {
        ...data,
        region: regionId,
      })
      if (!response.ok) {
        return setError()
      }
      return setSuccess()
    } catch {
      return setError()
    }
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className={className}
      name="support-form"
    >
      <FormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="name"
        name="name"
        label={t('supportForm.nameFieldLabel')}
      />
      <FormTextField
        control={control}
        autoComplete="email"
        rules={{ ...validateRequired(t), ...validateEmail(t) }}
        type="email"
        name="email"
        label={t('supportForm.emailFieldLabel')}
      />

      <FormPhoneField
        control={control}
        rules={validateRequired(t)}
        name="phoneNumber"
        label={t('supportForm.phoneFieldLabel')}
        form={form}
      />

      <FormTextField
        control={control}
        rules={validateRequired(t)}
        name="subject"
        label={t('supportForm.subjectFieldLabel')}
      />

      <FormTextField
        control={control}
        rules={validateRequired(t)}
        name="message"
        label={t('supportForm.messageFieldLabel')}
        multiline
        rows={8}
      />

      <SubmitButton
        text={t('supportForm.submitButtonLabel')}
        helpText={submitMessage}
        invalid={invalid}
        success={success}
        loading={isSubmitting}
      />
    </Form>
  )
}
