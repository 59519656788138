import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import { styled } from '@liftfoils/styles'
import { useCart } from '../useCart'

const Root = styled('div', {
  position: 'sticky',
  bottom: 0,
  padding: '$7',
  background: 'black',
  color: 'white',
  maxHeight: '50vh',
  overflow: 'auto',
  width: '100%',
  display: 'grid',
  gap: '4px',
  gridTemplateColumns: '1fr 1fr',
  alignItems: 'start',
  button: {
    padding: '5px 10px',
  },
  textarea: {
    width: '300px',
    height: '150px',
  },
})
const Buttons = styled('div', {
  display: 'flex',
  gap: '4px',
})
const Code = styled('span', {
  padding: '2px',
  color: 'white',
  variants: {
    applicable: {
      true: {
        color: 'green',
      },
      false: {
        color: 'red',
      },
    },
  },
})
export const Debugger: FC<PropsWithChildren> = () => {
  const { cart, updateDiscountCodes } = useCart()
  const [val, setVal] = useState('')
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window._ = () => {
      console.log('Toggle Debugger')
      return setEnabled(!enabled)
    }
  }, [enabled])

  if (!enabled) return null
  return (
    <Root>
      <div>
        <pre>
          Input for codes <br />
          JSON format, eg. ["code1", "code2"]
        </pre>
        <textarea value={val} onChange={(e) => setVal(e.target.value)} />
      </div>

      <Buttons>
        <pre>
          Codes in Cart
          <br />[
          <br />
          {cart?.discountCodes?.map(({ code, applicable }, i) => (
            <React.Fragment key={code}>
              {'  '}"<Code applicable={applicable}>{code}</Code>"
              {i + 1 === cart?.discountCodes.length ? '' : ','}
              <br />
            </React.Fragment>
          ))}
          ]
        </pre>
      </Buttons>
      <Buttons>
        <button
          onClick={() =>
            updateDiscountCodes(JSON.parse(val.replace(/'/g, '"')))
          }
        >
          updateDiscountCodes
        </button>
      </Buttons>
    </Root>
  )
}
