import { styled } from '@liftfoils/styles'

export const Gradient = styled('div', {
  position: 'absolute',
  pointerEvents: 'none',
  width: '100%',
  variants: {
    from: {
      bottom: {
        bottom: 0,
        background:
          'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.25) 100%)',
      },
      top: {
        top: 0,
        background:
          'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.25) 100%)',
      },
    },
  },
})
