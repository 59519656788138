import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { PageBuilderSection, PageResponse } from '@liftfoils/models'
import { createStoryPageProjection } from './projections/createStoryPageProjection'

export const getStoryPageByPath = async (
  path: string,
  localeRegionString: string,
  preview = false,
): Promise<PageResponse<PageBuilderSection[]>> => {
  const [, localeId] = getLocaleRegionIdFromPath(localeRegionString)
  const STORY_PROJECTION = createStoryPageProjection(localeId)

  const query = groq`*[!(_id in path('drafts.**')) && _type == "story" && defined(path.current) && path.current == "${path}"][0]${STORY_PROJECTION}`

  const pageData = await getClient(preview).fetch(query)

  return pageData
}
