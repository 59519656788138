import React from 'react'

export const ArrowTopRight = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.75773 6.69793C9.75773 6.80659 9.66719 6.89714 9.55853 6.89714L8.5442 6.89695C8.43554 6.89695 8.34499 6.80641 8.34517 6.69793L8.34499 2.64115L1.93412 9.05202C1.86169 9.12446 1.71717 9.12446 1.64473 9.05202L0.920339 8.32763C0.847899 8.25519 0.847718 8.11049 0.920157 8.03805L7.33102 1.62719L3.27443 1.62719C3.16577 1.62719 3.07522 1.53664 3.07522 1.42798L3.07522 0.413831C3.07504 0.304991 3.16559 0.214442 3.27443 0.214623L9.54042 0.214623C9.64908 0.214623 9.73962 0.305172 9.73962 0.413831L9.75773 6.69793Z" />
    </svg>
  )
}
