import { FormType } from '@liftfoils/models'

export const newsletterSubscribe = async (email: string) =>
  await fetch('/api/klaviyo/subscribe', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(email),
  })

export const checkSubscription = async (email: string) =>
  await fetch('/api/klaviyo/check-subscription', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(email),
  })

export type ContactFormModel = {
  phoneNumber: string
  name: string
  email?: string
  message: string
  region: string
}

export type PartnerFormModel = {
  firstName: string
  lastName: string
  desiredOperatingArea?: string
  businessName?: string
  businessDescription?: string
  phoneNumber: string
  email: string
  website?: string
  country: string
  programType: string
}

export type SupportFormModel = {
  name?: string
  email?: string
  phoneNumber?: string
  subject?: string
  message?: string
  region: string
}

export type RequestCallbackFormModel = {
  name?: string
  email?: string
  phoneNumber?: string
  message?: string
  region: string
}
export type AskQuestionFormFormModel = {
  name?: string
  email?: string
  country?: string
  message?: string
  region: string
  path: string
}

export type SendProductConfigModel = {
  name?: string
  email?: string
  country?: string
  message?: string
  phoneNumber?: string
  config?: string
  region: string
  path: string
}

export type FormModelType<T> = T extends 'contactForm'
  ? ContactFormModel
  : T extends 'partnerForm'
  ? PartnerFormModel
  : T extends 'supportForm'
  ? SupportFormModel
  : T extends 'requestCallbackForm'
  ? RequestCallbackFormModel
  : T extends 'sendProductConfigForm'
  ? SendProductConfigModel
  : T extends 'askQuestionForm'
  ? AskQuestionFormFormModel
  : never

export async function submitForm<T extends FormType>(
  formType: T,
  formData: FormModelType<T>,
) {
  return await fetch(`/api/klaviyo/form/${formType}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  })
}
