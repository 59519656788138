import { FC, useState } from 'react'
import { styled } from '@liftfoils/styles'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { submitForm } from '@liftfoils/services/api-service'
import { validateRequired } from '../helpers/validateRequired'
import { validateEmail } from '../helpers/validateEmail'
import { useFormSubmitState } from '../helpers/useFormSubmitState'
import { FormPhoneField, FormTextField, SubmitButton } from '../inputs'
import { useRouter } from 'next/router'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { Disclosure } from '@liftfoils/components'
import { ArrowDown } from '@liftfoils/icons'

interface ContactFormProps {
  className?: string
  attributes?: { key: string; value: string }[]
}

type FormValues = {
  name: string
  email: string
  notes: string
  country: string
  phoneNumber: string
}

const defaultValues: FormValues = {
  name: '',
  email: '',
  notes: '',
  country: '',
  phoneNumber: '',
}

const Form = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$4',
})
const AccordionWrap = styled('form', {
  display: 'grid',
  padding: '0 $8',
})
const AccordionInner = styled('form', {
  lift_font: 'body03',
  color: '$gray500',
  textAlign: 'left',
})
const AccordionHeader = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  lift_font: 'body02',
  color: '$gray500',
  padding: '$4 0',
  variants: {
    isOpen: {
      true: {
        svg: {
          transform: 'rotate(180deg)',
        },
      },
    },
  },
})

export const SendProductConfigForm: FC<ContactFormProps> = ({
  className,
  attributes,
}) => {
  const { t } = useTranslation('forms')
  const form = useForm<FormValues>({
    defaultValues,
  })

  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  const { locale, asPath } = useRouter()
  const [, regionId] = getLocaleRegionIdFromPath(locale)

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    clearErrors,
  } = form

  const { invalid, success, setSuccess, setError, submitMessage } =
    useFormSubmitState<FormValues>({ form, defaultValues })

  const onSubmit = async (data: FormValues) => {
    try {
      clearErrors()
      const response = await submitForm('sendProductConfigForm', {
        ...data,
        config: `${attributes?.map((a) => `${a.key}: ${a.value}, `)}`,
        region: regionId,
        path: asPath,
      })
      if (!response.ok) {
        return setError()
      }
      return setSuccess()
    } catch {
      return setError()
    }
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className={className}
      name="send-product-config-form"
    >
      <FormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="name"
        name="name"
        label={t('sendProductConfigForm.nameFieldLabel')}
      />

      <FormTextField
        control={control}
        rules={{
          ...validateEmail(t),
          ...validateRequired(t),
        }}
        autoComplete="email"
        type="email"
        name="email"
        label={t('sendProductConfigForm.emailFieldLabel')}
      />

      <FormPhoneField
        control={control}
        rules={validateRequired(t)}
        name="phoneNumber"
        label={t('sendProductConfigForm.phoneFieldLabel')}
        form={form}
      />

      <FormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="country-name"
        name="country"
        label={t('sendProductConfigForm.countryFieldLabel')}
      />

      <FormTextField
        control={control}
        rules={validateRequired(t)}
        name="notes"
        label={t('sendProductConfigForm.notesFieldLabel')}
        multiline
        rows={3}
      />

      <AccordionWrap>
        <Disclosure
          id={'yourConfiguration'}
          isOpen={isAccordionOpen}
          onRequestClose={() => setIsAccordionOpen(false)}
          onRequestOpen={() => setIsAccordionOpen(true)}
          headerSlot={
            <AccordionHeader isOpen={isAccordionOpen}>
              <span>{t('sendProductConfigForm.yourConfiguration')}</span>
              <ArrowDown />
            </AccordionHeader>
          }
        >
          <AccordionInner>
            {attributes?.map((attribute) => (
              <>
                {attribute.key}: {attribute.value}
                <br />
              </>
            ))}
          </AccordionInner>
        </Disclosure>
      </AccordionWrap>

      <SubmitButton
        text={t('sendProductConfigForm.submitButtonLabel')}
        helpText={submitMessage}
        invalid={invalid}
        success={success}
        loading={isSubmitting}
      />
    </Form>
  )
}
