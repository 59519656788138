import { Button } from '@liftfoils/components'
import { useTranslation } from 'react-i18next'
import { useAssistanceContext } from '@liftfoils/assistance'

export const ButtonAssistance = () => {
  const { openAssistanceModal } = useAssistanceContext()
  const { t } = useTranslation('header')

  return (
    <Button
      appearance={'solidTeal500'}
      size={'small'}
      onClick={openAssistanceModal}
    >
      {t('callUs')}
    </Button>
  )
}
