import { FC, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { normalizedRegions, Region, regions } from '@liftfoils/configs'
import { styled } from '@liftfoils/styles'
import { getLocaleData, getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { Button } from '../Button/Button'
import { Modal } from '../Modal/Modal'
import { ModalHeader } from '../ModalHeader/ModalHeader'
import { useTranslation } from 'react-i18next'
import { ArrowHeadDown, ArrowLeft, ArrowRight } from '@liftfoils/icons'
import Cookies from 'js-cookie'

const Content = styled('div', {
  maxWidth: '100%',
  width: '400px',
  display: 'grid',
  'button:last-of-type': {
    borderBottomLeftRadius: '$r2',
    borderBottomRightRadius: '$r2',
  },
  Button: {
    '&:not(:first-of-type)': {
      borderTop: '1px solid $gray200',
    },
  },
})

const Row = styled(Button, {
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  lift_font: 'body02',
  justifyItems: 'start',
  textAlign: 'left',
  px: '$8',
  height: '$11',
  alignItems: 'center',
  transition: 'color 100ms',
  '&:hover': {
    color: '$gray400',
  },
})
const SecondaryBar = styled(Row, {
  gridTemplateColumns: '1fr auto 1fr !important',
})

const GraySpan = styled('span', {
  ml: '$4',
  color: '$gray300',
})

type Market = {
  name: string
  regions: Region[]
}

const getCurrencySymbol = (locale: string, currency: string) => {
  const res = (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim()
  return res
}

const getFlagEmoji = (c: string) =>
  c.replace(/./g, (ch) =>
    String.fromCodePoint(0x1f1a5 + ch.toUpperCase().charCodeAt(0)),
  )

const FlagWrap = styled('span', {
  mr: '$5',
})

const Flag: FC<{ regionId: string | undefined }> = ({ regionId }) => {
  return regionId ? <FlagWrap>{getFlagEmoji(regionId)}</FlagWrap> : null
}

const COOKIE_EXPIRES = 14

export const ShippingButton: FC<{
  isShippingModalOpen: boolean
  handleOpenShippingModal: () => void
  handleCloseShippingModal: () => void
  appearance: 'bareWhite' | 'bareGray' | 'outlineGray300'
  shortLabel?: boolean
  size?: 'small' | 'medium'
}> = ({
  appearance = 'bareGray',
  size = 'small',
  isShippingModalOpen,
  handleOpenShippingModal,
  handleCloseShippingModal,
}) => {
  const { t } = useTranslation('header')
  const router = useRouter()
  const { pathname, asPath, query, locale } = router

  const toggleLocale = (newLocale: string) => {
    Cookies.set('PREFERRED_REGION', newLocale.toLowerCase(), {
      expires: COOKIE_EXPIRES,
    })
    router.push({ pathname, query }, asPath, {
      locale: newLocale.toLowerCase(),
    })
    handleCloseShippingModal()
  }
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
  const currentRouterRegion = normalizedRegions[regionId]

  const [state, setState] = useState<{
    view: 'markets' | 'regions' | 'locales'
    marketIndex: number
    regionIndex: number
  }>({
    view: 'markets',
    marketIndex: -1,
    regionIndex: -1,
  })

  useEffect(() => {
    if (isShippingModalOpen) {
      setState({
        view: 'markets',
        marketIndex: -1,
        regionIndex: -1,
      })
    }
  }, [isShippingModalOpen])

  const markets: Market[] = useMemo(
    () =>
      regions
        .reduce((acc: Market[], region) => {
          const foundMarket = acc.find(
            (market) => market.name === region.market,
          )
          let newMarket = undefined
          if (foundMarket) {
            foundMarket.regions.push(region)
          } else {
            newMarket = {
              name: region.market,
              regions: [region],
            }
          }
          return newMarket ? [...acc, newMarket] : acc
        }, [])
        .map((market) => ({
          ...market,
          regions: market.regions.sort((a, b) => (a.label > b.label ? 1 : -1)),
        }))
        .sort((a) => (a.regions.length === 1 ? 1 : -1)),
    [locale],
  )

  const marketFromState = markets[state.marketIndex]
  const regionFromState = marketFromState?.regions[state.regionIndex]

  const fullLocale = `${localeId}-${regionId}`

  return (
    <>
      <Button
        appearance={appearance}
        size={size}
        onClick={handleOpenShippingModal}
      >
        {currentRouterRegion.shortLabel} <span>|</span>{' '}
        {getCurrencySymbol(fullLocale, currentRouterRegion.currencyCode)}
        <span>
          <ArrowHeadDown />
        </span>
      </Button>
      <Modal
        isOpen={isShippingModalOpen}
        onRequestClose={handleCloseShippingModal}
        variant={'white-rounded'}
      >
        <ModalHeader
          title={t('shipping')}
          onRequestClose={handleCloseShippingModal}
        />
        <Content>
          {state.view === 'markets' &&
            markets.map((market, i) => {
              const singleRegion =
                market.regions.length === 1 ? market.regions[0] : undefined
              const singleRegionSingleLocale =
                singleRegion && market.regions[0].supportedLocales.length === 1
                  ? market.regions[0].supportedLocales[0]
                  : undefined
              const url = singleRegion?.url
              return (
                <Row
                  key={market.name}
                  onClick={() => {
                    if (url) {
                      router.push(url)
                      return
                    }
                    if (singleRegionSingleLocale) {
                      toggleLocale(market.regions[0].id)
                    } else if (singleRegion) {
                      setState({
                        view: 'locales',
                        marketIndex: i,
                        regionIndex: 0,
                      })
                    } else {
                      setState({
                        view: 'regions',
                        marketIndex: i,
                        regionIndex: -1,
                      })
                    }
                  }}
                >
                  <div>
                    <Flag regionId={singleRegion?.id} />
                    {market.name}
                  </div>
                  {singleRegion ? (
                    <GraySpan>
                      {getCurrencySymbol(fullLocale, singleRegion.currencyCode)}
                    </GraySpan>
                  ) : (
                    <ArrowRight />
                  )}
                </Row>
              )
            })}
          {state.view === 'regions' && (
            <>
              <SecondaryBar
                aria-label={t('back')}
                onClick={() => {
                  setState({
                    view: 'markets',
                    marketIndex: -1,
                    regionIndex: -1,
                  })
                }}
              >
                <ArrowLeft />
                <div>{marketFromState.name}</div>
              </SecondaryBar>
              {marketFromState.regions.map((region, i) => {
                const multiLocale = region.supportedLocales.length > 1
                return (
                  <Row
                    key={region.id}
                    onClick={() => {
                      if (multiLocale) {
                        setState({
                          view: 'locales',
                          marketIndex: state.marketIndex,
                          regionIndex: i,
                        })
                      } else {
                        toggleLocale(region.id)
                      }
                    }}
                  >
                    <div>
                      <Flag regionId={region.id} /> {region.label}
                    </div>

                    {multiLocale ? (
                      <ArrowRight />
                    ) : (
                      <GraySpan>
                        {getCurrencySymbol(fullLocale, region.currencyCode)}
                      </GraySpan>
                    )}
                  </Row>
                )
              })}
            </>
          )}
          {state.view === 'locales' && (
            <>
              <SecondaryBar
                aria-label={t('back')}
                onClick={() => {
                  setState({
                    view:
                      marketFromState.regions.length === 1
                        ? 'markets'
                        : 'regions',
                    marketIndex: state.marketIndex,
                    regionIndex: state.regionIndex,
                  })
                }}
              >
                <ArrowLeft />
                <div>
                  <Flag regionId={regionFromState?.id} />
                  {regionFromState?.label}
                </div>
              </SecondaryBar>
              {regionFromState?.supportedLocales.map((supportedLocale) => {
                return (
                  <Row
                    key={supportedLocale}
                    onClick={() => {
                      toggleLocale(
                        supportedLocale === regionFromState.defaultLocale
                          ? regionFromState.id
                          : `${regionFromState.id}-${supportedLocale}`,
                      )
                    }}
                  >
                    <div>{getLocaleData(supportedLocale)?.label}</div>
                    <GraySpan>
                      {getCurrencySymbol(
                        fullLocale,
                        regionFromState.currencyCode,
                      )}
                    </GraySpan>
                  </Row>
                )
              })}
            </>
          )}
        </Content>
      </Modal>
    </>
  )
}
