import { ShopifyResolvedCartLine } from '@liftfoils/shopify-sdk'
import { createItem } from './createItem'
import { GTMResetEcommerce } from './gtmResetEcommerce'

export const GTMAddManyToCart = (cartLines: ShopifyResolvedCartLine[]) => {
  if (!cartLines) {
    return
  }

  GTMResetEcommerce()

  const w = window as any

  w.dataLayer = w.dataLayer || []
  w.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      items: cartLines.map(({ merchandise, quantity }, index) =>
        createItem({
          productTitle: merchandise.product.title,
          currency: merchandise.price.currencyCode,
          index,
          variantTitle: merchandise.title,
          priceAmount: merchandise.price.amount,
          quantity,
        }),
      ),
    },
  })
}
