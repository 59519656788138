import { FC, useMemo, useState } from 'react'
import { styled } from '@liftfoils/styles'
import debounce from 'lodash/debounce'
import { getBundleId } from '@liftfoils/services/shopify-service'
import {
  ProductVariantPrice,
  Media,
  Button,
  Link,
  MediaPlaceholder,
} from '@liftfoils/components'
import { useTranslation } from 'react-i18next'
import {
  BUNDLE_ID_KEY,
  BUNDLE_TITLE_KEY,
  BUNDLE_QUERY_EDIT_KEY,
  ROUTE_BASE_PRODUCTS,
  BUNDLE_HANDLE_KEY,
} from '@liftfoils/configs'
import { useRouter } from 'next/router'
import {
  ShopifyMoneyFieldsFragment,
  ShopifyResolvedCartLine,
} from '@liftfoils/shopify-sdk'

const Root = styled('div', {
  display: 'grid',
  lift_font: 'body02',
  gridGap: '$8',
  gridTemplateColumns: '100px 1fr',
  '@md': {
    gridTemplateColumns: '220px 1fr',
  },
  alignItems: 'start',
  variants: {
    disabled: {
      true: { opacity: 0.5, pointerEvents: 'none' },
    },
  },
})

const MetaWrap = styled('div', {
  display: 'grid',
  gridGap: '$7',
  alignContent: 'start',
  justifyItems: 'start',
  py: '$4',
})
const Title = styled('h2', {
  lift_font: 'caps04',
  color: '$gray500',
})
const PriceWrap = styled('div', {
  lift_font: 'caps04',
  color: '$gray300',
})
const MediaWrap = styled(Link, {
  border: '1px solid',
  borderColor: '$gray200',
  borderRadius: '$r2',
  overflow: 'hidden ',
  display: 'grid',
  alignItems: 'center',
})
const Utils = styled('div', {
  display: 'grid',
  gridGap: '$7',
  gridAutoColumns: 'auto',
  gridAutoFlow: 'column',
  alignItems: 'center',
})
const Quantity = styled('span', {
  lift_font: 'body02',
  width: '$7',
  textAlign: 'center',
  color: '$gray500',
})
const IconWrap = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: { position: 'absolute' },
})
const BundleUtils = styled('div', {
  color: '$gray400',
  lift_font: 'body03',
  button: {
    mt: '$6',
  },
})
export const CartLine: FC<{
  line: ShopifyResolvedCartLine
  cartReady: boolean
  updateQuantity: (newQuantity: number) => Promise<number>
  removeLine: () => void
  overwrittenCost?: ShopifyMoneyFieldsFragment
}> = ({ line, updateQuantity, removeLine, cartReady, overwrittenCost }) => {
  const [quantity, setQuantity] = useState(line.quantity)
  const { t } = useTranslation('cart')
  const { push } = useRouter()

  const bundleId = getBundleId(line)
  const handle =
    line.attributes?.find((a) => a.key === BUNDLE_HANDLE_KEY)?.value ??
    line.merchandise.product.handle
  const productHref = handle.includes(ROUTE_BASE_PRODUCTS)
    ? handle
    : `${ROUTE_BASE_PRODUCTS}/${handle}`

  const variantPrice = overwrittenCost ?? line.cost.totalAmount

  const onEditClick = () => {
    const query = line.attributes?.reduce(
      (acc, a) => {
        if (a.key === BUNDLE_ID_KEY) return acc
        return {
          ...acc,
          [a.key]: a.value,
        }
      },
      { [BUNDLE_QUERY_EDIT_KEY]: bundleId },
    )
    push({ pathname: productHref, query })
  }

  const debouncedUpdate = useMemo(
    () =>
      debounce(async (newQuantity: number) => {
        await updateQuantity(newQuantity)
        setQuantity(newQuantity)
      }, 500),
    [updateQuantity],
  )

  const handleQuantityChange = (newQuantity: number) => {
    setQuantity(newQuantity)
    debouncedUpdate(newQuantity)
  }

  const title =
    line.attributes?.find((a) => a.key === BUNDLE_TITLE_KEY)?.value ??
    line.merchandise.product.title

  return (
    <Root disabled={!cartReady}>
      <MediaWrap href={productHref} tabIndex={-1}>
        <MediaPlaceholder ratio={'portrait2'}>
          {line.merchandise.image && (
            <Media
              mediaPayload={line.merchandise.image}
              sizes={'220px'}
              layout={'fill'}
              objectFit={'contain'}
              alt=""
            />
          )}
        </MediaPlaceholder>
      </MediaWrap>
      <MetaWrap>
        <Link href={productHref}>
          <Title>{title}</Title>
          <PriceWrap>
            <ProductVariantPrice price={variantPrice} />
          </PriceWrap>
        </Link>
        <Utils>
          <Button
            appearance={'outlineGray300'}
            size={'small'}
            onClick={() =>
              handleQuantityChange(quantity > 0 ? quantity - 1 : 0)
            }
            aria-label={t('decreaseQuantity')}
          >
            <IconWrap>
              <svg
                width="11"
                height="1"
                viewBox="0 0 11 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0V1H11V0H0Z" fill="currentColor" />
              </svg>
            </IconWrap>
          </Button>
          <Quantity>{quantity}</Quantity>
          <Button
            appearance={'outlineGray300'}
            size={'small'}
            onClick={() => handleQuantityChange(quantity + 1)}
            aria-label={t('increaseQuantity')}
          >
            <IconWrap>
              <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 0H6V1V5H10H11V6H10H6V10V11H5V10V6H1H0V5H1H5V1V0Z"
                  fill="currentColor"
                />
              </svg>
            </IconWrap>
          </Button>
          <Button onClick={removeLine} appearance={'grayUnderline'}>
            {t('remove')}
          </Button>
        </Utils>
        {line.attributes && bundleId && (
          <BundleUtils>
            {line.attributes
              .filter((a) => a.key[0] !== '_')
              .map((a) => (
                <div key={a.key}>
                  {a.key}: {a.value}
                </div>
              ))}
            <Button onClick={onEditClick} appearance={'grayUnderline'}>
              {t('editConfiguration')}
            </Button>
          </BundleUtils>
        )}
      </MetaWrap>
    </Root>
  )
}
