export const SoundOn = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8463 0.888916C17.0512 4.81629 17.0512 11.1838 12.8463 15.1111L11.8501 14.1807C15.505 10.7672 15.505 5.23303 11.8501 1.81939L12.8463 0.888916ZM10.7118 2.88277C13.7377 5.70898 13.7377 10.2913 10.7118 13.1175L9.71555 12.187C12.1912 9.87471 12.1912 6.12554 9.71555 3.81306L10.7118 2.88258V2.88277ZM5.97637 3.14462V12.9193L2.21384 9.91174H0V6.15223H2.21384L5.97637 3.14462ZM8.57703 4.87644C10.424 6.60168 10.424 9.39856 8.57703 11.1236L7.5808 10.1931C8.87771 8.98201 8.87771 7.01823 7.5808 5.80692L8.57703 4.87644Z"
        fill="white"
      />
    </svg>
  )
}
