import { useState } from 'react'
import { styled } from '@liftfoils/styles'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import {
  checkSubscription,
  newsletterSubscribe,
} from '@liftfoils/services/api-service'

const Root = styled('form', {})
const FormInputWrap = styled('div', {
  position: 'relative',
  alignItems: 'center',
  variants: {
    isVisible: {
      true: {
        display: 'grid',
      },
      false: {
        display: 'none',
      },
    },
  },
})

const HelperText = styled('p', {
  lift_font: 'body06',
  textAlign: 'right',
  mt: '$4',
  color: '$red500',
  variants: {
    isVisible: {
      true: {
        display: 'block',
      },
      false: {
        display: 'none',
      },
    },
  },
})

const FormSuccess = styled('p', {
  lift_font: 'body02',
  color: '$gray500',
  minHeight: '$buttonHeightL',
  alignItems: 'center',
  variants: {
    isVisible: {
      true: {
        display: 'grid',
      },
      false: {
        display: 'none',
      },
    },
  },
})

const InputEmail = styled('input', {
  lift_font: 'body02',
  display: 'block',
  height: '$buttonHeightL',
  borderRadius: '999px',
  border: '1px solid',
  borderColor: '$gray500',
  backgroundColor: '$gray500',
  '&:-webkit-autofill,&:-webkit-autofill:focus, &:-webkit-autofill:hover, &:-webkit-autofill:active':
    {
      '-webkit-box-shadow': '0 0 0 60px $colors$gray500 inset !important',
      backgroundClip: 'content-box !important',
      backgroundColor: '$gray500 !important',
      '-webkit-text-fill-color': '$colors$white !important',
    },
  pl: '$8',
  pr: '$12',
  color: 'white',
  '&::placeholder': {
    color: '$white_08',
  },
  '&:focus-visible': {
    outline: '#36B7B2 solid 3px',
  },
  variants: {
    isError: {
      true: {
        color: '$red500',
      },
    },
  },
})

const InputSubmit = styled('input', {
  lift_font: 'body02',
  position: 'absolute',
  appearance: 'none',
  height: '$buttonHeightM',
  justifySelf: 'end',
  borderRadius: '$rMax',
  display: 'flex',
  alignItems: 'center',
  mr: '$4',
  px: '$6',
  background: 'none',
  border: 'none',
  color: 'white',
  cursor: 'pointer',
  '&:focus-visible': {
    outline: '#36B7B2 solid 2px',
  },
  '&:hover': {
    backgroundColor: '$teal500',
  },
})

export const Newsletter = () => {
  const { t } = useTranslation('newsletter')
  const [value, setValue] = useState('')
  const [formState, setFormState] = useState<
    'default' | 'error' | 'success' | 'alreadyExist'
  >('default')

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value)
  }

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    checkSubscription(value).then((res) => {
      if (res.ok) {
        return setFormState('alreadyExist')
      } else {
        newsletterSubscribe(value)
          .then((res) => {
            if (res.ok) {
              return res.json()
            }
            throw new Error('Email send error')
          })
          .then((data) => {
            if (!data.errors) {
              setFormState('success')
            } else {
              throw new Error('Email send error')
            }
          })
          .catch(() => {
            setFormState('error')
          })
          .finally(() => {
            Cookies.set('disableNewsletter', 'true', { expires: 5 })
          })
      }
    })
  }

  return (
    <Root onSubmit={(e) => submitForm(e)}>
      <FormInputWrap isVisible={formState !== 'success'}>
        <InputEmail
          placeholder={t('enterEmail')}
          required
          onChange={handleInputChange}
          value={value}
          isError={formState === 'error' || formState === 'alreadyExist'}
        />
        <InputSubmit type="submit" value="Sign up" />
      </FormInputWrap>
      <FormSuccess isVisible={formState === 'success'}>
        {t('success')}
      </FormSuccess>
      <HelperText
        isVisible={formState === 'error' || formState === 'alreadyExist'}
      >
        {t(formState)}
      </HelperText>
    </Root>
  )
}
