import React from 'react'

export const Star = () => {
  return (
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 7.5L2.81107 9.04508L3.37236 5.77254L0.994717 3.45492L4.28054 2.97746L5.75 0L7.21946 2.97746L10.5053 3.45492L8.12764 5.77254L8.68893 9.04508L5.75 7.5Z"
        fill="#36B7B2"
      />
    </svg>
  )
}
