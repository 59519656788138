import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { Link } from '@liftfoils/components'
import { useCart } from '@liftfoils/cart'

const Quantity = styled('div', {
  display: 'grid',
  placeItems: 'center',
  width: '$2',
  position: 'relative',
  span: { position: 'absolute' },
})

export const CartQuantity: FC = () => {
  const { quantity } = useCart()

  return (
    <Link href={'/cart'} appearance={'solidTeal500'} size={'small'}>
      <Quantity>
        <span>{quantity ?? 0}</span>
      </Quantity>
    </Link>
  )
}
