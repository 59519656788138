import React from 'react'

export const MobileDrawerClose = () => {
  return (
    <svg
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2924 14.4996L0.503906 28.2881L1.21101 28.9952L14.9995 15.2067L28.7881 28.9953L29.4952 28.2882L15.7066 14.4996L29.4953 0.710922L28.7882 0.00381517L14.9995 13.7925L1.21094 0.00390625L0.503831 0.711013L14.2924 14.4996Z"
        fill="black"
      />
    </svg>
  )
}
