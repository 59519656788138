import React, { FC } from 'react'
import { PricePrimitive } from './PricePrimitive'
import { ShopifyProductPriceRangeFieldsFragment } from '@liftfoils/shopify-sdk'

type PriceRangeProps = {
  range: ShopifyProductPriceRangeFieldsFragment
}

export const PriceRange: FC<PriceRangeProps> = ({ range }) => {
  return range.maxVariantPrice.amount === range.minVariantPrice.amount ? (
    <PricePrimitive price={range.minVariantPrice} />
  ) : (
    <>
      From <PricePrimitive price={range.minVariantPrice} />
      {/*&mdash;&nbsp;*/}
      {/*<PricePrimitive price={range.maxVariantPrice} />*/}
    </>
  )
}
