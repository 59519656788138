import { styled } from '@liftfoils/styles'
export const VideoModalContentWrap = styled('div', {
  overflow: 'hidden',
  transform: 'translateZ(0)',
  borderRadius: '$r1',
  width: '320px', // this value must be in pixels to not rerender iframe content on ios when rotating device
  '@md': {
    width: '70vw',
  },
  '@lg': {
    width: '60vw',
  },
})
