import { ShopifyResolvedVariantBasic } from '@liftfoils/shopify-sdk'

export const getVariantsSumPrice = (
  variants?: ShopifyResolvedVariantBasic[],
) => {
  if (!(variants && variants.length > 0)) return undefined
  return {
    amount: variants
      .reduce((acc, variant) => {
        return parseFloat(variant.price.amount) + acc
      }, 0)
      .toString(),
    currencyCode: variants[0].price.currencyCode,
  }
}
