import { createBaseImageProjection } from './projections/createBaseImageProjection'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { groq } from 'next-sanity'
import { createPageBuilderProjection } from './projections/createPageBuilderProjection'
import { getClient } from './getClient'
import { PageBuilderSection, PageResponse } from '@liftfoils/models'
import { coalesceLocaleField } from './helpers/coalesceLocaleField'
import { selectRegionalizedContent } from './helpers/selectRegionalizedContent'
import { DEFAULT_REGION_ID } from '@liftfoils/configs'

export const getRouteByPath = async (
  path: string,
  localeRegionString: string,
  preview = false,
): Promise<PageResponse<PageBuilderSection[]>> => {
  const [, localeId] = getLocaleRegionIdFromPath(localeRegionString)
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const BASE_IMAGE_PROJECTION = createBaseImageProjection(localeId)

  // TODO remove instanceId
  const instanceId = DEFAULT_REGION_ID
  const query = groq`*[!(_id in path('drafts.**')) && _type == "route" && defined(path.current) && path.current == "${path}"][0]{
    "page": ${selectRegionalizedContent(instanceId)}->{
      ...,
      ${coalesceLocaleField('title', localeId)},
      "disallowRobots":^.disallowRobots || ^.b2bOnly,
      "b2bOnly": ^.b2bOnly,
      pageBuilder[]${PAGE_BUILDER_PROJECTION},
      seo{
        ${coalesceLocaleField('metaTitle', localeId)},
        ${coalesceLocaleField('metaDescription', localeId)},
        ${coalesceLocaleField('ogTitle', localeId)},
        ${coalesceLocaleField('ogDescription', localeId)},
        ogImage${BASE_IMAGE_PROJECTION}
      },
    }
  }.page`

  const pageData = await getClient(preview).fetch(query)

  return pageData
}
