import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { Section } from '@liftfoils/models'
import { ROUTE_BASE_PRODUCTS } from '@liftfoils/configs'
import { useTranslation } from 'react-i18next'
import { Link } from '../Link/Link'
import { Media } from '../Media/Media'
import { PriceRange } from '../Price/PriceRange'
import { ShopifyData } from '@liftfoils/shopify-sdk'

const Root = styled('div', {
  paddingTop: '$8',
  paddingBottom: '$10',
})

const Wrap = styled('div', {
  $$gray: '#575B5C',
  display: 'grid',
  gap: '$9',
  backgroundColor: '$$gray',
  borderRadius: '$r1',
  padding: '$9',

  '@md': {
    gridTemplateColumns: '$15 auto',
    borderRadius: '$r3',
  },
})

const MediaWrap = styled('div', {
  maxWidth: '$17',
})
const ContentWrap = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'start',
  gap: '$7',
})

const Title = styled('p', {
  lift_font: 'caps05',
  color: '$white',
})

const StyledLink = styled(Link, {
  lift_font: 'body02',
})

const Description = styled('span', {
  lift_font: 'body02',
  color: '$white_06',
  margin: '0',
  textAlign: 'left',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
})

type SectionShopTheStoryProps = Section<
  'sectionShopTheStory',
  {
    product?: {
      id?: string
    }
    shopifyData?: ShopifyData
  }
>

export const SectionShopTheStory: FC<SectionShopTheStoryProps> = ({
  product,
  shopifyData,
}) => {
  const { t } = useTranslation('stories')
  if (!shopifyData) return null

  const productId = product?.id ?? ''
  const shopifyProduct =
    shopifyData.productBasic && shopifyData.productBasic[productId]

  if (!shopifyProduct) return null

  const { featuredImage, handle, title, description, priceRange } =
    shopifyProduct

  return (
    <Root className={'shopTheStory'}>
      <Wrap>
        <MediaWrap>
          {featuredImage && (
            <Media
              mediaPayload={featuredImage}
              hardcropRatio={'square'}
              sizes="(min-width: 1000px) 32vw, 50vw"
              alt=""
            />
          )}
        </MediaWrap>
        <ContentWrap>
          <div>
            {title && <Title>{title}</Title>}
            {priceRange && <PriceRange range={priceRange} />}
          </div>
          {description && <Description>{description}</Description>}
          {handle && (
            <StyledLink
              href={`${ROUTE_BASE_PRODUCTS}/${handle}`}
              appearance={'solidTeal500'}
              size="medium"
            >
              {t('shopNow')}
            </StyledLink>
          )}
        </ContentWrap>
      </Wrap>
    </Root>
  )
}
