import { FC, useEffect, useState } from 'react'
import { Media } from '../Media/Media'
import { YouTubePlayer } from '../YouTubePlayer/YouTubePlayer'
import { VideoModalContentWrap } from '../VideoModalContentWrap/VideoModalContentWrap'
import { PortableText } from '../PortableText/PortableText'
import { Modal } from '../Modal/Modal'
import { styled } from '@liftfoils/styles'
import { getYouTubeIdFromUrl } from '@liftfoils/utils'
import { MediaFieldType, PortableTextBlocks } from '@liftfoils/models'
import { PlayIcon } from '@liftfoils/icons'
import { MediaPlaceholder } from '../Media/components/MediaPlaceholder'

const Overlay = styled('div', {
  zIndex: 1,
  display: 'grid',
  placeItems: 'end end',
  borderRadius: '$r2',
  border: '2px solid transparent',
  color: 'white',
  p: '$6',
  transition: 'opacity 100ms',
  svg: {
    width: '$8',
    height: '$8',
  },
  '@lg': {
    borderColor: '$teal500',
    opacity: 0,
  },
})
const Root = styled('button', {
  padding: '$0',
  background: 'none',
  width: '100%',
  minWidth: '130px',
  alignSelf: 'start',
  '&:hover': {
    [`${Overlay}`]: {
      opacity: 1,
    },
  },
  variants: {
    enableSnap: {
      true: {
        scrollSnapAlign: 'start',
      },
      false: {
        scrollSnapAlign: 'unset',
      },
    },
  },
})

const Thumbnail = styled('div', {
  borderRadius: '$r2',
  overflow: 'hidden',
  transform: 'translateZ(0)',
  display: 'grid',
  '&>*': {
    gridArea: '1/1',
  },
})

const VideoTitle = styled('div', {
  lift_font: 'body02',
  color: '$white_06',
  textAlign: 'left',
  mt: '$5',
  '.highlighted': {
    color: 'white',
    textTransform: 'uppercase',
  },
})
const CardInfo = styled('div', {
  alignSelf: 'start',
})
const CardTitle = styled('p', {
  lift_font: 'body02',
  color: 'white',
  mt: '$6',
  textTransform: 'uppercase',
  textAlign: 'left',
})
const CardSubtitle = styled('p', {
  lift_font: 'body02',
  color: '$white_06',
  textTransform: 'uppercase',
  textAlign: 'left',
})
interface YoutubeVideoThumbnailProps {
  card: {
    title?: string
    subtitle?: string
    thumbnail?: MediaFieldType
    richTitle?: PortableTextBlocks
    video?: {
      _type?: string
      url?: string
    }
    _key: string
    _type?: string
  }
  onRequestOpen?: () => void
  onRequestClose?: () => void
  enableSnap?: boolean
}

export const VideoCard: FC<YoutubeVideoThumbnailProps> = ({
  card,
  onRequestOpen,
  onRequestClose,
  enableSnap,
}) => {
  const [isVideoModalOpen, setVideoModalOpen] = useState(false)

  useEffect(() => {
    if (isVideoModalOpen && onRequestOpen) {
      onRequestOpen()
    } else if (!isVideoModalOpen && onRequestClose) {
      onRequestClose()
    }
  }, [isVideoModalOpen])

  return (
    <>
      <Root
        onClick={() => setVideoModalOpen(!isVideoModalOpen)}
        enableSnap={enableSnap}
      >
        {card.thumbnail && (
          <Thumbnail>
            <MediaPlaceholder ratio={'landscape2'}>
              <Media
                {...card.thumbnail}
                layout="fill"
                hardcropRatio="landscape2"
                sizes="(min-width: 1000px) 333px, 50vw"
                alt={card?.thumbnail?.alt ?? ''}
              />
            </MediaPlaceholder>
            <Overlay>
              <PlayIcon />
            </Overlay>
          </Thumbnail>
        )}
        <CardInfo>
          {card.title && <CardTitle>{card.title}</CardTitle>}
          {card.subtitle && <CardSubtitle>{card.subtitle}</CardSubtitle>}

          {card.richTitle && (
            <VideoTitle>
              <PortableText value={card.richTitle} />
            </VideoTitle>
          )}
        </CardInfo>
      </Root>
      {card.video && (
        <Modal
          isOpen={isVideoModalOpen}
          onRequestClose={() => setVideoModalOpen(false)}
          overlayCloseButton
          variant={'rounded'}
          overlayVariant="blur"
        >
          <VideoModalContentWrap>
            <YouTubePlayer videoId={getYouTubeIdFromUrl(card.video.url)} />
          </VideoModalContentWrap>
        </Modal>
      )}
    </>
  )
}
