export const SoundOff = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00604 2.74133V13.2587L2.22483 10.0226H0V5.97746H2.22483L6.00604 2.74133ZM14.8289 3.75262L16 4.77746L13.1801 8.00003L16 11.2226L14.8289 12.2474L12.1462 9.18162L9.46384 12.2474L8.29257 11.2226L11.1122 8.00003L8.29257 4.77746L9.46364 3.75262L12.1462 6.81844L14.8289 3.75262Z"
        fill="white"
      />
    </svg>
  )
}
