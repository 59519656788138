import { useState, createContext, FC, ReactNode } from 'react'

export type AssistanceContextType = {
  assistanceState: {
    isOpen: boolean
  }
  openAssistanceModal: () => void
  closeAssistanceModal: () => void
}

export const AssistanceContext = createContext<AssistanceContextType>({
  assistanceState: {
    isOpen: false,
  },
  openAssistanceModal: () => undefined,
  closeAssistanceModal: () => undefined,
})

export const AssistanceProvider: FC<{
  children?: ReactNode
}> = ({ children }) => {
  const [assistanceState, setAssistanceState] = useState<{
    isOpen: boolean
  }>({
    isOpen: false,
  })

  const openAssistanceModal = () =>
    setAssistanceState({
      isOpen: true,
    })

  const closeAssistanceModal = () =>
    setAssistanceState({
      isOpen: false,
    })

  return (
    <AssistanceContext.Provider
      value={{
        assistanceState,
        openAssistanceModal,
        closeAssistanceModal,
      }}
    >
      {children}
    </AssistanceContext.Provider>
  )
}
