import { FC } from 'react'

import { ShopifyMoneyFieldsFragment } from '@liftfoils/shopify-sdk'
import { ProductVariantPrice } from './ProductVariantPrice'
import { styled } from '@liftfoils/styles'
import { PricePrimitive } from './PricePrimitive'
import {
  useAccount,
  useClientVariants,
} from '@liftfoils/services/shopify-service'

const Wrap = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$4',
})
const LowOpacity = styled('span', {
  opacity: 0.6,
})
export const ContextualizedProductVariantPrice: FC<{
  price: ShopifyMoneyFieldsFragment
  compareAtPrice?: ShopifyMoneyFieldsFragment
  productVariantId: string
}> = ({ productVariantId, price, compareAtPrice }) => {
  const data = useClientVariants([productVariantId])
  const { isB2B } = useAccount()

  if (!isB2B)
    return <ProductVariantPrice price={price} compareAtPrice={compareAtPrice} />

  return (
    <Wrap>
      <LowOpacity>
        <ProductVariantPrice
          ignoreCompareAtPrice={isB2B}
          price={price}
          compareAtPrice={compareAtPrice}
        />
      </LowOpacity>
      <LowOpacity>MSRP</LowOpacity>
      <span>·</span>
      <span>
        {!data.variants ? (
          '...'
        ) : data.sumPrice ? (
          <PricePrimitive price={data.sumPrice} />
        ) : (
          'N/A'
        )}
      </span>
      <span>Wholesale</span>
    </Wrap>
  )
}
