import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { submitForm } from '@liftfoils/services/api-service'
import { validateRequired } from '../helpers/validateRequired'
import { validateEmail } from '../helpers/validateEmail'
import { useFormSubmitState } from '../helpers/useFormSubmitState'
import {
  FormTextField,
  SubmitButton,
  FormSelectField,
  FormPhoneField,
} from '../inputs'

interface ContactFormProps {
  className?: string
}

type FormValues = {
  firstName: string
  lastName: string
  desiredOperatingArea?: string
  businessName?: string
  businessDescription?: string
  phoneNumber: string
  email: string
  website?: string
  country: string
  programType: string
}

const defaultValues: FormValues = {
  firstName: '',
  lastName: '',
  desiredOperatingArea: '',
  businessName: '',
  businessDescription: '',
  phoneNumber: '',
  email: '',
  website: '',
  country: '',
  programType: 'Affiliate Or Retailer',
}

const Form = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$4',
})

export const PartnerForm: FC<ContactFormProps> = ({ className }) => {
  const { t } = useTranslation('forms')
  const form = useForm<FormValues>({
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = form

  const { invalid, success, setSuccess, setError, submitMessage } =
    useFormSubmitState<FormValues>({ form, defaultValues })

  const onSubmit = async (data: FormValues) => {
    try {
      const response = await submitForm('partnerForm', data)
      if (!response.ok) {
        return setError()
      }
      return setSuccess()
    } catch {
      return setError()
    }
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className={className}
      name="partner-form"
    >
      <FormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="given-name"
        name="firstName"
        label={t('partnerForm.firstNameFieldLabel')}
      />

      <FormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="family-name"
        name="lastName"
        label={t('partnerForm.lastNameFieldLabel')}
      />

      <FormTextField
        control={control}
        rules={validateRequired(t)}
        name="desiredOperatingArea"
        label={t('partnerForm.desiredOperatingAreaFieldLabel')}
      />
      <FormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="organization"
        name="businessName"
        label={t('partnerForm.businessNameFieldLabel')}
      />

      <FormTextField
        control={control}
        rules={validateRequired(t)}
        name="businessDescription"
        label={t('partnerForm.businessDescriptionFieldLabel')}
        multiline
        rows={8}
      />

      <FormPhoneField
        control={control}
        rules={validateRequired(t)}
        name="phoneNumber"
        label={t('partnerForm.phoneFieldLabel')}
        form={form}
      />

      <FormTextField
        control={control}
        rules={{
          ...validateRequired(t),
          ...validateEmail(t),
        }}
        autoComplete="email"
        type="email"
        name="email"
        label={t('partnerForm.emailFieldLabel')}
      />

      <FormTextField
        control={control}
        autoComplete="url"
        name="website"
        label={t('partnerForm.websiteFieldLabel')}
      />
      <FormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="country-name"
        name="country"
        label={t('partnerForm.countryFieldLabel')}
      />

      <FormSelectField
        control={control}
        rules={validateRequired(t)}
        name="programType"
        label={t('partnerForm.programFieldLabel')}
      >
        <option value="Affiliate Or Retailer">
          {t('partnerForm.programTypeOptions.affiliateOrRetailer')}
        </option>
      </FormSelectField>

      <SubmitButton
        text={t('partnerForm.submitButtonLabel')}
        helpText={submitMessage}
        invalid={invalid}
        success={success}
        loading={isSubmitting}
      />
    </Form>
  )
}
