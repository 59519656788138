import { styled } from '@liftfoils/styles'
import { FocusEvent, ReactNode, forwardRef, useEffect, useState } from 'react'
import { TextFieldPropsType } from './TextFieldPropsType'

const FieldContainer = styled('div', {
  width: '100%',
})

const InputContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  lift_font: 'body02',
})

const Input = styled('input', {
  margin: 0,
  lift_font: 'body02',
  display: 'block',
  padding: '28px $8 15px',
  borderRadius: '$r1',
  border: '1px solid',
  borderColor: '$gray200',
  color: '$gray500',
  appearance: 'none',
  '&::placeholder': {
    color: '$gray300',
  },
  '&:focus': {
    borderColor: '$teal500',
    outline: '0',
    $inputShadow: '$colors$teal500',
  },
  '&:-webkit-autofill,&:-webkit-autofill:focus, &:-webkit-autofill:hover, &:-webkit-autofill:active':
    {
      '-webkit-box-shadow': '0 0 0 60px $colors$white inset !important',
      backgroundClip: 'content-box !important',
      backgroundColor: '$white !important',
    },
  variants: {
    multiline: {
      true: {
        resize: 'none',
      },
    },
    isActive: {
      true: {
        '&::placeholder': {
          opacity: 1,
          transition: 'opacity 200ms cubic-bezier(0.6, 0.04, 0.98, 0.335) 10ms',
        },
      },
      false: {
        '&::placeholder': {
          opacity: 0,
          transition: 'none',
        },
      },
    },
    isInvalid: {
      true: {
        borderColor: '$red500',
        color: '$red500',
        '&:focus': {
          borderColor: '$red500',
          $inputShadow: '$colors$red500',
          outline: '0',
        },
      },
    },
    isDisabled: {
      true: {
        backgroundColor: '$gray500_01',
      },
      false: {
        backgroundColor: 'white',
      },
    },
  },
})

const Label = styled('label', {
  zIndex: 2,
  margin: 0,
  border: 0,
  top: 0,
  left: 0,
  transition: 'transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  transformOrigin: 'left top',
  cursor: 'text',
  pointerEvents: 'none',
  color: '$gray500',
  position: 'absolute',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  variants: {
    isActive: {
      true: {
        transform: 'translate(25px, 14px) scale(0.75)',
      },
      false: {
        transform: 'translate(25px, 23px) scale(1)',
      },
    },
    isInvalid: {
      true: {
        color: '$red500',
      },
    },
  },
})

const HelpText = styled('div', {
  position: 'relative',
  lift_font: 'body06',
  mt: '$4',
  variants: {
    isInvalid: {
      true: {
        color: '$red500',
      },
    },
  },
})

export const TextField = forwardRef<
  HTMLInputElement,
  TextFieldPropsType & {
    startDecoration?: ReactNode
    endDecoration?: ReactNode
  }
>((props, ref) => {
  const {
    autoComplete,
    disabled,
    id,
    children,
    invalid,
    helpText,
    label,
    readOnly,
    onBlur,
    onFocus,
    value,
    type = 'text',
    style,
    className,
    placeholder,
    multiline,
    startDecoration,
    endDecoration,
    ...rest
  } = props

  const isValueSet = (value?: string): boolean =>
    typeof value === 'string' && value.length > 0

  const helpId = helpText ? `${id}__hint` : undefined

  const [isActive, setIsActive] = useState<boolean>(() => isValueSet(value))

  const handleOnBlur = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setIsActive(isValueSet(event.target.value))
    if (typeof onBlur === 'function') {
      onBlur(event)
    }
  }
  const handleOnFocus = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setIsActive(true)
    if (typeof onBlur === 'function') {
      onBlur(event)
    }
  }

  useEffect(() => {
    setIsActive(isValueSet(value) ?? false)
  }, [value])

  const htmlTag = multiline ? 'textarea' : 'input'

  return (
    <FieldContainer className={className}>
      <InputContainer>
        {startDecoration !== undefined && startDecoration}

        {label && (
          <Label htmlFor={id} isInvalid={invalid} isActive={isActive}>
            {label}
          </Label>
        )}

        <Input
          {...rest}
          aria-describedby={helpId}
          aria-errormessage={invalid && helpId ? helpId : undefined}
          aria-invalid={invalid}
          disabled={disabled}
          id={id}
          readOnly={readOnly}
          ref={ref}
          type={type}
          autoComplete={autoComplete}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          isInvalid={invalid}
          isDisabled={disabled}
          isActive={isActive}
          value={isValueSet(value) ? value : ''}
          placeholder={placeholder}
          multiline={multiline}
          as={htmlTag}
        />

        {endDecoration !== undefined && endDecoration}
      </InputContainer>
      {helpText && (
        <HelpText id={helpId} isInvalid={invalid}>
          {helpText}
        </HelpText>
      )}
    </FieldContainer>
  )
})
