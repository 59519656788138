import {
  createShopifySdk,
  ShopifyGetSearchResultsQueryVariables,
  formatShopifyProductBasic,
  ShopifyResolvedProductBasic,
  ShopifySearchType,
} from '@liftfoils/shopify-sdk'
import { createStorefrontApiClient } from './createStorefrontApiClient'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { ShopifyCountryCode } from '@liftfoils/shopify-sdk'

export const getSearchResults = async (
  variables: ShopifyGetSearchResultsQueryVariables,
  locale?: string,
) => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)

  const shopifyClient = createStorefrontApiClient(localeId)

  const searchResultsVariables: ShopifyGetSearchResultsQueryVariables = {
    ...variables,
    types: [ShopifySearchType.Product],
    regionId: regionId as ShopifyCountryCode,
    productFilters: [
      {
        productMetafield: {
          key: 'exclude_from_search',
          value: 'false',
          namespace: 'custom',
        },
      },
    ],
  }

  return createShopifySdk(shopifyClient)
    .getSearchResults(searchResultsVariables)
    .then((res) => {
      if (!res.data.search.nodes) {
        return {
          products: [],
        }
      }

      const filteredProducts = res.data.search.nodes.reduce<
        ShopifyResolvedProductBasic[]
      >((acc, val) => {
        if (val.__typename === 'Product') {
          return [...acc, formatShopifyProductBasic(val)]
        }
        return acc
      }, [])

      return {
        products: filteredProducts,
      }
    })
}
