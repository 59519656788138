import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { PageBuilderSection, PageResponse } from '@liftfoils/models'
import { coalesceLocaleField } from './helpers/coalesceLocaleField'
import { createPortableTextProjection } from './projections/createPortableTextProjection'
import { LocaleId, ROUTE_BASE_EVENTS } from '@liftfoils/configs'
import { createLinkProjection } from './projections/createLinkProjection'
import { coalescePortableToPlainText } from './helpers/coalescePortableToPlain'
import { createMediaProjection } from './projections/createMediaProjection'

export const createEventPageProjection = (localeId: LocaleId) => {
  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`{
    "initialHeaderStyle": 'transparent',
    "showFooter": true,
    title,
    "pageBuilder": [
      {
        "_type": 'sectionEditorialHero',
        "_key": _id + '1',
        ${coalesceLocaleField('title', localeId)},
        background${MEDIA_PROJECTION},
        category,
        "categoryPath": "${ROUTE_BASE_EVENTS}?filter=" + category,
        "parentPath": "${ROUTE_BASE_EVENTS}",
        date,
      },
      {
        "_type": 'sectionEventContent',
        "_key": _id + '2',
        ${coalesceLocaleField(
          'content',
          localeId,
        )}[]${PORTABLE_TEXT_PROJECTION},
        startDate,
        endDate,
        address,
        buttonLink${LINK_PROJECTION},
      }
    ],
    "seo":{
      ${coalesceLocaleField('title', localeId, 'metaTitle')},
      ${coalesceLocaleField('title', localeId, 'ogTitle')},
      ${coalescePortableToPlainText(
        'introduction',
        localeId,
        'metaDescription',
      )},
      ${coalescePortableToPlainText('introduction', localeId, 'ogDescription')},
      "ogImage":background${MEDIA_PROJECTION}
    }
  }`
}

export const getEventPageByPath = async (
  path: string,
  localeRegionString: string,
  preview = false,
): Promise<PageResponse<PageBuilderSection[]>> => {
  const [, localeId] = getLocaleRegionIdFromPath(localeRegionString)
  const EVENT_PROJECTION = createEventPageProjection(localeId)

  const query = groq`*[!(_id in path('drafts.**')) && _type == "event" && defined(path.current) && path.current == "${path}"][0]${EVENT_PROJECTION}`

  const pageData = await getClient(preview).fetch(query)

  return pageData
}
