import { formatShopifyImage, formatShopifyImages } from './formatShopifyImage'
import { ShopifyProductBasicFieldsFragment } from '../generated/schemaSdk'
import { formatProductMetafields } from './formatProductMetafields'
import { formatShopifyVariantBasic } from './formatShopifyVariantBasic'

export const formatShopifyProductBasic = (
  product: ShopifyProductBasicFieldsFragment,
) => ({
  ...product,
  featuredImage: product.featuredImage
    ? formatShopifyImage(product.featuredImage)
    : null,
  images: product.images?.nodes
    ? formatShopifyImages(product.images.nodes)
    : null,
  metafields: formatProductMetafields(product.metafields),
  variants: { nodes: product.variants.nodes.map(formatShopifyVariantBasic) },
  __typename: 'ResolvedProductBasic',
})

export type ShopifyResolvedProductBasic = ReturnType<
  typeof formatShopifyProductBasic
>
