import React from 'react'

export const CloseXL = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23 1.00073L1 23.0029" stroke="#474646" />
      <path d="M23 23.0021L1 1" stroke="#474646" />
    </svg>
  )
}
