import { styled } from '@liftfoils/styles'
import React from 'react'
const TRANSITION_TIME = '200ms'
const pillStyle = {
  whiteSpace: 'nowrap',
  borderRadius: '$rMax',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: `fill ${TRANSITION_TIME}, color ${TRANSITION_TIME}, border-color ${TRANSITION_TIME}, background-color ${TRANSITION_TIME}`,

  span: {
    mx: '0.5em',
  },
}
const hoverState = {
  '&:not([disabled]):hover': {
    backgroundColor: '$teal500',
  },
}
export const StyledClickable = styled('button', {
  variants: {
    round: {
      true: {
        borderRadius: '50%',
      },
    },
    appearance: {
      outlineWhite: {
        ...pillStyle,
        border: '1px solid',
        color: 'white',
        borderColor: 'white',
        '&:not([disabled]):hover': {
          borderColor: 'white',
          background: 'white',
          color: '$gray500',
        },
      },
      outlineGray300: {
        ...pillStyle,
        border: '1px solid',
        color: '$gray300',
        borderColor: '$gray300',
        '&:not([disabled]):hover': {
          borderColor: '$gray500',
          bg: '$gray500',
          color: 'white',
        },
      },
      outlineGray300Alt: {
        ...pillStyle,
        border: '1px solid',
        color: '$gray300',
        borderColor: '$gray300_03',
        '&:not([disabled]):hover': {
          borderColor: '$gray300',
        },
      },
      outlineTeal500: {
        ...pillStyle,
        border: '1px solid',
        color: '$teal500',
        borderColor: '$teal500',
        '&:not([disabled]):hover': {
          borderColor: '$gray300',
          color: '$gray300',
        },
        '&:disabled, &[disabled]': {
          bg: '$gray100',
          color: '$gray300',
          borderColor: 'transparent',
        },
      },
      solidGray300: {
        ...pillStyle,
        bg: '$gray300',
        color: 'white',
        '&:not([disabled]):hover': {
          borderColor: '$gray300_08',
          bg: '$gray300_08',
          color: 'white',
        },
      },
      solidGray500: {
        ...pillStyle,
        bg: '$gray500',
        color: 'white',
        '&:not([disabled]):hover': {
          borderColor: '$gray400',
          bg: '$gray400',
          color: 'white',
        },
      },
      solidTeal500: {
        ...pillStyle,
        color: 'white',
        backgroundColor: '$teal500',
        '&:disabled': {
          color: '$white_06',
          backgroundColor: '$gray500_06',
          cursor: 'auto !important',
        },
        '&:not([disabled]):hover': {
          backgroundColor: '$teal600',
        },
      },
      solidWhite: {
        ...pillStyle,
        bg: 'white',
        color: '$gray500',
        justifyContent: 'center',
        '&:disabled, &[disabled]': {
          bg: '$gray100',
          color: '$gray300',
        },
        '&:not([disabled]):hover': {
          backgroundColor: '$teal500',
          color: '$white',
          fill: '$white',
        },
      },
      bareWhite: {
        ...pillStyle,
        color: 'white',
      },
      bareGray: {
        ...pillStyle,
        color: '$gray500',
      },
      blur: {
        ...pillStyle,
        color: 'white',
        backdropFilter: 'blur(10px)',
        backgroundColor: '$white_01',
        '&:disabled, &[disabled]': {
          color: '$gray300',
        },
        ...hoverState,
      },
      blackBlur: {
        ...pillStyle,
        color: 'white',
        backdropFilter: 'blur(10px)',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        textTransform: 'capitalize',
        ...hoverState,
      },
      articleLink: {
        color: '$gray300',
        transition: `color ${TRANSITION_TIME}`,
        px: '0 !important',

        '&:not([disabled]):hover': {
          color: '$teal500',
        },
      },
      grayUnderline: {
        transition: `color ${TRANSITION_TIME}`,
        color: '$gray300',
        lift_font: 'body03',
        borderBottom: '1px solid currentColor',
        '&:not([disabled]):hover': {
          color: '$teal500',
        },
      },
      socialMedia: {
        ...pillStyle,
        width: '$buttonHeightM',
        height: '$buttonHeightM',
        px: '$6',
        py: '$6',
        backgroundColor: '$teal500',

        svg: {
          width: '100%',
          path: {
            fill: '$white',
          },
        },
        '&:not([disabled]):hover': {
          color: '$white',
          backgroundColor: '$teal600',
        },
        '&:focus-visible': {
          outlineOffset: '2px',
        },
      },
      socialMediaWhite: {
        ...pillStyle,
        width: '$buttonHeightM',
        height: '$buttonHeightM',
        px: '$6',
        py: '$6',
        backgroundColor: 'white',
        svg: {
          width: '100%',
          path: {
            fill: '$gray500',
          },
        },
        '&:not([disabled]):hover': {
          backgroundColor: '$teal500',
          color: '$white',
        },
        '&:focus-visible': {
          outlineOffset: '2px',
        },
      },
      shareIcon: {
        ...pillStyle,
        width: '$buttonHeightS',
        height: '$buttonHeightS',
        px: '$5',
        py: '$5',
        backgroundColor: '$white_02',
        svg: {
          width: '100%',
          path: {
            fill: '$white',
          },
        },
        ...hoverState,
        '&:focus-visible': {
          outlineOffset: '2px',
        },
      },
      gridStretch: {
        display: 'grid',
        placeItems: 'stretch',
      },
      gridCenter: {
        display: 'grid',
        placeItems: 'center',
      },
      flex: {
        display: 'flex',
      },
      block: {
        display: 'block',
      },
      blockWithOutline: {
        display: 'block',
        borderRadius: '$r1',
        '&:focus-visible': {
          outlineOffset: '3px',
        },
      },
      undefined: {
        background: 'none',
      },
    },
    size: {
      large: {
        lift_font: 'body02',
        px: '$9',
        height: '$buttonHeightL',
      },
      medium: {
        lift_font: 'body02',
        px: '$9',
        height: '$buttonHeightM',
      },
      small: {
        lift_font: 'body03',
        px: '$7',
        height: '$buttonHeightS',
      },
      wide: {
        lift_font: 'body02',
        width: '100%',
        height: '$buttonHeightL',
      },
      navHeaderLarge: {
        lift_font: 'body02',
        px: '$7',
        height: '$buttonHeightS',
      },
    },
  },
  compoundVariants: [
    {
      appearance: 'solidWhite',
      size: 'medium',
      css: {
        paddingBottom: 1.5,
      },
    },
    {
      appearance: 'solidWhite',
      size: 'large',
      css: {
        paddingBottom: 1.5,
      },
    },
    {
      appearance: 'solidGray500',
      size: 'medium',
      css: {
        paddingBottom: 1.5,
      },
    },
    {
      appearance: 'solidGray500',
      size: 'large',
      css: {
        paddingBottom: 1.5,
      },
    },
    {
      appearance: 'outlineGray300',
      size: 'medium',
      css: {
        paddingBottom: 1,
      },
    },
    {
      size: 'large',
      round: true,
      css: {
        height: '$buttonHeightL',
        width: '$buttonHeightL',
        padding: 0,
      },
    },
    {
      size: 'medium',
      round: true,
      css: {
        height: '$buttonHeightM',
        width: '$buttonHeightM',
        padding: 0,
      },
    },
    {
      size: 'small',
      round: true,
      css: {
        height: '$buttonHeightS',
        width: '$buttonHeightS',
        padding: 0,
      },
    },
  ],
  padding: 0,
  bg: 'transparent',
  '&:focus-visible': {
    outline: '$teal500_05 solid 3px',
    outlineOffset: '-2px',
  },
  '&:disabled': {
    pointerEvents: 'none',
  },
  color: 'currentColor',
})

type Appearance =
  | 'outlineWhite'
  | 'outlineTeal500'
  | 'outlineGray300'
  | 'outlineGray300Alt'
  | 'solidGray300'
  | 'solidGray500'
  | 'solidTeal500'
  | 'solidWhite'
  | 'bareWhite'
  | 'bareGray'
  | 'blur'
  | 'blackBlur'
  | 'socialMedia'
  | 'socialMediaWhite'
  | 'shareIcon'
  | 'articleLink'
  | 'grayUnderline'
  | 'block'
  | 'gridStretch'
  | 'gridCenter'
  | 'blockWithOutline'
  | 'flex'
  | undefined

export type StyledClickableProps = {
  size?: 'large' | 'medium' | 'small' | 'wide' | 'navHeaderLarge'
  appearance?: Appearance
  tabIndex?: number
  disabled?: boolean
  round?: boolean
  id?: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onFocus?: () => void
  onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void
}
