import { styled } from '@liftfoils/styles'
import { forwardRef } from 'react'
import { SelectFieldPropsType } from './SelectFieldPropsType'

export const FieldContainer = styled('div', {
  width: '100%',
})

export const SelectContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  lift_font: 'body02',
  '&::after': {
    position: 'absolute',
    content: '',
    right: 'calc(20% + 10px)',
    height: '30px',
    borderRight: '1px solid $gray200',
    pointerEvents: 'none',
  },
  '&::before': {
    pointerEvents: 'none',
    width: '0px',
    height: '0px',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid $gray300',
    position: 'absolute',
    content: '',
    right: '10%',
  },
})

export const Select = styled('select', {
  margin: 0,
  appearance: 'none',
  lift_font: 'body02',
  display: 'block',
  padding: '28px calc($8*2) 15px $8',
  cursor: 'pointer',
  borderRadius: '$r1',
  border: '1px solid',
  borderColor: '$gray200',
  backgroundColor: 'white',
  color: '$gray500',
  '&:focus': {
    borderColor: '$teal500',
    outline: '0',
    $inputShadow: '$colors$teal500',
  },
  variants: {
    isInvalid: {
      true: {
        borderColor: '$red500',
        color: '$red500',
        '&:focus': {
          borderColor: '$red500',
          outline: 'none',
          $inputShadow: '$colors$red500',
        },
      },
    },
    isDisabled: {
      true: {
        backgroundColor: '$gray500_01',
      },
      false: {
        backgroundColor: 'white',
      },
    },
  },
})

const Label = styled('label', {
  margin: 0,
  border: 0,
  top: 0,
  left: 0,
  transformOrigin: 'left top',
  cursor: 'text',
  pointerEvents: 'none',
  color: '$gray300',
  position: 'absolute',
  transform: 'translate(25px, 14px) scale(0.75)',
  variants: {
    isInvalid: {
      true: {
        color: '$red500',
      },
    },
  },
})

const HelpText = styled('div', {
  position: 'relative',
  lift_font: 'body06',
  mt: '$4',
  variants: {
    isInvalid: {
      true: {
        color: '$red500',
      },
    },
  },
})

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldPropsType>(
  (props, ref) => {
    const {
      autoComplete,
      disabled,
      id,
      children,
      invalid,
      helpText,
      label,
      value,
      style,
      className,
      ...rest
    } = props

    const helpId = helpText ? `${id}__hint` : undefined

    return (
      <FieldContainer className={className}>
        <SelectContainer>
          {label && (
            <Label htmlFor={id} isInvalid={invalid}>
              {label}
            </Label>
          )}

          <Select
            {...rest}
            aria-describedby={helpId}
            aria-errormessage={invalid && helpId ? helpId : undefined}
            aria-invalid={invalid}
            disabled={disabled}
            id={id}
            ref={ref}
            autoComplete={autoComplete}
            isInvalid={invalid}
            isDisabled={disabled}
            value={value}
          >
            {children}
          </Select>
        </SelectContainer>
        {helpText && (
          <HelpText id={helpId} isInvalid={invalid}>
            {helpText}
          </HelpText>
        )}
      </FieldContainer>
    )
  },
)
