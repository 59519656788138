import React, { FC, useEffect, useRef, useState } from 'react'
import { Close } from '@liftfoils/icons'
import { styled } from '@liftfoils/styles'
import { useTranslation } from 'react-i18next'
import { Loader } from '../Loader/Loader'
import { ShopifyResolvedCart } from '@liftfoils/shopify-sdk'

const GiftCardWrap = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'stretch',
})

const GiftCardButton = styled('button', {
  background: 'none',
  lift_font: 'body02',
  color: '$teal500',
  cursor: 'pointer',
  justifySelf: 'start',
  height: 55,

  '&:hover': {
    color: '$teal600',
  },
})

const GiftCardInputWrap = styled('form', {
  width: '100%',
  position: 'relative',
  display: 'flex',
})

const GiftCardInput = styled('input', {
  height: '55px',
  width: '100%',
  borderRadius: '$rMax',
  border: '1px solid $gray200',
  $py: '0',
  $px: '20px',
  fontSize: '$4',
})

const ButtonWrap = styled('div', {
  position: 'absolute',
  right: '0',
  minWidth: 90,
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
})

const GiftCardInputButton = styled('button', {
  height: '100%',
  background: 'none',
  fontSize: '$4',
  paddingInline: '$6',
})

const CodesWrap = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '$4',
  minHeight: 55,
})

const PromoCode = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '$gray300',
  color: 'white',
  borderRadius: '$rMax',
  $py: '10px',
  $px: '20px',
  height: 42,
})

const RemovePromoCodeButton = styled('button', {
  background: 'none',
  width: '15px',
  height: '15px',
  $px: '10px',

  svg: {
    fill: 'white',
    width: '15px',
    height: '15px',
  },
})

const ErrorMessage = styled('span', {
  lift_font: 'body06',
  color: '$red500',
})

type GiftCardProps = {
  cart: ShopifyResolvedCart | null
  updateDiscountCodes: (discountCodes: string[]) => void
}

export const GiftCard: FC<GiftCardProps> = ({ cart, updateDiscountCodes }) => {
  const { t } = useTranslation('cart')
  const giftCardInputRef = useRef<HTMLInputElement>(null)
  const [promoCodeInputExpanded, setPromoCodeInputExpanded] = useState(false)
  const [cartHasPromoCodeError, setCartHasPromoCodeError] = useState(false)
  const [loading, setLoading] = useState(false)
  const discountCodes = cart?.discountCodes ?? []

  const cartHasValidPromoCode = discountCodes?.find(
    (discount) => discount.applicable,
  )
  const cartHasInvalidPromoCode = discountCodes?.find(
    (discount) => !discount.applicable && !discount.code.startsWith('B2B'),
  )

  useEffect(() => {
    if (cartHasInvalidPromoCode) {
      setCartHasPromoCodeError(true)
      discountCodes.forEach((discount) => {
        if (!discount.applicable) handleRemoveDiscountCode(discount.code)
      })
    }
  }, [cartHasInvalidPromoCode])

  useEffect(() => {
    setCartHasPromoCodeError(false)
  }, [cartHasValidPromoCode])

  const deduplicatedCodes = discountCodes.filter((discount, index, self) => {
    return index === self.findIndex((t) => t.code === discount.code)
  })

  const handleUpdateDiscountCode = async (discountCode: string) => {
    if (!cart) return
    const currentCodes = cart.discountCodes.map((discount) => discount.code)
    const newCodes = [...currentCodes, discountCode]
    return updateDiscountCodes(newCodes)
  }

  const handleRemoveDiscountCode = async (discountCode: string) => {
    if (!cart) return
    const currentCodes = cart.discountCodes.map((discount) => discount.code)
    const newCodes = currentCodes.filter((code) => code !== discountCode)
    return updateDiscountCodes(newCodes)
  }

  return (
    <GiftCardWrap>
      {!cartHasValidPromoCode &&
        (!promoCodeInputExpanded ? (
          <GiftCardButton
            onClick={() => {
              setPromoCodeInputExpanded(true)
            }}
          >
            {t('enterGiftCard')}
          </GiftCardButton>
        ) : (
          <GiftCardInputWrap
            onSubmit={async (e) => {
              e.preventDefault()
              if (!giftCardInputRef?.current?.value) {
                return setCartHasPromoCodeError(true)
              }

              setLoading(true)
              const promoCodeFromInput = giftCardInputRef.current.value
              await handleUpdateDiscountCode(promoCodeFromInput)
              setLoading(false)
            }}
          >
            <GiftCardInput
              placeholder={
                t('enterGiftCard') ?? 'Enter gift card or promo code'
              }
              ref={giftCardInputRef}
            />
            <ButtonWrap>
              {loading ? (
                <Loader />
              ) : (
                <GiftCardInputButton>{t('apply')}</GiftCardInputButton>
              )}
            </ButtonWrap>
          </GiftCardInputWrap>
        ))}

      {cart && cartHasValidPromoCode && deduplicatedCodes && (
        <CodesWrap>
          {deduplicatedCodes.map((discount) => (
            <PromoCode key={discount.code}>
              <span>{discount.code}</span>
              <RemovePromoCodeButton
                onClick={async () => {
                  await handleRemoveDiscountCode(discount.code)
                }}
              >
                <Close />
              </RemovePromoCodeButton>
            </PromoCode>
          ))}
        </CodesWrap>
      )}
      {cartHasPromoCodeError && (
        <ErrorMessage>{t('enterValidCode')}</ErrorMessage>
      )}
    </GiftCardWrap>
  )
}
