import React from 'react'
import { styled } from '@liftfoils/styles'

const StyledPath = styled('path', {
  stroke: 'currentColor',
  transition: 'stroke 150ms',
})

export const ArrowHeadDown = () => {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledPath d="M12.9995 0.443359L6.88453 6.55836L0.769531 0.443359" />
    </svg>
  )
}
