import { createStorefrontApiClient } from './createStorefrontApiClient'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'

import {
  ShopifyCountryCode,
  ShopifyResolvedVariantBasic,
  createShopifySdk,
  formatShopifyVariantBasic,
} from '@liftfoils/shopify-sdk'

export const getProductVariantsByIds: (
  ids: string[],
  locale: string,
) => Promise<Array<ShopifyResolvedVariantBasic | null>> = async (
  ids,
  locale,
) => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
  const shopifyClient = createStorefrontApiClient(localeId)

  return await createShopifySdk(shopifyClient)
    .getNodesById({
      productBasic: [],
      variantBasic: ids,
      collectionBasic: [],
      regionId: regionId as ShopifyCountryCode,
    })
    .then((res) => {
      return res.data.variantBasic.reduce((acc, val) => {
        return val && val.__typename === 'ProductVariant'
          ? [...acc, formatShopifyVariantBasic(val)]
          : acc
      }, [] as Array<ShopifyResolvedVariantBasic>)
    })
}
