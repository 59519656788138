export const Play = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.542 8L4.45801 12V4L11.542 8Z" fill="white" />
    </svg>
  )
}
