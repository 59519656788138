import React from 'react'

export const Close = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 21 21"
      width={21}
      height={21}
    >
      <polygon
        className="st0"
        points="18.1,3.4 17.4,2.6 10.5,9.5 3.6,2.6 2.9,3.4 9.8,10.2 2.9,17.1 3.6,17.9 10.5,11 17.4,17.9 18.1,17.1
	11.2,10.2 "
      />
    </svg>
  )
}
