import React from 'react'
import { Properties } from 'csstype'

export const ArrowDown = ({
  pathStyle,
}: {
  pathStyle?: Properties<string | number, string>
}) => {
  return (
    <svg
      height="10"
      viewBox="0 0 10 10"
      width="10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        style={pathStyle}
        d="m4.6725 6.635-2.655-2.667a.445.445 0 0 1 -.123-.304.4331.4331 0 0 1 .427-.439h.006a.447.447 0 0 1 .316.135l2.357 2.365 2.356-2.365a.458.458 0 0 1 .316-.135.433.433 0 0 1 .433.433v.006a.4189.4189 0 0 1 -.123.3l-2.655 2.671a.4451.4451 0 0 1 -.327.14.464.464 0 0 1 -.328-.14z"
      ></path>
    </svg>
  )
}
