import { forwardRef, useEffect, useState } from 'react'
import { styled } from '@liftfoils/styles'
import { CountryCodeSelectField } from './CountryCodeSelectField'
import { getCountryDataByCode } from './helpers/getCountryDataByCode'
import { TextField } from '../TextField/TextField'
import { TextFieldPropsType } from '../TextField/TextFieldPropsType'
import { UseFormReturn } from 'react-hook-form'
import { DEFAULT_REGION_ID } from '@liftfoils/configs'

export type PhoneFieldsProps = Omit<
  TextFieldPropsType,
  'autoComplete' | 'type'
> & {
  defaultCountryCode?: string
  form: UseFormReturn<any>
  onChange?: (value: string) => void
}

const StyledCountryCodeSelectField = styled(CountryCodeSelectField, {
  '&:focus-within': {
    zIndex: 1,
  },
  select: {
    borderRadius: '$r1 0 0 $r1',
    borderRight: 'none',
  },
})

const StyledNumberField = styled(TextField, {
  '&:focus-within': {
    zIndex: 1,
  },
  input: {
    borderRadius: '0 $r1 $r1 0',
  },
})

const Container = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'minmax(100px, 135px) minmax(160px, 1fr)',
})

export const PhoneField = forwardRef<HTMLInputElement, PhoneFieldsProps>(
  (props, ref) => {
    const {
      defaultCountryCode = DEFAULT_REGION_ID,
      name,
      label,
      form,
      onChange,
      value,
      ...restOfProps
    } = props

    const { setValue } = form

    const [codeValue, setCodeValue] = useState<string | undefined>(
      defaultCountryCode,
    )
    const [numberValue, setNumberValue] = useState<string | undefined>(value)

    useEffect(() => {
      const currentCountryData = getCountryDataByCode(codeValue)
      const dialCode = currentCountryData?.dialCode || ''

      const inputValue = numberValue ? `${dialCode}${numberValue}` : ''

      if (typeof onChange === 'function') {
        //Trick: for the reset() onChange would be called, which causes immediate validation. We don't want to see errors in the form when using reset()
        inputValue
          ? onChange(inputValue)
          : name &&
            setValue(name, '', { shouldValidate: false, shouldDirty: false })
      }
    }, [codeValue, numberValue, onChange, setValue, name])

    useEffect(() => {
      if (!value) {
        setNumberValue('')
        setCodeValue(defaultCountryCode)
      }
    }, [value, defaultCountryCode])

    return (
      <Container>
        <StyledCountryCodeSelectField
          onChange={(e) => setCodeValue(e.target.value)}
          value={codeValue}
        />
        <StyledNumberField
          ref={ref}
          autoComplete="tel-national"
          type="tel"
          label={label}
          onChange={(e) => setNumberValue(e.target.value)}
          name={name}
          value={numberValue}
          {...restOfProps}
        />
      </Container>
    )
  },
)
