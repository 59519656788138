import {
  DEFAULT_LOCALE_ID,
  LocaleId,
  ROUTE_BASE_STORIES,
} from '@liftfoils/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { coalescePortableToPlainText } from '../helpers/coalescePortableToPlain'
import { createPortableTextProjection } from './createPortableTextProjection'
import { createEditorialPortableTextProjection } from './createEditorialPortableTextProjection'
import { createMediaProjection } from './createMediaProjection'

export const createStoryPageProjection = (localeId: LocaleId) => {
  const EDITORIAL_PORTABLE_TEXT_PROJECTION =
    createEditorialPortableTextProjection(localeId)
  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`{
    "initialHeaderStyle": 'transparent',
    "showFooter": true,
    "title": title,
    "pageBuilder": [
      {
        "_type": 'sectionEditorialHero',
        "_key": _id + '1',
        ${coalesceLocaleField(
          'shortDescription',
          localeId,
        )}[]${PORTABLE_TEXT_PROJECTION},
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField('headerVideo', localeId)},
        background${MEDIA_PROJECTION},
        "category": categoryNew->{title}.title,
        "categoryPath": "${ROUTE_BASE_STORIES}?filter=" + categoryNew->{slug}.slug.current,
        "parentPath": "${ROUTE_BASE_STORIES}",
        date,
      },
      {
        "_type": 'sectionEditorialContent',
        "_key": _id + '2',
        ${coalesceLocaleField(
          'content',
          localeId,
        )}[]${EDITORIAL_PORTABLE_TEXT_PROJECTION},
        "shopifyIds": {
          "productBasic": [
            ...coalesce(content.${localeId}, content.${DEFAULT_LOCALE_ID})[]{
              _type == "sectionShopTheStory" => {
                "id": product->{
                  "id":
                    select(
                      _type == "efoil"   => mainProduct -> store.gid,
                      _type == "product" => store.gid,
                    )
                  }.id,
                },
              }.id,
            ],
          },
          "theme": 'dark'
      },
      {
        "_type": 'sectionShare',
        "_key": _id + '3',
      },
      {
        "_type": 'sectionRelatedStories',
        "_key": _id + '4',
        "relatedStories": relatedStories[]->{
          ${coalesceLocaleField('title', localeId)},
          "path": path.current,
          "category": categoryNew->{title}.title,
          "categoryPath": "${ROUTE_BASE_STORIES}?filter=" + categoryNew->{slug}.slug.current,
          date,
          background${MEDIA_PROJECTION}
        }
      }
    ],
    "seo":{
      ${coalesceLocaleField('title', localeId, 'metaTitle')},
      ${coalesceLocaleField('title', localeId, 'ogTitle')},
      ${coalescePortableToPlainText(
        'shortDescription',
        localeId,
        'metaDescription',
      )},
      ${coalescePortableToPlainText(
        'shortDescription',
        localeId,
        'ogDescription',
      )},
      "ogImage":background${MEDIA_PROJECTION}
    }
  }`
}
