import { GraphQLClient } from 'graphql-request'
import { CUSTOMER_API_URL } from './shopify.config'

export const createCustomerAccountApiClient = (
  localeId: string,
  accessToken: string,
) => {
  return new GraphQLClient(CUSTOMER_API_URL, {
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': localeId,
      Authorization: accessToken,
    },
  })
}
