import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { submitForm } from '@liftfoils/services/api-service'
import { validateRequired } from '../helpers/validateRequired'
import { validateEmail } from '../helpers/validateEmail'
import { useFormSubmitState } from '../helpers/useFormSubmitState'
import { FormTextField, SubmitButton } from '../inputs'
import { useRouter } from 'next/router'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'

interface ContactFormProps {
  className?: string
}

type FormValues = {
  name: string
  email: string
  message: string
  country: string
}

const defaultValues: FormValues = {
  name: '',
  email: '',
  message: '',
  country: '',
}

const Form = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$4',
})

export const AskQuestionForm: FC<ContactFormProps> = ({ className }) => {
  const { t } = useTranslation('forms')
  const form = useForm<FormValues>({
    defaultValues,
  })

  const { locale, asPath } = useRouter()
  const [, regionId] = getLocaleRegionIdFromPath(locale)

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    clearErrors,
  } = form

  const { invalid, success, setSuccess, setError, submitMessage } =
    useFormSubmitState<FormValues>({ form, defaultValues })

  const onSubmit = async (data: FormValues) => {
    try {
      clearErrors()
      const response = await submitForm('askQuestionForm', {
        ...data,
        region: regionId,
        path: asPath,
      })
      if (!response.ok) {
        return setError()
      }
      return setSuccess()
    } catch {
      return setError()
    }
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className={className}
      name="ask-question-form"
    >
      <FormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="name"
        name="name"
        label={t('askQuestionForm.nameFieldLabel')}
      />

      <FormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="country-name"
        name="country"
        label={t('askQuestionForm.countryFieldLabel')}
      />

      <FormTextField
        control={control}
        rules={{
          ...validateEmail(t),
          ...validateRequired(t),
        }}
        autoComplete="email"
        type="email"
        name="email"
        label={t('askQuestionForm.emailFieldLabel')}
      />

      <FormTextField
        control={control}
        rules={validateRequired(t)}
        name="message"
        label={t('askQuestionForm.messageFieldLabel')}
        multiline
        rows={8}
      />

      <SubmitButton
        text={t('askQuestionForm.submitButtonLabel')}
        helpText={submitMessage}
        invalid={invalid}
        success={success}
        loading={isSubmitting}
      />
    </Form>
  )
}
