import React, { FC, useEffect, useState } from 'react'
import { styled } from '@liftfoils/styles'
import { Button } from '@liftfoils/components'
import { SearchIcon } from '@liftfoils/icons'
import { SiteConfiguration } from '@liftfoils/models'
import { SearchModal } from './SearchModal'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'

const Wrapper = styled(Button, {
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
})
const Label = styled('span', {
  '@md': {
    display: 'none',
  },
})

export const SearchButton: FC<{
  appearance: 'bareWhite' | 'bareGray' | 'outlineGray300'
  searchConfig?: SiteConfiguration['search']
}> = ({ searchConfig, appearance }) => {
  const [isOpen, setIsOpen] = useState(false)
  const router = useRouter()
  const { t } = useTranslation('search')

  useEffect(() => {
    const handleRouteChange = () => {
      setIsOpen(false)
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      <SearchModal
        isOpen={isOpen}
        closeSearch={() => setIsOpen(false)}
        searchConfig={searchConfig}
      />
      <Wrapper
        appearance={appearance}
        size={'small'}
        onClick={() => {
          setIsOpen(true)
        }}
      >
        <SearchIcon />
        <Label>{t('search')}</Label>
      </Wrapper>
    </>
  )
}
