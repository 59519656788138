import { FC } from 'react'

import { ShopifyProductPriceRange } from '@liftfoils/shopify-sdk'
import { styled } from '@liftfoils/styles'
import { PriceRange } from './PriceRange'
import {
  useAccount,
  useClientProducts,
} from '@liftfoils/services/shopify-service'

const Wrap = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$4',
})
const LowOpacity = styled('span', {
  opacity: 0.6,
})
export const ContextualizedProductPrice: FC<{
  priceRange: ShopifyProductPriceRange
  productId: string
}> = ({ productId, priceRange }) => {
  const data = useClientProducts([productId])
  const { isB2B } = useAccount()

  if (!isB2B) return <PriceRange range={priceRange} />

  const priceRangeWithContext = (() => {
    if (!(data.products && data.products[0])) return undefined
    return data.products[0].priceRange
  })()

  return (
    <Wrap>
      <LowOpacity>
        <PriceRange range={priceRange} />
      </LowOpacity>
      <LowOpacity>MSRP</LowOpacity>
      <span>·</span>
      <span>
        {!data.products ? (
          '...'
        ) : priceRangeWithContext ? (
          <PriceRange range={priceRangeWithContext} />
        ) : (
          'N/A'
        )}
      </span>
      <span>Wholesale</span>
    </Wrap>
  )
}
