import { DEFAULT_LOCALE_ID, LocaleId } from '@liftfoils/configs'

export const coalescePortableToPlainText = (
  fieldName: string,
  localeId: LocaleId,
  defaultLocaleId = DEFAULT_LOCALE_ID,
  targetFieldName?: string,
) =>
  `"${
    targetFieldName ?? fieldName
  }": pt::text(coalesce(${fieldName}.${localeId}, ${fieldName}.${defaultLocaleId}))`
