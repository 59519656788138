import { FC, useEffect, useState } from 'react'
import { breakpoints, styled, useMediaQuery } from '@liftfoils/styles'
import { SiteConfiguration } from '@liftfoils/models'
import { Newsletter } from './Newsletter'
import { Close } from '@liftfoils/icons'
import { Button } from '../Button/Button'
import { Modal } from '../Modal/Modal'
import { Media } from '../Media/Media'
import { PortableText } from '../PortableText/PortableText'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import { useAccount } from '@liftfoils/services/shopify-service'
import { checkSubscription } from '@liftfoils/services/api-service'

const Content = styled('div', {
  display: 'grid',
  overflow: 'hidden',
  minHeight: '460px',
  margin: '$8',
  gridTemplateRows: 'auto minmax(200px, auto) 82px auto',
  gridGap: '$8',
  '@md': {
    margin: '$0',
    borderRadius: '$r1',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto 82px auto',
    gridGapColums: '$10',
    maxWidth: '1000px',
  },
})

const ImageWrap = styled('div', {
  position: 'relative',
  borderRadius: '$r1',
  overflow: 'hidden',
  '@md': {
    borderRadius: '$0',
    gridRow: '1 / 4',
    gridColumn: '1 / 2',
  },
})
const Heading = styled('div', {
  lift_font: 'heading03',
  textAlign: 'center',
  color: '$gray500',
  gridRow: '1 / 2',
  '@md': {
    padding: '$10',
    gridRow: '1 / 2',
    gridColumn: '2 / 3',
  },
})

const InputWrap = styled('div', {
  textAlign: 'center',
  '@md': {
    px: '$10',
    gridRow: '2 / 3',
    gridColumn: '2 / 3',
  },
})

const Description = styled('div', {
  lift_font: 'body06',
  color: '$gray300',
  b: {
    color: '$gray500',
  },
  a: {
    fontWeight: 'bold',
  },
  '@md': {
    padding: '$10',
    gridRow: '3 / 4',
    gridColumn: '2 / 3',
    alignSelf: 'end',
  },
})
const CloseButtonWrapper = styled('div', {
  position: 'absolute',
  right: 0,
  top: 0,
  width: '$11',
  height: '$11',
  display: 'grid',
  placeItems: 'center',
})
const TRIGGER_ROUTE_NUMBER = 3
const TIME_TO_TRIGGER = 8000

// the time in days after which the disable-newsletter cookie will expire
const COOKIE_EXPIRES = 14

export const ModalNewsletter: FC<{
  newsletter: SiteConfiguration['newsletter']
}> = ({ newsletter }) => {
  const matchesMd = useMediaQuery(breakpoints.md)
  const [isOpen, setIsOpen] = useState(false)
  const [routeVisited, setRouteVisited] = useState(0)
  const { account } = useAccount()
  const router = useRouter()

  const incrementRouteVisited = () => {
    setRouteVisited(routeVisited + 1)
  }
  useEffect(() => {
    router.events.on('routeChangeComplete', incrementRouteVisited)
    return () => {
      router.events.off('routeChangeComplete', incrementRouteVisited)
    }
  }, [routeVisited])

  useEffect(() => {
    if (routeVisited === TRIGGER_ROUTE_NUMBER) {
      setTimeout(() => {
        handleOpen()
      }, TIME_TO_TRIGGER)
      router.events.off('routeChangeComplete', incrementRouteVisited)
    }
  }, [routeVisited])

  const handleOpen = () => {
    if (Cookies.get('disableNewsletter')) return
    const accountEmail = account?.email
    if (!accountEmail) return setIsOpen(true)
    checkSubscription(accountEmail).then((res) => {
      if (!res.ok) return setIsOpen(true)
    })
  }

  const handleClose = () => {
    setIsOpen(false)
    Cookies.set('disableNewsletter', 'true', {
      expires: COOKIE_EXPIRES,
    })
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      position={matchesMd ? 'center' : 'bottom'}
      size={matchesMd ? 'intrinsic' : 'stretch-x'}
      variant={matchesMd ? 'white-rounded' : 'default'}
    >
      <CloseButtonWrapper>
        <Button onClick={handleClose} appearance={'flex'}>
          <Close />
        </Button>
      </CloseButtonWrapper>
      <Content>
        <ImageWrap>
          {newsletter.media && (
            <Media
              {...newsletter.media}
              layout="fill"
              sizes="(min-width:1000px) 488px, (min-width: 740px) 50vw, 100vw"
              alt={newsletter.media?.alt ?? ''}
            />
          )}
        </ImageWrap>
        <Heading>
          {newsletter.heading && <PortableText value={newsletter.heading} />}
        </Heading>
        <InputWrap>
          <Newsletter />
        </InputWrap>
        <Description>
          {newsletter.description && (
            <PortableText value={newsletter.description} />
          )}
        </Description>
      </Content>
    </Modal>
  )
}
