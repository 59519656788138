import { styled } from '@stitches/react'
import React from 'react'

const StyledPath = styled('path', {
  fill: 'currentColor',
  transition: 'fill 150ms',
})

export const SearchIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <g id="Shape">
        <StyledPath
          id="Shape_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7778 13.5L13.5 12.7778L9.80187 9.03584C10.5336 8.1353 10.9722 6.98692 10.9722 5.73611C10.9722 2.84429 8.62794 0.5 5.73611 0.5C2.84429 0.5 0.5 2.84429 0.5 5.73611C0.5 8.62794 2.84429 10.9722 5.73611 10.9722C6.98692 10.9722 8.1353 10.5336 9.03584 9.80187L12.7778 13.5ZM9.88889 5.73611C9.88889 8.02963 8.02963 9.88889 5.73611 9.88889C3.4426 9.88889 1.58333 8.02963 1.58333 5.73611C1.58333 3.4426 3.4426 1.58333 5.73611 1.58333C8.02963 1.58333 9.88889 3.4426 9.88889 5.73611Z"
          fillOpacity="0.8"
        />
      </g>
    </svg>
  )
}

export const SearchIconBig = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Vector">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.668 22.8203C17.5597 22.8203 22.3359 18.0441 22.3359 12.1523C22.3359 6.26059 17.5597 1.48438 11.668 1.48438C5.77621 1.48438 1 6.26059 1 12.1523C1 18.0441 5.77621 22.8203 11.668 22.8203ZM11.668 23.8203C18.112 23.8203 23.3359 18.5964 23.3359 12.1523C23.3359 5.7083 18.112 0.484375 11.668 0.484375C5.22393 0.484375 0 5.7083 0 12.1523C0 18.5964 5.22393 23.8203 11.668 23.8203Z"
          fill="#474646"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3122 29.5174L19.3513 20.5506L20.0586 19.8438L29.0195 28.8105L28.3122 29.5174Z"
          fill="#474646"
        />
      </g>
    </svg>
  )
}
