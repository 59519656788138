import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

/**
 * Orchestrator control state of <Video> and <VideoControls> components
 * and listeners of events html <video/> element
 */
export const useVideoOrchestrator = (config: {
  autoplay: boolean
  isMuted: boolean
  isVideoPaused?: boolean
  onPlaybackFinish?: () => void
}) => {
  const { autoplay, isMuted, isVideoPaused = false, onPlaybackFinish } = config
  const [isPlaying, setIsPlaying] = useState<boolean | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined)
  const [muted, setMuted] = useState<boolean | undefined>(isMuted)
  const [play, setPlay] = useState<boolean | undefined>(undefined)
  const [load, setLoad] = useState<boolean | undefined>(undefined)
  const [interrupted, setInterrupted] = useState(false)
  const { ref, inView, entry } = useInView({
    rootMargin: '300px',
    triggerOnce: true,
  })

  /**
   *  If video is in view and is not display='none' set load to true
   *  Prop 'load' should be passed to <Video> component and method load() should be triggered on html <video>
   **/
  useEffect(() => {
    if (!entry?.target) {
      return
    }

    const isEntryHidden =
      window.getComputedStyle(entry?.target).display === 'none'

    if (inView && !isEntryHidden && autoplay && !isVideoPaused) {
      setLoad(true)
    }

    if (inView && !isEntryHidden && !autoplay) {
      setIsLoading(false)
      setIsPlaying(false)
    }
  }, [inView, entry, autoplay, isVideoPaused])

  useEffect(() => {
    if (isVideoPaused) {
      setPlay(false)
    } else if (load) {
      setPlay(true)
    }
  }, [isVideoPaused])

  const onLoadStart = () => {
    setIsLoading(true)
  }

  const onPlaying = () => {
    setIsPlaying(true)
    setIsLoading(false)
    setInterrupted(false)
  }

  const onPause = () => {
    setIsPlaying(false)
  }
  /**
   *  If video is waiting, and it is not ready to play (e.g. because of lack of data)
   *  change loading=true, and disable play
   **/
  const onWaiting = () => {
    setPlay(false)
    setIsPlaying(false)
    setIsLoading(true)
    setInterrupted(true)
  }

  /**
   *  Reset state of video when error occurs in play() method
   *  Low power mode case
   **/
  const onNotAllowed = () => {
    setPlay(false)
    setIsPlaying(false)
    setIsLoading(false)
  }

  const onError = () => {
    setPlay(undefined)
    setIsPlaying(undefined)
    setIsLoading(undefined)
  }

  const onEnded = () => {
    onPlaybackFinish && onPlaybackFinish()
  }

  const onCanPlay = () => {
    /**
     *  if video is autoplay, is ready to play and is not playing because of :
     *  - before onWaiting was triggered - lack of data
     *  - on initial load
     * ...then change state to play=true & it will trigger play()
     * **/
    if (autoplay && !isPlaying) {
      setPlay(true)
    }
    /**
     *  if video is not autoplay, is ready to play and is not playing because of:
     * - before user trigger play(), but onWaiting was triggered (lack of data)
     * ...then change state to play=true & it will trigger play()
     **/
    if (!autoplay && !isPlaying && interrupted) {
      setPlay(true)
    }
    /**
     *  if video is not autoplay, is ready to play and is not playing because of:
     * - before user not trigger play (initial load)
     * ... then change state loading=false (ready to play - show play control button)
     **/
    if (!autoplay && !isPlaying && !interrupted) {
      setIsLoading(false)
    }
  }

  /**
   *  Sound on button clicked
   **/
  const onRequestUnmute = () => {
    setMuted(false)
  }

  /**
   *  Sound off button clicked
   **/
  const onRequestMute = () => {
    setMuted(true)
  }

  /**
   *  Play button clicked
   **/
  const onRequestPlay = () => {
    setPlay(true)
  }
  /**
   *  Pause button clicked
   **/
  const onRequestPause = () => {
    setPlay(false)
  }
  return {
    containerRef: ref,
    videoControlProps: {
      onPlaying,
      onPause,
      onCanPlay,
      onWaiting,
      onError,
      onNotAllowed,
      onLoadStart,
      onEnded,
      play,
      load,
      muted,
    },
    controlsProps: {
      isPlaying,
      isLoading,
      muted,
      onRequestPlay,
      onRequestPause,
      onRequestUnmute,
      onRequestMute,
    },
  }
}
