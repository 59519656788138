import { LocaleId } from '@liftfoils/configs'
import { Action, PageBuilderSection, PageResponse } from '@liftfoils/models'
import { logger } from '@liftfoils/utils'
import { getClient } from './getClient'
import { createStoryPageProjection } from './projections/createStoryPageProjection'

export const getLinkAction = async (
  localeId: LocaleId,
  action: Action,
  preview = false,
): Promise<PageResponse<PageBuilderSection[]> | undefined> => {
  let query

  switch (action.type) {
    case 'storyModal':
      query = `*[_id == "${action.payload.storyId}"]${createStoryPageProjection(
        localeId,
      )}[0]`
      break
    default:
      logger.error(`[getLinkAction]: Not defined action type: ${action.type}`)
      return
  }

  const response = await getClient(preview).fetch(query)

  return response
}
