import { Properties } from 'csstype'

export const BurgerIcon = ({
  pathStyle,
}: {
  pathStyle: Properties<string | number, string>
}) => (
  <svg
    width="40"
    height="9"
    viewBox="0 0 40 9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M40 0H0V1H40V0Z" fill="white" style={pathStyle} />
    <path d="M40 8H0V9H40V8Z" fill="white" style={pathStyle} />
  </svg>
)
