import { ParsedMetafields, parseMetafield } from '@shopify/hydrogen-react'
import { formatShopifyMedia } from './formatShopifyMedia'
import { ShopifyMediaFieldsFragment } from '../generated/schemaSdk'

type Metafields = Array<{
  __typename?: 'Metafield'
  id: string
  key: string
  value: string
  type: string
} | null>

type PossibleRawRichTextMetafieldValue = string | null
type PossibleParsedMetafieldValues = ParsedMetafields[keyof ParsedMetafields]

export function formatMetafields<
  ResultMetafields extends Record<
    string,
    | ParsedMetafields[keyof ParsedMetafields]['parsedValue']
    | PossibleRawRichTextMetafieldValue
  >,
>(metafields: Metafields): ResultMetafields {
  return (
    metafields?.reduce((acc, metafield) => {
      if (!metafield) return acc

      if (metafield.type === 'rich_text_field') {
        return {
          ...acc,
          [metafield.key]: metafield.value,
        }
      }
      if (metafield.type === 'list.file_reference') {
        return {
          ...acc,
          [metafield.key]: formatShopifyMedia(
            parseMetafield<PossibleParsedMetafieldValues>(metafield)
              .parsedValue as ShopifyMediaFieldsFragment[],
          ),
        }
      }

      const parsedMetafield =
        parseMetafield<PossibleParsedMetafieldValues>(metafield)

      return parsedMetafield
        ? {
            ...acc,
            [metafield.key]: parsedMetafield.parsedValue,
          }
        : acc
    }, {} as ResultMetafields) ?? ({} as ResultMetafields)
  )
}
