import { groq } from 'next-sanity'
import { LocaleId } from '@liftfoils/configs'
import { coalesceLocaleField } from './helpers/coalesceLocaleField'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { getClient } from './getClient'
import { createMediaProjection } from './projections/createMediaProjection'
import { createBaseImageProjection } from './projections/createBaseImageProjection'
import { createPortableTextProjection } from './projections/createPortableTextProjection'

/*
 * These projections are used to get the content from Sanity for Gear Selector.
 * It's not used for fetching products, that make the result.
 */

export type GearType = 'classicFoil' | 'efoil'

export const createGearSelectorQuery = (path: string, localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const BASE_IMAGE_PROJECTION = createBaseImageProjection(localeId)
  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(localeId)

  return groq`
  *[_type == "classicFoilGearSelector" && path.current == "${path}"][0] {
    "path": path.current,
    ${coalesceLocaleField('configurationTitle', localeId)},
    ${coalesceLocaleField('buyButtonDescription', localeId)},
    ${coalesceLocaleField('sendToEmailDescription', localeId)},
    ${coalesceLocaleField('disclaimer', localeId)}[]${PORTABLE_TEXT_PROJECTION},
    ${coalesceLocaleField(
      'outOfStockDisclaimer',
      localeId,
    )}[]${PORTABLE_TEXT_PROJECTION},
    gearSelectorType,
    steps[] {
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('description', localeId)},
      stepType,
      answers[] -> {
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField('description', localeId)},
        image${MEDIA_PROJECTION},
      },
    },
    seo {
      ${coalesceLocaleField('metaTitle', localeId)},
      ${coalesceLocaleField('metaDescription', localeId)},
      ${coalesceLocaleField('ogTitle', localeId)},
      ${coalesceLocaleField('ogDescription', localeId)},
      ogImage${BASE_IMAGE_PROJECTION},
    },
  }
`
}

export const getGearSelectorContentByPath = async (
  path: string,
  localeRegionString: string,
  preview = false,
) => {
  const [, localeId] = getLocaleRegionIdFromPath(localeRegionString)
  if (!path) throw new Error('Path is required')

  const query = createGearSelectorQuery(path, localeId)

  return await getClient(preview).fetch(query)
}
