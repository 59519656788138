import {
  CLIENT_ID,
  AUTHORIZATION_REDIRECT_URI,
  SHOPIFY_OAUTH_BASE_PATH,
  CLIENT_SECRET,
} from '../../shopify.config'

type ObtainResponse = {
  access_token: string
  expires_in: number
  id_token: string
  refresh_token: string
}

// two secrets below
const CREDENTIALS = btoa(`${CLIENT_ID}:${CLIENT_SECRET}`)

export const obtainAccessToken = async (authorizationCode: string) => {
  if (!CLIENT_ID) throw new Error('CLIENT_ID is not defined')
  if (!AUTHORIZATION_REDIRECT_URI)
    throw new Error('AUTHORIZATION_REDIRECT_URI is not defined')

  const body = new URLSearchParams({
    grant_type: 'authorization_code',
    client_id: CLIENT_ID,
    redirect_uri: AUTHORIZATION_REDIRECT_URI,
    code: authorizationCode,
    scope: 'openid email https://api.customers.com/auth/customer.graphql',
  })
  const response = await fetch(`${SHOPIFY_OAUTH_BASE_PATH}/token`, {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${CREDENTIALS}`,
    },
    body,
  })
  return (await response.json()) as ObtainResponse
}

type ExchangeResponse = {
  expires_in: number
  refresh_token: string
}
export const exchangeAccessToken = async (accessToken: string) => {
  if (!CLIENT_ID) throw new Error('CLIENT_ID is not defined')

  const body = new URLSearchParams({
    grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
    client_id: CLIENT_ID,
    audience: '30243aa5-17c1-465a-8493-944bcc4e88aa', // fixed value, specific for all Shopify stores
    subject_token: accessToken,
    subject_token_type: 'urn:ietf:params:oauth:token-type:access_token',
    scopes: 'https://api.customers.com/auth/customer.graphql',
  })

  const response = await fetch(`${SHOPIFY_OAUTH_BASE_PATH}/token`, {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${CREDENTIALS}`,
    },
    body,
  })

  return (await response.json()) as ExchangeResponse
}

type RefreshResponse = {
  access_token?: string
  expires_in?: number
  refresh_token?: string
  error?: string
}

export const refreshAccessToken = async (refreshToken: string) => {
  if (!CLIENT_ID) throw new Error('CLIENT_ID is not defined')

  const body = new URLSearchParams({
    grant_type: 'refresh_token',
    client_id: CLIENT_ID,
    refresh_token: refreshToken,
  })
  const response = await fetch(`${SHOPIFY_OAUTH_BASE_PATH}/token`, {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${CREDENTIALS}`,
    },
    body,
  })

  return (await response.json()) as RefreshResponse
}
