import { FC } from 'react'
import { styled } from '@liftfoils/styles'

const Root = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
})

export type InstagramEmbedProps = {
  _type: string
  _key: string
  embedCode: string
}

export const InstagramEmbed: FC<InstagramEmbedProps> = ({ embedCode }) => {
  return (
    <Root
      className={'instagramEmbed'}
      dangerouslySetInnerHTML={{ __html: embedCode }}
    />
  )
}
