import { FC } from 'react'

import { styled } from '@liftfoils/styles'

import { CommonTab } from './HeaderTab'
import { Button } from '@liftfoils/components'
import { SiteConfiguration } from '@liftfoils/models'

const ROW_HEIGHT = '$headerHeight'

const TabsRoot = styled('div', {
  display: 'grid',
  gridTemplateRows: `${ROW_HEIGHT} 1fr`,
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  pointerEvents: 'none',
})
const TabWrap = styled('div', {
  gridRow: 2,
  gridColumn: 1,
  display: 'grid',
  alignContent: 'start',
  background: 'white',
  variants: {
    isVisible: {
      true: {
        pointerEvents: 'all',
      },
      false: {
        display: 'none',
      },
    },
  },
})
const BackButtonInner = styled('div', {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: `${ROW_HEIGHT} 1fr ${ROW_HEIGHT}`,
  lift_font: 'heading02',
  alignItems: 'center',
  height: `${ROW_HEIGHT}`,
  px: '$7',
  background: '$gray100',
  color: '$gray500',
})

export const MobileTabs: FC<{
  activeTabIndex: number | null
  onRequestTabClose: any
  header: SiteConfiguration['header']
}> = ({ activeTabIndex, onRequestTabClose, header }) => {
  return (
    <TabsRoot>
      {header.tabs.map((tab, i) => {
        return (
          <TabWrap key={tab.title} isVisible={i === activeTabIndex}>
            <Button onClick={onRequestTabClose}>
              <BackButtonInner>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.3 24L11.6 16.3L19.3 8.6L20 9.3L12.9 16.3L20 23.3L19.3 24Z"
                    fill="#474646"
                  />
                </svg>
                <span>{tab.title}</span>
              </BackButtonInner>
            </Button>
            <CommonTab tab={tab} />
          </TabWrap>
        )
      })}
    </TabsRoot>
  )
}
