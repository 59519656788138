import { ValidateFnType } from '../models/ValidateFnType'

const PHONE_REGEXP = /^[0-9()+\\-]+$/

export const validatePhone: ValidateFnType = (t) => ({
  pattern: {
    value: PHONE_REGEXP,
    message: t('validate.invalidPhoneError'),
  },
})
